/* eslint-disable */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, Tooltip, Box } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import IconButton from '@mui/material/IconButton';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { setSelectedYear, setSelectedLocation, setSelectedCourse } from '../../../store/actions/getLocationCoordinator';
import { postExportedSheetF } from '../../../store/actions/getStudent';
import { PerformantDropdown, PerfromantMultiValueDropdown } from '../../../components/atoms';
import styles from './style';
import MapPin from '../../../assets/images/map-pin.png';
import useStyles from '../../../custom-hooks/useStyles';
import { ColumnSelectIcon } from '../../../assets/svg';
import { NavigateRoutes, Buttons } from '../../../constant';
import Constant from '../../../store/constant';
import { setLocalStorage, getLocalStorage } from '../../../utils/localStorageMethod';
import MediaUploader from '../../../utils/exportStudentSheet';
import ButtonAtom from '../../../components/atoms/button';
import { colors } from '../../../theme';
import DialogAtomSheeet from './dialogSheet';
import { getGradeforScore, isNullOrUndefined } from '../../../utils/methods';

const useStylesSheet = makeStyles(() => ({
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
  },
}));

function StudentHeader({
  setSelectedRows,
  setExportSuccessfull,
  formik,
  t,
  fileHeaders,
  years,
  locations,
  courseData,
  fileName,
  setDialogOpen,
  selectedRows,
  setError,
  selectedCourseRedux,
  setSelectedLocationForCourse,
  setLoading,
  gradesData,
}) {
  const [isAgeHeaderAdded, setIsAgeHeaderAdded] = useState(fileHeaders.some((header) => header === 'Age'));
  const [showTokenAdvice, setShowTokenAdvice] = useState(false);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const classesSheet = useStylesSheet();
  if (isAgeHeaderAdded === false) {
    fileHeaders.splice(4, 0, { name: 'Age', label: 'Age', key: 'age' });
    setIsAgeHeaderAdded(true);
  }
  selectedRows.forEach((row) => {
    row.age = moment().diff(row.dateOfBirth, 'years', false);
    const date = new Date(row.dateOfBirth);
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    row.dateOfBirth = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  });
  const [fileHeadersCSV, setFileHeadersCSV] = useState(fileHeaders);

  useEffect(() => {
    const isIdHeaderAdded = fileHeadersCSV.some((header) => header.name === 'id');
    if (isIdHeaderAdded === false) {
      const aFileHeaders = fileHeaders;
      aFileHeaders.unshift({ name: 'id', label: 'id', key: 'id' });
      setFileHeadersCSV(aFileHeaders);
    } else {
      setFileHeadersCSV(fileHeaders);
    }
  }, [fileHeaders]);
  const classes = useStyles(styles)();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  setLocalStorage('selectedLocation', '');
  setLocalStorage('selectedYear', '');

  useEffect(() => {
    if (selectedCourseRedux?.value) {
      formik.setFieldValue('courseId', selectedCourseRedux?.value);
    } else {
      formik.setFieldValue('courseId', courseData.map((courses) => {
        return courses?.id;
      }));
    }
  }, [courseData]);

  const sendBulkEmail = () => {
    if (selectedRows.length > 0) {
      setError('');
      const emailList = [];
      const emailParents = [];
      selectedRows?.forEach((row) => {
        emailList.push(row?.studentInfo?.manabadiEmail);
        emailParents.push(row?.parent1Info?.personalEmail?.toString());
        emailParents.push(row?.parent2Info?.personalEmail?.toString());
      });
      dispatch({ type: Constant.SET_MAIL_SUBJECT, payload: '' });
      dispatch({ type: Constant.SET_MAIL_BODY, payload: '' });
      dispatch({ type: Constant.RECIPIENTS, payload: emailList });
      dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
      dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
      setLocalStorage('selectedLocation', formik?.values?.locationId);
      setLocalStorage('selectedYear', formik?.values?.academicYear);
      setLocalStorage('showSelectAllinEmail', false);
      setLocalStorage('moduleReturn', 'Students');

      if (selectedRows.length > 0) {
        setLocalStorage('showLocationFilterRecipients', false);
        setLocalStorage('showLocationAnnouncementsRecipients', false);
      } else {
        setLocalStorage('showLocationFilterRecipients', false);
        setLocalStorage('showLocationAnnouncementsRecipients', false);
      }
      navigate(NavigateRoutes.LOCATION_COORDINATOR_EMAIL);
    } else {
      setError(t('SELECT_ATLEAST_ONE_STUDENT'));
    }
  };

  const handleYearChange = (e) => {
    formik.setFieldValue('academicYear', e.target.value);
    const payload = {
      id: e.target.value,
      name: e.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const handleLocationChange = (e) => {
    formik.setFieldValue('locationId', e.target.value);
    dispatch(setSelectedLocation(e.target));
    setSelectedLocationForCourse(e.target);
  };

  const handleCourseChange = (e) => {
    formik.setFieldValue('courseId', e.target.value);
    dispatch(setSelectedCourse(e.target));
  };

  const saveCSV = () => {
    if (selectedRows.length > 0) {
      const payloadCreateSheet = {
        properties: {
          title: fileName,
        },
        sheets: [
          {
            properties: {
              title: `${fileName.split(' - ')[0]} - ${fileName.split(' - ')[1]}`,
            },
          },
        ],
      };

      const payloadPutSheet = [];

      const Cells = {
        updateCells: {
          rows: [],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };

      const headerValues = fileHeadersCSV.map((header) => ({
        userEnteredValue: {
          stringValue: header.label,
        },
        userEnteredFormat: {
          textFormat: {
            bold: true,
          },
        },
      }));
      const filters = fileHeadersCSV.map((header) => (
        header.label
      ));
      Cells.updateCells.rows = [
        {
          values: headerValues,
        },
      ];
      selectedRows.forEach((row) => {
        const newRowValues = {
          values: [],
        };

        fileHeadersCSV.forEach((header) => {
          let value = row[header.key];
          if (header.key === 'gradeS1') {
            value = getGradeforScore(row?.S1Total, gradesData.gradeData);
          }

          if (header.key === 'gender') {
            value = row[header.key] === '' || row[header.key] === ' ' || row[header.key] === 'null' ? 'N/A' : row[header.key];
          }

          if (header.key === 'gradeS2') {
            value = getGradeforScore(row?.S2Total, gradesData.gradeData);
          }

          if (header.key === 'annualScore') {
            value = (row?.marks?.Q1?.isAttended || row?.marks?.Q2?.isAttended || row?.marks?.Q3?.isAttended
              || (isNullOrUndefined(row?.marks?.S1?.isAttended) && isNullOrUndefined(row?.marks?.S2?.isAttended)) || isNullOrUndefined(row?.marks?.S3?.isAttended))
              ? row?.annualScore : 'N/A'
          }

          if (header.key === 'grade') {
            value = (row?.marks?.Q1?.isAttended || row?.marks?.Q2?.isAttended || row?.marks?.Q3?.isAttended
              || (isNullOrUndefined(row?.marks?.S1?.isAttended) && isNullOrUndefined(row?.marks?.S2?.isAttended)) || isNullOrUndefined(row?.marks?.S3?.isAttended))
              ? row?.grade : 'N/A'
          }

          if (header.key === 'S1Total') {
            value = row?.S1Total !== '-' ? row?.S1Total : '0'
          }

          if (header.key === 'S2Total') {
            value = row?.S2Total !== '-' ? row?.S2Total : '0'
          }

          if (typeof value === 'boolean') {
            newRowValues.values.push({
              userEnteredValue: {
                boolValue: value,
              },
            });
          } else if (typeof value === 'number') {
            newRowValues.values.push({
              userEnteredValue: {
                numberValue: value,
              },
            });
          } else {
            newRowValues.values.push({
              userEnteredValue: {
                stringValue: value || '',
              },
            });
          }
        });

        Cells.updateCells.rows.push(newRowValues);
      });

      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: headerValues.length || 30,
            },
          },
          Cells,
        ],
      });

      setLoading(true);
      const gettoken = getLocalStorage('accessToken');
      const baseUrl = JSON.parse(gettoken);
      const newDate = new Date();
      const time = newDate.toString().split('GMT')[0]
      const localtime = moment(time).format('MM-DD-YYYY HH:mm:ss');
      const localTimezone = newDate.toString().match(/GMT([+-]\d{4}) \(([^)]+)\)/);
      const localHour = `${localtime} ${localTimezone[0]}`
      const uploader = new MediaUploader({
        token: baseUrl?.access_token,
        filename: fileName,
        contentType: 'application/vnd.google-apps.spreadsheet',
        metadata: payloadCreateSheet,
        sheetMood: 'New',
        file: payloadPutSheet,
        onComplete: (response) => {
          const payload = {
            fileName: fileName,
            creationDate: newDate,
            columnsUpdated: filters.length,
            rowsUpdated: selectedRows.length,
            sheetId: response.spreadsheetId,
            urlFile: response.spreadsheetUrl,
            filters: filters.join(' - '),
            localHour: localHour,
          };
          dispatch(postExportedSheetF(payload, setOpenSheet, null, setLoading, null, false, setSelectedRows, setExportSuccessfull));
        
        },
        onError: (e) => {
          const res = JSON.parse(e);
          if (res.error.status === 'PERMISSION_DENIED') {
            setIsPermissionDenied(true);
          } else {
            setIsPermissionDenied(false);
          }
          setShowTokenAdvice(true);
          setLoading(false);
        },
      });
      uploader.upload();
    } else {
      setError(t('SELECT_ATLEAST_ONE_STUDENT_REPORT'));
    }
  };

  const missingToken = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {isPermissionDenied ? t('TOKEN_DENIED') : t('TOKEN_EXPIRED') }
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => {
            setShowTokenAdvice(false);
            setLoading(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderOkContent = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('STUDENT_CSV_EXPORTED_SUCCESSFULLY')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => {
            setOpenSheet(false);
            setLoading(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} direction="row" display="flex" alignItems="center">
      <FormikProvider value={formik}>
        <Grid item xs={4} sm={1.5} className={classes.year}>
          <PerformantDropdown
            minWidth="100%"
            label={t('YEAR')}
            labelId={t('YEAR')}
            id="academicYear"
            name="academicYear"
            value={formik.values.academicYear}
            handleChange={(e) => handleYearChange(e)}
            options={years}
            customClass="yearDropdown"
            variant="standard"
          />
        </Grid>
        <Grid item xs={4.5} className={classes.locationDropdown}>
          <PerformantDropdown
            minWidth="100%"
            label={t('LOCATION')}
            id="locationId"
            name="locationId"
            value={formik.values.locationId}
            handleChange={(e) => handleLocationChange(e)}
            options={locations}
            customClass="locationDropdown"
            variant="standard"
            icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}
          />
        </Grid>
        <Grid item xs={6} sm={3} className={classes.courseDropdown}>
          <PerfromantMultiValueDropdown
            minWidth="100%"
            label={t('COURSE')}
            value={formik.values.courseId}
            options={courseData}
            onChange={(e) => handleCourseChange(e)}
            id="courseId"
            name="courseId"
            variant="standard"
            customClass="courseDropdown"
          />
        </Grid>
        <Grid item md={1.4} />
        <Grid item xs={0.5} className={classes.gridActions}>
          <Tooltip title={t('DOWNLOAD')} placement="right">
            <div className={classes.header}>
              <IconButton onClick={() => saveCSV()}>
                <FileDownloadOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={0.5} className={classes.gridActions} onClick={sendBulkEmail}>
          <Tooltip title={t('SEND_EMAIL')} placement="right">
            <div className={classes.header}>
              <EmailOutlinedIcon className={classes.header} />
            </div>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={0.5}
          onClick={() => setDialogOpen(true)}
          className={classes.gridActions}
        >
          {' '}
          <Tooltip title={t('UPDATE_SETTINGS')} placement="right">
            <div>
              <ColumnSelectIcon
                customClass={classes.columnSelectionIcon}
                strokeColor="#104F96"
              />
            </div>
          </Tooltip>
        </Grid>
        <DialogAtomSheeet
          isOpen={showTokenAdvice}
          dialogHeading={t('TOKEN_EXPIRED_TITLE')}
          content={missingToken()}
          secHandle={() => setShowTokenAdvice(false)}
        />
        <DialogAtomSheeet
          isOpen={openSheet}
          dialogHeading={t('STUDENT_EXPORT')}
          content={renderOkContent()}
          secHandle={() => { setOpenSheet(false); setLoading(false); }}
        />
      </FormikProvider>
    </Grid>
  );
}

export default StudentHeader;

/* eslint-disable arrow-parens */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable-loop */
/* eslint-disable radix */
/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { Buttons } from '../../../constant';
import { colors } from '../../../theme';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import SyncFilters from './sync-filter';
import ButtonAtom from '../../../components/atoms/button';
import { getAssignedLocations, getMarksDetails, deleteGoogleSheetbyId } from '../../../store/actions/getLocationCoordinator';
import {
  classesByYearTeacher, studentsByLocationMarks, updateMarksStudents, setSelectedYear, setSelectedClassRooms, createStudentMarks, saveImportExportSheetTimes
} from '../../../store/actions/getTeacherView';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import MediaUploader from '../../../utils/uploadSheet';
import DialogAtom from './dialogSheet';
import { getCurrentYear } from '../../../utils/methods';
import constant from '../../../constant/config';

const useStylesSheet = makeStyles(() => ({
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
  },
}));

export default function TeacherSyncView() {
  const classes = useStyles(styles)();
  const classesSheet = useStylesSheet();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isselectedYear, setSelectYear] = useState();
  const [locationData, setLocationData] = useState([]);
  const [isSelectedlocation, setSelectedlocation] = useState();
  const [isSelectedCourse, setSelectedCourse] = useState();
  const [isSelectedCourseId, setSelectedCourseId] = useState();
  const [isSelectedlocationName, setSelectedlocationName] = useState();
  const [isSelectedCourseName, setSelectedCourseName] = useState();
  const [courseData, setCourseData] = useState([]);
  const [csvDataMarks, setCsvDataMarks] = useState([]);
  const [csvDataMarks1, setCsvDataMarks1] = useState([]);
  const [csvDataMarks2, setCsvDataMarks2] = useState([]);
  const [csvDataMarks3, setCsvDataMarks3] = useState([]);
  const [csvDataHWMarks1, setCsvDataHWMarks1] = useState([]);
  const [csvDataHWMarks2, setCsvDataHWMarks2] = useState([]);
  const [csvDataHWMarks3, setCsvDataHWMarks3] = useState([]);
  const [csvDataAssignmentMarks1, setCsvDataAssignmentMarks1] = useState([]);
  const [csvDataAssignmentMarks2, setCsvDataAssignmentMarks2] = useState([]);
  const [csvDataQuiz1Marks1, setCsvDataQuiz1Marks1] = useState([]);
  const [csvDataQuiz1Marks2, setCsvDataQuiz1Marks2] = useState([]);
  const [csvDataQuiz2Marks1, setCsvDataQuiz2Marks1] = useState([]);
  const [csvDataQuiz2Marks2, setCsvDataQuiz2Marks2] = useState([]);
  const [csvDataCoCurricularMarks1, setCsvDataCoCurricularMarks1] = useState([]);
  const [csvDataCoCurricularMarks2, setCsvDataCoCurricularMarks2] = useState([]);
  const [showDataMarks, setShowDataMarks] = useState([]);
  const [showTokenAdvice, setShowTokenAdvice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameSheet, setNameSheet] = useState('Sheet has not been exported');
  const [createAtSheet, setCreateAtSheet] = useState(false);
  const [exportSheetTime, setExportSheetTime] = useState('Sheet has not been exported');
  const [importSheetTime, setImportSheetTime] = useState('Sheet has not been imported');
  const [changeYear, setChangeYear] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const [openSheetExists, setOpenSheetExists] = useState(false);
  const [idSheet, setIdSheet] = useState();
  const [showNameSheet, setShowNameSheet] = useState('');
  const [bOpenImportSheet, setOpenImportSheet] = useState(false);
  const [bOpenDialog, setOpenDialog] = useState(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [openDialogErrorImport, setOpenDialogErrorImport] = useState(false);
  const [selectedGoogleClassId, setSelectedGoogleClassId] = useState();
  const [aQ1IDs, setAQ1IDs] = useState([]);
  const [aQ2IDs, setAQ2IDs] = useState([]);
  const [aQ3IDs, setAQ3IDs] = useState([]);
  const [aQ1HWIDs, setAQ1HWIDs] = useState([]);
  const [aQ2HWIDs, setAQ2HWIDs] = useState([]);
  const [aQ3HWIDs, setAQ3HWIDs] = useState([]);
  const [aS1IDs, setAS1IDs] = useState([]);
  const [aS2IDs, setAS2IDs] = useState([]);
  const [aS3IDs, setAS3IDs] = useState([]);
  const [aS1HWIDs, setAS1HWIDs] = useState([]);
  const [aS2HWIDs, setAS2HWIDs] = useState([]);
  const [aS1AssignmentIDs, setAS1AssignmentIDs] = useState([]);
  const [aS2AssignmentIDs, setAS2AssignmentIDs] = useState([]);
  const [aS1Quiz1IDs, setAS1Quiz1IDs] = useState([]);
  const [aS2Quiz1IDs, setAS2Quiz1IDs] = useState([]);
  const [aS1Quiz2IDs, setAS1Quiz2IDs] = useState([]);
  const [aS2Quiz2IDs, setAS2Quiz2IDs] = useState([]);
  const [aS1CoCurricularIDs, setAS1CoCurricularIDs] = useState([]);
  const [aS2CoCurricularIDs, setAS2CoCurricularIDs] = useState([]);
  const [systemBased, setSystemBased] = useState('Semester');
  const [marksType, setMarksType] = useState('EXAM MARKS');
  const [systemBasedType, setSystemBasedType] = useState('S1');
  const [showS3, setShowS3] = useState('');
  const [sBorderBottomQ1, setBorderBottomQ1] = useState(0);
  const [sBorderBottomQ2, setBorderBottomQ2] = useState(0);
  const [sBorderBottomQ3, setBorderBottomQ3] = useState(0);
  const [sBorderBottomS1, setBorderBottomS1] = useState(2);
  const [sBorderBottomS2, setBorderBottomS2] = useState(0);
  const [sBorderBottomS3, setBorderBottomS3] = useState(0);
  const [sBorderBottomExamMarks, setBorderBottomExamMarks] = useState(2);
  const [sBorderBottomHomeworkMarks, setBorderBottomHomeworkMarks] = useState(0);
  const [sBorderBottomAssignmentMarks, setBorderBottomAssignmentMarks] = useState(0);
  const [sBorderBottomCoCurricularMarks, setBorderBottomCoCurricularMarks] = useState(0);
  const [sBorderBottomQuiz1Marks, setBorderBottomQuiz1Marks] = useState(0);
  const [sBorderBottomQuiz2Marks, setBorderBottomQuiz2Marks] = useState(0);
  const [openDialogErrorMarkImport, setOpenDialogErrorMarkImport] = useState(false);
  const [openDialogErrorGetData, setOpenDialogErrorGetData] = useState(false);
  const [openDialogExportFirst, setOpenDialogExportFirst] = useState(false);
  const reduxStore = useSelector((state) => state?.getLocationCoordinator);
  const selectedYearRedux = reduxStore?.selectedYear;
  const assignedYears = reduxStore?.assignedYears;
  const teacherPanel = useSelector((state) => state?.getTeacherView);
  const [listRefreshed, setListRefreshed] = useState(false);
  const [dialogErrorImportMessage, setDialogErrorImportMessage] = useState('');
  const showS3Marks = false;

  const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA'];
  const handleOkDialog = () => {
    setOpenSheet(false);
  };

  const handleSheetDialog = () => {
    setOpenSheetExists(false);
  };

  const handleChangeMarksButtons = (SystemType, Marktype) => {
    const setTypeBorder = (type, value) => {
      setBorderBottomQ1(type === 'Q1' ? value : 0);
      setBorderBottomQ2(type === 'Q2' ? value : 0);
      setBorderBottomQ3(type === 'Q3' ? value : 0);
      setBorderBottomS1(type === 'S1' ? value : 0);
      setBorderBottomS2(type === 'S2' ? value : 0);
      setBorderBottomS3(type === 'S3' ? value : 0);
    };
  
    const setMarksTypeBorder = (type, value) => {
      setBorderBottomExamMarks(type === 'EXAM MARKS' ? value : 0);
      setBorderBottomHomeworkMarks(type === 'HOMEWORK MARKS' ? value : 0);
      setBorderBottomAssignmentMarks(type === 'ASSIGNMENT MARKS' ? value : 0);
      setBorderBottomQuiz1Marks(type === 'QUIZ 1 MARKS' ? value : 0);
      setBorderBottomQuiz2Marks(type === 'QUIZ 2 MARKS' ? value : 0);
      setBorderBottomCoCurricularMarks(type === 'CO-CURRICULAR MARKS' ? value : 0);
    };
    
    SystemType = SystemType ? SystemType : systemBasedType;
    Marktype = Marktype ? Marktype : marksType;
    setSystemBasedType(SystemType);
    setMarksType(Marktype);
    setTypeBorder(SystemType, 2);
    setMarksTypeBorder(Marktype, 2);
  
    if (SystemType === 'S3' || SystemType === 'Q3') {
      if (SystemType === 'Q3') {
        switch (Marktype) {
          case 'EXAM MARKS':
            setShowDataMarks(csvDataMarks3);
            break;
          case 'HOMEWORK MARKS':
            setShowDataMarks(csvDataHWMarks3);
            break;
          default:
            break;
        }
        setShowNameSheet(`Marks_Q3_${Marktype.replace(/ /g, '_')}`);
      } else if (showS3Marks) {
        setShowDataMarks(csvDataMarks3);
        if (showS3 === 'PSTU' && showS3Marks) {
          setShowNameSheet(`PSTU_${Marktype.replace(/ /g, '_')}`);
        } else if (showS3 === 'Supplementary' && showS3Marks) {
          setShowNameSheet(`Supplementary_${Marktype.replace(/ /g, '_')}`);
        }
      }
    } else if (SystemType === 'S1' || SystemType === 'Q1') {
      switch (Marktype) {
        case 'EXAM MARKS':
          setShowDataMarks(csvDataMarks1);
          break;
        case 'HOMEWORK MARKS':
          setShowDataMarks(csvDataHWMarks1);
          break;
        case 'ASSIGNMENT MARKS':
          setShowDataMarks(csvDataAssignmentMarks1);
          break;
        case 'QUIZ 1 MARKS':
          setShowDataMarks(csvDataQuiz1Marks1);
          break;
        case 'QUIZ 2 MARKS':
          setShowDataMarks(csvDataQuiz2Marks1);
          break;
        case 'CO-CURRICULAR MARKS':
          setShowDataMarks(csvDataCoCurricularMarks1);
          break;
        default:
          break;
      }
      setShowNameSheet(`Marks_${SystemType}_${Marktype.replace(/ /g, '_')}`);
    } else if (SystemType === 'S2' || SystemType === 'Q2') {
      switch (Marktype) {
        case 'EXAM MARKS':
          setShowDataMarks(csvDataMarks2);
          break;
        case 'HOMEWORK MARKS':
          setShowDataMarks(csvDataHWMarks2);
          break;
        case 'ASSIGNMENT MARKS':
          setShowDataMarks(csvDataAssignmentMarks2);
          break;
        case 'QUIZ 1 MARKS':
          setShowDataMarks(csvDataQuiz1Marks2);
          break;
        case 'QUIZ 2 MARKS':
          setShowDataMarks(csvDataQuiz2Marks2);
          break;
        case 'CO-CURRICULAR MARKS':
          setShowDataMarks(csvDataCoCurricularMarks2);
          break;
        default:
          break;
      }
      setShowNameSheet(`Marks_${SystemType}_${Marktype.replace(/ /g, '_')}`);
    }

    setLoading(true);
    if (listRefreshed) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const deleteGoogleSheet = (id) => {
    const gettoken = getLocalStorage('accessToken');
    const body = {
      gettoken: gettoken,
      id: id
    };
    dispatch(deleteGoogleSheetbyId(body));
  };

  const renderOkContent = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('STUDENT_CSV_EXPORTED_SUCCESSFULLY')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={handleOkDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderErrorSheet = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('TOKEN_EXPIRED')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => setOpenDialogError(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderErrorImportSheet = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {dialogErrorImportMessage}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => setOpenDialogErrorImport(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderErrorImportMarkSheet = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('SHEET_IMPORT_MARK_ERROR')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => setOpenDialogErrorMarkImport(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  function getMarks(index, num, aMarks, sQuarter, isExamMarks = false) {
    // variable to limit the number of marks on the sheet 
    const numberOfMarks = 53;
    let iNum = num;
    const oMarks = {};
    const quiz = sQuarter.split('_')[2];
    const newsQuarter = `${sQuarter.split('_')[0]}_${sQuarter.split('_')[1]}`;
    const panel = index[newsQuarter][quiz] ? index[newsQuarter][quiz] : index[newsQuarter];
    if (panel[0] !== undefined) {
      iNum++;
      oMarks['fieldNumber'] = iNum;
      oMarks['id'] = `${panel[0]}-${iNum}`;
    }
    if (typeof panel[1] !== 'undefined') oMarks['totalMarks'] = Number.isInteger(parseFloat(panel[1])) ? panel[1] : parseFloat(panel[1]).toFixed(3);
    if (isExamMarks) {
      if (typeof panel[2] !== 'undefined') oMarks['isAbsent'] = panel[2];
    }
    for (let i = (isExamMarks ? 3 : 2); i < numberOfMarks; i++) {
      oMarks[`mark${i}`] = panel[i];
    }
    if (typeof panel[0] !== 'undefined') {
      oMarks['studentName'] = panel[0];
      aMarks.push(oMarks);
    }
    return iNum;
  }

  function processMarks(aMarks, sNQuarter, isExamMarks = false) {
    if (aMarks.length !== 0) {
      const iNum = [];
      let titleTable;
      if (isExamMarks) {
        titleTable = {
          fieldNumber: 1,
          id: 'title-1',
          studentName: 'Student Name',
          totalMarks: 'Total Marks',
          isAbsent: 'Is Absent',
        };
      } else {
        titleTable = {
          fieldNumber: 1,
          id: 'title-1',
          studentName: 'Student Name',
          totalMarks: 'Total Marks',
        };
      }
      for (let i = 0; i < teacherPanel.studentsMark.length; i++) {
        let panel = teacherPanel.studentsMark[i][sNQuarter] || '';
        if (sNQuarter === 'homeworkPanelS1' || sNQuarter === 'homeworkPanelS2' || sNQuarter === 'homeworkPanelQ1' || sNQuarter === 'homeworkPanelQ2' || sNQuarter === 'homeworkPanelQ3' ) {
          panel = teacherPanel.studentsMark[i]['homeworkMarks'][sNQuarter];
        } else if (sNQuarter === 'assignmentPanelS1' || sNQuarter === 'assignmentPanelS2') {
          panel = teacherPanel.studentsMark[i]['assignmentMarks'][sNQuarter];
        } else if (sNQuarter === 'quiz1PanelS1' || sNQuarter === 'quiz1PanelS2' || sNQuarter === 'quiz2PanelS1' || sNQuarter === 'quiz2PanelS2') {
          panel = teacherPanel.studentsMark[i]['quizMarks'][sNQuarter];
        } else if (sNQuarter === 'AttendenceActivitiesS1' || sNQuarter === 'AttendenceActivitiesS2') {
          panel = teacherPanel.studentsMark[i]['studentAttendenceActivitiesMarks'][sNQuarter];
        }
        if (panel) {
          if (panel.length > 0) {
            for (let nIndex = 0; nIndex < panel.length; nIndex++) {
              iNum.push(panel[nIndex].id);
              titleTable[`mark${nIndex + (isExamMarks ? 3 : 2)}`] = `${panel[nIndex].notes} / ${panel[nIndex].marks}`;
            }
            break;
          }
        }
      }
      switch (sNQuarter) {
        case 'academicPanelQ1':
          setAQ1IDs(iNum);
          break;
        case 'academicPanelQ2':
          setAQ2IDs(iNum);
          break;
        case 'academicPanelQ3':
          setAQ3IDs(iNum);
          break;
        case 'academicPanelS1':
          setAS1IDs(iNum);
          break;
        case 'academicPanelS2':
          setAS2IDs(iNum);
          break;
        case 'academicPanelS3':
          setAS3IDs(iNum);
            break;
        case 'homeworkPanelS1':
          setAS1HWIDs(iNum);
          break;
        case 'homeworkPanelS2':
          setAS2HWIDs(iNum);
          break;
        case 'homeworkPanelQ1':
          setAQ1HWIDs(iNum);
          break;
        case 'homeworkPanelQ2':
          setAQ2HWIDs(iNum);
          break;
        case 'homeworkPanelQ3':
          setAQ3HWIDs(iNum);
          break;
        case 'assignmentPanelS1':
          setAS1AssignmentIDs(iNum);
          break;
        case 'assignmentPanelS2':
          setAS2AssignmentIDs(iNum);
          break;
        case 'quiz1PanelS1':
          setAS1Quiz1IDs(iNum);
          break;
        case 'quiz1PanelS2':
          setAS2Quiz1IDs(iNum);
            break;
        case 'quiz2PanelS1':
          setAS1Quiz2IDs(iNum);
          break;
        case 'quiz2PanelS2':
          setAS2Quiz2IDs(iNum);
            break;
        case 'AttendenceActivitiesS1':
          setAS1CoCurricularIDs(iNum);
          break;
        case 'AttendenceActivitiesS2':
          setAS2CoCurricularIDs(iNum);
            break;
        default:
          break;
      }
      aMarks.unshift(titleTable);
    }
  }

  useEffect(() => {
    const aMarks1 = [];
    const aMarks2 = [];
    const aMarks3 = [];
    const aMarksHW1 = [];
    const aMarksHW2 = [];
    const aMarksHW3 = [];
    const aMarksAssignment1 = [];
    const aMarksAssignment2 = [];
    const aMarks1Quiz1 = [];
    const aMarks2Quiz1 = [];
    const aMarks1Quiz2 = [];
    const aMarks2Quiz2 = [];
    const aMarksCoCurricular1 = [];
    const aMarksCoCurricular2 = [];
    let num1 = 1;
    let num2 = 1;
    let num3 = 1;
    let numHW1 = 1;
    let numHW2 = 1;
    let numHW3 = 1;
    let numAssignment1 = 1;
    let numAssignment2 = 1;
    let num1Quiz1 = 1;
    let num2Quiz1 = 1;
    let num1Quiz2 = 1;
    let num2Quiz2 = 1;
    let numCoCurricular1 = 1;
    let numCoCurricular2 = 1;
    if (systemBased === 'Semester') {
      teacherPanel?.studentsMark.forEach((index) => {
        num1 = getMarks(index, num1, aMarks1, 'S1_marks', true);
        num2 = getMarks(index, num2, aMarks2, 'S2_marks', true);
        num3 = getMarks(index, num3, aMarks3, 'S3_marks', true);
        numHW1 = getMarks(index.homeworkMarks, numHW1, aMarksHW1, 'S1_marks');
        numHW2 = getMarks(index.homeworkMarks, numHW2, aMarksHW2, 'S2_marks');
        numAssignment1 = getMarks(index.assignmentMarks, numAssignment1, aMarksAssignment1, 'S1_marks');
        numAssignment2 = getMarks(index.assignmentMarks, numAssignment2, aMarksAssignment2, 'S2_marks');
        num1Quiz1 = getMarks(index.quizMarks, num1Quiz1, aMarks1Quiz1, 'S1_marks_quiz1');
        num2Quiz1 = getMarks(index.quizMarks, num2Quiz1, aMarks2Quiz1, 'S2_marks_quiz1');
        num1Quiz2 = getMarks(index.quizMarks, num1Quiz2, aMarks1Quiz2, 'S1_marks_quiz2');
        num2Quiz2 = getMarks(index.quizMarks, num2Quiz2, aMarks2Quiz2, 'S2_marks_quiz2');
        numCoCurricular1 = getMarks(index.studentAttendenceActivitiesMarks, numCoCurricular1, aMarksCoCurricular1, 'S1_marks');
        numCoCurricular2 = getMarks(index.studentAttendenceActivitiesMarks, numCoCurricular2, aMarksCoCurricular2, 'S2_marks');
      });
      processMarks(aMarks1, 'academicPanelS1', true);
      processMarks(aMarks2, 'academicPanelS2', true);
      processMarks(aMarks3, 'academicPanelS3', true);
      processMarks(aMarksHW1, 'homeworkPanelS1');
      processMarks(aMarksHW2, 'homeworkPanelS2');
      processMarks(aMarksAssignment1, 'assignmentPanelS1');
      processMarks(aMarksAssignment2, 'assignmentPanelS2');
      processMarks(aMarks1Quiz1, 'quiz1PanelS1');
      processMarks(aMarks2Quiz1, 'quiz1PanelS2');
      processMarks(aMarks1Quiz2, 'quiz2PanelS1');
      processMarks(aMarks2Quiz2, 'quiz2PanelS2');
      processMarks(aMarksCoCurricular1, 'AttendenceActivitiesS1');
      processMarks(aMarksCoCurricular2, 'AttendenceActivitiesS2');

      setCsvDataMarks1(aMarks1);
      setCsvDataHWMarks1(aMarksHW1);
      setCsvDataAssignmentMarks1(aMarksAssignment1);
      setCsvDataQuiz1Marks1(aMarks1Quiz1);
      setCsvDataQuiz2Marks1(aMarks1Quiz2);
      setCsvDataCoCurricularMarks1(aMarksCoCurricular1);
      if (systemBasedType === 'S1') {
        switch (marksType) {
          case 'EXAM MARKS':
            setShowDataMarks(aMarks1);
            break;
          case 'HOMEWORK MARKS':
            setShowDataMarks(aMarksHW1);
            break;
          case 'ASSIGNMENT MARKS':
            setShowDataMarks(aMarksAssignment1);
            break;
          case 'QUIZ 1 MARKS':
            setShowDataMarks(aMarks1Quiz1);
            break;
          case 'QUIZ 2 MARKS':
            setShowDataMarks(aMarks1Quiz2);
            break;
          case 'CO-CURRICULAR MARKS':
            setShowDataMarks(aMarksCoCurricular1);
            break;
          default:
            break;
        }
      }
      setCsvDataMarks2(aMarks2);
      setCsvDataHWMarks2(aMarksHW2);
      setCsvDataAssignmentMarks2(aMarksAssignment2);
      setCsvDataQuiz1Marks2(aMarks2Quiz1);
      setCsvDataQuiz2Marks2(aMarks2Quiz2);
      setCsvDataCoCurricularMarks2(aMarksCoCurricular2);
      if (systemBasedType === 'S2') {
        switch (marksType) {
          case 'EXAM MARKS':
            setShowDataMarks(aMarks2);
            break;
          case 'HOMEWORK MARKS':
            setShowDataMarks(aMarksHW2);
            break;
          case 'ASSIGNMENT MARKS':
            setShowDataMarks(aMarksAssignment2);
            break;
          case 'QUIZ 1 MARKS':
            setShowDataMarks(aMarks2Quiz1);
            break;
          case 'QUIZ 2 MARKS':
            setShowDataMarks(aMarks2Quiz2);
            break;
          case 'CO-CURRICULAR MARKS':
            setShowDataMarks(aMarksCoCurricular2);
            break;
          default:
            break;
        }
      }
      setCsvDataMarks3(aMarks3);
      if (systemBasedType === 'S3' && showS3Marks) {
        setShowDataMarks(aMarks3);
      }
    } else {
      teacherPanel?.studentsMark.forEach((index) => {
        num1 = getMarks(index, num1, aMarks1, 'Q1_marks', true);
        num2 = getMarks(index, num2, aMarks2, 'Q2_marks', true);
        num3 = getMarks(index, num3, aMarks3, 'Q3_marks', true);
        numHW1 = getMarks(index.homeworkMarks, numHW1, aMarksHW1, 'Q1_marks');
        numHW2 = getMarks(index.homeworkMarks, numHW2, aMarksHW2, 'Q2_marks');
        numHW3 = getMarks(index.homeworkMarks, numHW3, aMarksHW3, 'Q3_marks');
      });
      processMarks(aMarks1, 'academicPanelQ1', true);
      processMarks(aMarks2, 'academicPanelQ2', true);
      processMarks(aMarks3, 'academicPanelQ3', true);
      processMarks(aMarksHW1, 'homeworkPanelQ1');
      processMarks(aMarksHW2, 'homeworkPanelQ2');
      processMarks(aMarksHW3, 'homeworkPanelQ3');

      setCsvDataMarks1(aMarks1);
      setCsvDataHWMarks1(aMarksHW1);
      if (systemBasedType === 'Q1') {
        switch (marksType) {
          case 'EXAM MARKS':
            setShowDataMarks(aMarks1);
            break;
          case 'HOMEWORK MARKS':
            setShowDataMarks(aMarksHW1);
            break;
          default:
            break;
        }
      }
      setCsvDataMarks2(aMarks2);
      setCsvDataHWMarks2(aMarksHW2);
      if (systemBasedType === 'Q2') {
        switch (marksType) {
          case 'EXAM MARKS':
            setShowDataMarks(aMarks2);
            break;
          case 'HOMEWORK MARKS':
            setShowDataMarks(aMarksHW2);
            break;
          default:
            break;
        }
      }
      setCsvDataMarks3(aMarks3);
      setCsvDataHWMarks3(aMarksHW3);
      if (systemBasedType === 'Q3') {
        switch (marksType) {
          case 'EXAM MARKS':
            setShowDataMarks(aMarks3);
            break;
          case 'HOMEWORK MARKS':
            setShowDataMarks(aMarksHW3);
            break;
          default:
            break;
        }
      }
    }
  }, [teacherPanel?.studentsMark]);

  useEffect(() => {
    setCsvDataMarks(teacherPanel?.studentsMark);
  }, [teacherPanel?.studentsMark]);

  useEffect(() => {
    const aLocationDuplicate = teacherPanel?.classesByYear?.map((index) => {
      return {
        id: index.locationId,
        name: index.locationName,
        value: index.googleClassId,
      };
    });

    const aLocation = aLocationDuplicate?.reduce((unique, o) => {
      if (!unique.some(obj => obj.id === o.id && obj.name === o.name)) {
        unique.push(o);
      }
      return unique;
    }, []);

    const aCourseByLocation = teacherPanel?.classesByYear?.filter((index) => {
      return index.locationId === aLocation[0].id;
    });

    const aCourse = aCourseByLocation?.map((index) => {
      return {
        id: index.id,
        name: `${index.courseName} (${index.sectionName})`,
        courseId: index.courseId,
        googleClassId: index.googleClassId,
        courseName: index.courseName,
      };
    });

    aCourse.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setLocationData(aLocation);

    setCourseData(aCourse);
    if (aLocation.length !== 0) {
      setChangeYear(false);
      const classRoomData = teacherPanel?.classesByYear?.filter((data) => data?.googleClassId === teacherPanel?.selectedClassroom?.value);
      if (classRoomData.length > 0) {
        const locData = {
          id: classRoomData[0]?.locationId,
          name: classRoomData[0]?.locationName,
        };

        setSelectedlocation(locData?.id);
        setSelectedlocationName(locData?.name);
      } else {
        setSelectedlocation(aLocation[0]?.id);
        setSelectedlocationName(aLocation[0]?.name);
        dispatch(setSelectedClassRooms(aLocation[0]));
      }

      if (teacherPanel?.selectedClassroom?.value) {
        const sCourse = teacherPanel?.classesByYear?.filter((data) => data?.googleClassId === teacherPanel?.selectedClassroom?.value);
        const coursesInSameLoc = teacherPanel?.classesByYear?.filter((data) => data?.locationId === sCourse[0]?.locationId);
        const mappedCourses = coursesInSameLoc?.map((course) => {
          return {
            id: course?.id,
            name: `${course?.courseName} (${course?.sectionName})`,
            courseId: course?.courseId,
            googleClassId: course?.googleClassId,
            courseName: course?.courseName,
          };
        });
        setCourseData(mappedCourses);
        const matchedCourses = mappedCourses?.filter((data) => data?.googleClassId === teacherPanel?.selectedClassroom?.value);
        const courseData1 = {
          id: matchedCourses[0]?.id,
          name: `${matchedCourses[0]?.name}`,
          courseId: matchedCourses[0]?.courseId,
          googleClassId: matchedCourses[0]?.googleClassId,
          courseName: matchedCourses[0]?.courseName
        };
        if (courseData1?.courseName === "BalaBadi - I" || courseData1?.courseName === "BalaBadi - II" || courseData1?.courseName === "Telugu-1" || courseData1?.courseName === "Telugu-3") {
          setShowS3('');
        } else if (courseData1?.courseName === "Prabhasam" || courseData1?.courseName === "Prakasam" || courseData1?.courseName === "Telugu-2" || courseData1?.courseName === "Telugu-4") {
          setShowS3('PSTU');
        } else {
          setShowS3('Supplementary');
        }
        if (systemBased === 'Quarter') {
          setBorderBottomQ1(2);
          setBorderBottomQ2(0);
          setBorderBottomQ3(0);
          setBorderBottomS1(0);
          setBorderBottomS2(0);
          setBorderBottomS3(0);
          setBorderBottomExamMarks(2);
          setBorderBottomHomeworkMarks(0);
          setBorderBottomAssignmentMarks(0);
          setBorderBottomCoCurricularMarks(0);
          setBorderBottomQuiz1Marks(0);
          setBorderBottomQuiz2Marks(0);
          setMarksType('EXAM MARKS');
          setSystemBasedType('Q1');
          setShowDataMarks(csvDataMarks1);
          setShowNameSheet('Marks_Q1_Exam_Marks');
        } else {
          setBorderBottomS1(2);
          setBorderBottomS2(0);
          setBorderBottomS3(0);
          setBorderBottomQ1(0);
          setBorderBottomQ2(0);
          setBorderBottomQ3(0);
          setBorderBottomExamMarks(2);
          setBorderBottomHomeworkMarks(0);
          setBorderBottomAssignmentMarks(0);
          setBorderBottomCoCurricularMarks(0);
          setBorderBottomQuiz1Marks(0);
          setBorderBottomQuiz2Marks(0);
          setMarksType('EXAM MARKS');
          setSystemBasedType('S1');
          setShowDataMarks(csvDataMarks1);
          setShowNameSheet('Marks_S1_Exam_Marks');
        }
        setSelectedCourse(courseData1?.id);
        setSelectedCourseId(courseData1?.courseId);
        setSelectedCourseName(courseData1?.name);
        setSelectedGoogleClassId(courseData1?.googleClassId);
      } else {
        if (aCourse[0].courseName === "BalaBadi - I" || aCourse[0].courseName === "BalaBadi - II" || aCourse[0].courseName === "Telugu-1" || aCourse[0].courseName === "Telugu-3") {
          setShowS3('');
        } else if (aCourse[0].courseName === "Prabhasam" || aCourse[0].courseName === "Prakasam" || aCourse[0].courseName === "Telugu-2" || aCourse[0].courseName === "Telugu-4") {
          setShowS3('PSTU');
        } else {
          setShowS3('Supplementary');
        }
        if (systemBased === 'Quarter') {
          setBorderBottomQ1(2);
          setBorderBottomQ2(0);
          setBorderBottomQ3(0);
          setBorderBottomS1(0);
          setBorderBottomS2(0);
          setBorderBottomS3(0);
          setBorderBottomExamMarks(2);
          setBorderBottomHomeworkMarks(0);
          setBorderBottomAssignmentMarks(0);
          setBorderBottomCoCurricularMarks(0);
          setBorderBottomQuiz1Marks(0);
          setBorderBottomQuiz2Marks(0);
          setMarksType('EXAM MARKS');
          setSystemBasedType('Q1');
          setShowDataMarks(csvDataMarks1);
          setShowNameSheet('Marks_Q1_Exam_Marks');
        } else {
          setBorderBottomS1(2);
          setBorderBottomS2(0);
          setBorderBottomS3(0);
          setBorderBottomQ1(0);
          setBorderBottomQ2(0);
          setBorderBottomQ3(0);
          setBorderBottomExamMarks(2);
          setBorderBottomHomeworkMarks(0);
          setBorderBottomAssignmentMarks(0);
          setBorderBottomCoCurricularMarks(0);
          setBorderBottomQuiz1Marks(0);
          setBorderBottomQuiz2Marks(0);
          setMarksType('EXAM MARKS');
          setSystemBasedType('S1');
          setShowDataMarks(csvDataMarks1);
          setShowNameSheet('Marks_S1_Exam_Marks');
        }
        setSelectedCourse(aCourse[0].id);
        setSelectedCourseId(aCourse[0].courseId);
        setSelectedCourseName(aCourse[0].name);
        setSelectedGoogleClassId(aCourse[0].googleClassId);
      }
    }
  }, [teacherPanel?.selectedClassroom, teacherPanel?.classesByYear]);

  useEffect(() => {
    if (isSelectedlocation && isselectedYear && isSelectedCourse) {
      const payloadObj = {
        academicYear: isselectedYear,
        locationId: isSelectedlocation,
        courseId: isSelectedCourse,
      };
      dispatch(getMarksDetails(payloadObj, setOpenDialogErrorImport, setDialogErrorImportMessage));
    }
  }, [isSelectedlocation, isselectedYear, isSelectedCourse]);

  useEffect(() => {
    setExportSheetTime(reduxStore.exportSheetTime);
  }, [reduxStore.exportSheetTime]);

  useEffect(() => {
    setImportSheetTime(reduxStore.importSheetTime);
    setCreateAtSheet(reduxStore.createdAtSheetTime);
  }, [reduxStore.importSheetTime, reduxStore.createdAtSheetTime]);

  useEffect(() => {
    if (moment(reduxStore.createdAtSheetTime).isAfter(moment(reduxStore.exportedAtSheetTime))) {
      setOpenDialogExportFirst(true);
    } else {
      setOpenDialogExportFirst(false);
    }
  }, [reduxStore.exportedAtSheetTime, reduxStore.createdAtSheetTime]);

  const loadFalse = () => setLoading(false);
  useEffect(() => {
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  useEffect(() => {
    const currentAcademicYear = getCurrentYear()?.id;
    const index = assignedYears.findIndex((cur) => cur.name === currentAcademicYear);
    if (index >= 0) {
      if (selectedYearRedux?.id) {
        const yearFilter = parseInt(selectedYearRedux?.id.split('-')[0]);
        if (yearFilter >= 2023) {
          setSystemBased('Semester');
          setShowNameSheet('Marks_S1_Exam_Marks');
          handleChangeMarksButtons('S1', 'EXAM MARKS');
        } else {
          setSystemBased('Quarter');
          setShowNameSheet('Marks_Q1_Exam_Marks');
          handleChangeMarksButtons('Q1', 'EXAM MARKS');
        }
        setSelectYear(selectedYearRedux?.id);
      } else {
        const yearFilter = parseInt(assignedYears[index]?.id.split('-')[0]);
        if (yearFilter >= 2023) {
          setSystemBased('Semester');
          setShowNameSheet('Marks_S1_Exam_Marks');
          handleChangeMarksButtons('S1', 'EXAM MARKS');
        } else {
          setSystemBased('Quarter');
          setShowNameSheet('Marks_Q1_Exam_Marks');
          handleChangeMarksButtons('Q1', 'EXAM MARKS');
        }
        setSelectYear(assignedYears[index]?.id);
      }
    }
  }, [assignedYears, selectedYearRedux?.id]);

  useEffect(() => {
    if (isselectedYear !== undefined) {
      const payload = {
        academicYear: isselectedYear,
      };
      dispatch(classesByYearTeacher(payload, setLoading));
    }
  }, [isselectedYear]);

  const getGoogleSheet = (callback) => {
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://www.googleapis.com/drive/v3/files?pageSize=1000&supportAllDrives=false&includeItemsFromAllDrives=false&q="root" in parents&fields=files(id,name,createdTime,modifiedTime,size,webViewLink)');
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
    xhr.onload = () => {
      callback(xhr.responseText);
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };

  const refreshList = () => {
    setLoading(true);
    const payload = {
      academicYear: isselectedYear,
      locationId: isSelectedlocation,
      courseId: isSelectedCourseId,
      googleClassId: selectedGoogleClassId,
    };
    localStorage.setItem('CourseTeacher', isSelectedCourseId);
    localStorage.setItem('LocationTeacher', isSelectedlocation);
    setListRefreshed(false);
    dispatch(studentsByLocationMarks(payload, setLoading, setOpenDialogErrorGetData, setListRefreshed));
    dispatch(getMarksDetails(payload, setOpenDialogErrorImport, setDialogErrorImportMessage));
    getGoogleSheet((i) => {
      const fileData = JSON.parse(i).files;
      if (fileData === undefined) {
        setOpenDialogError(true);
        setLoading(false);
      } else {
        const sNameSheet = fileData.filter(
          (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
        );
        if (sNameSheet.length !== 0) {
          setNameSheet(sNameSheet[0].name);
          setIdSheet(sNameSheet[0].id);
        } else {
          setNameSheet('Sheet has not been exported');
          setIdSheet();
        }
      }
    });
  };
  useEffect(() => {
    if (isselectedYear && isSelectedlocation && isSelectedCourse && !changeYear) {
      refreshList();
    }
  }, [isselectedYear, isSelectedlocation, isSelectedCourse]);

  const handleYearDropDownChange = (data) => {
    setListRefreshed(false);
    const { value } = data.target;
    setLoading(true);
    setChangeYear(true);
    setSelectYear(value);
    const payload = {
      id: data.target.value,
      name: data.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const handlelocationDropDownChange = (data) => {
    const { value } = data.target;
    const aCourseByLocation = teacherPanel?.classesByYear?.filter((index) => {
      return index.locationId === value;
    });
    const aCourse = aCourseByLocation?.map((index) => {
      return {
        id: index.id,
        name: `${index.courseName} (${index.sectionName})`,
        courseId: index.courseId,
        googleClassId: index.googleClassId,
        courseName: index.courseName,
      };
    });
    aCourse.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    if (data) {
      const payload = {
        name: 'googleClass',
        value: aCourseByLocation[0]?.googleClassId,
      };
      dispatch(setSelectedClassRooms(payload));
    }
    if (aCourse[0]?.courseName === "BalaBadi - I" || aCourse[0]?.courseName === "BalaBadi - II" || aCourse[0]?.courseName === "Telugu-1" || aCourse[0]?.courseName === "Telugu-3") {
      setShowS3('');
    } else if (aCourse[0]?.courseName === "Prabhasam" || aCourse[0]?.courseName === "Prakasam" || aCourse[0]?.courseName === "Telugu-2" || aCourse[0]?.courseName === "Telugu-4") {
      setShowS3('PSTU');
    } else {
      setShowS3('Supplementary');
    }
    if (systemBased === 'Quarter') {
      setBorderBottomQ1(2);
      setBorderBottomQ2(0);
      setBorderBottomQ3(0);
      setBorderBottomS1(0);
      setBorderBottomS2(0);
      setBorderBottomS3(0);
      setShowDataMarks(csvDataMarks1);
      setShowNameSheet('Marks_Q1_Exam_Marks');
    } else {
      setBorderBottomS1(2);
      setBorderBottomS2(0);
      setBorderBottomS3(0);
      setBorderBottomQ1(0);
      setBorderBottomQ2(0);
      setBorderBottomQ3(0);
      setShowDataMarks(csvDataMarks1);
      setShowNameSheet('Marks_S1_Exam_Marks');
    }

    setCourseData(aCourse);
    setSelectedlocation(value);
    setSelectedlocationName(aCourseByLocation[0].locationName);
    setSelectedCourse(aCourse[0].id);
    setSelectedCourseName(aCourse[0].name);
    setSelectedCourseId(aCourse[0].courseId);
    setSelectedGoogleClassId(aCourse[0].googleClassId);
  };

  const handleClassWeekDropDownChange = (data) => {
    const { value } = data.target;
    const aCourseId = teacherPanel?.classesByYear?.filter((index) => {
      return index.id === value;
    });
    if (data) {
      const payload = {
        name: 'googleClass',
        value: aCourseId[0]?.googleClassId,
      };
      dispatch(setSelectedClassRooms(payload));
    }
    if (aCourseId[0]?.courseName === "BalaBadi - I" || aCourseId[0]?.courseName === "BalaBadi - II" || aCourseId[0]?.courseName === "Telugu-1" || aCourseId[0]?.courseName === "Telugu-3") {
      setShowS3('');
    } else if (aCourseId[0]?.courseName === "Prabhasam" || aCourseId[0]?.courseName === "Prakasam" || aCourseId[0]?.courseName === "Telugu-2" || aCourseId[0]?.courseName === "Telugu-4") {
      setShowS3('PSTU');
    } else {
      setShowS3('Supplementary');
    }
    if (systemBased === 'Quarter') {
      setBorderBottomQ1(2);
      setBorderBottomQ2(0);
      setBorderBottomQ3(0);
      setBorderBottomS1(0);
      setBorderBottomS2(0);
      setBorderBottomS3(0);
      setBorderBottomExamMarks(2);
      setBorderBottomHomeworkMarks(0);
      setBorderBottomAssignmentMarks(0);
      setBorderBottomCoCurricularMarks(0);
      setBorderBottomQuiz1Marks(0);
      setBorderBottomQuiz2Marks(0);
      setMarksType('EXAM MARKS');
      setSystemBasedType('Q1');
      setShowDataMarks(csvDataMarks1);
      setShowNameSheet('Marks_Q1_Exam_Marks');
    } else {
      setBorderBottomS1(2);
      setBorderBottomS2(0);
      setBorderBottomS3(0);
      setBorderBottomQ1(0);
      setBorderBottomQ2(0);
      setBorderBottomQ3(0);
      setBorderBottomExamMarks(2);
      setBorderBottomHomeworkMarks(0);
      setBorderBottomAssignmentMarks(0);
      setBorderBottomCoCurricularMarks(0);
      setBorderBottomQuiz1Marks(0);
      setBorderBottomQuiz2Marks(0);
      setMarksType('EXAM MARKS');
      setSystemBasedType('S1');
      setShowDataMarks(csvDataMarks1);
      setShowNameSheet('Marks_S1_Exam_Markss');
    }
    setSelectedCourseId(aCourseId[0].courseId);
    setSelectedCourse(value);
    setSelectedCourseName(`${aCourseId[0].courseName} (${aCourseId[0].sectionName})`);
    setSelectedGoogleClassId(aCourseId[0].googleClassId);
  };

  const sendQuarterSheet = () => {
    if (idSheet === undefined) {
      const payloadPutSheet = [];
      const newRowsQ1 = {
        updateCells: {
          rows: [
            {
              values: [
                {
                  userEnteredValue: {
                    stringValue: 'Student Name',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Total Marks',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Is Absent',
                  },
                },
              ],
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };
      const newRowsHWQ1 = {
        updateCells: {
          rows: [
            {
              values: [
                {
                  userEnteredValue: {
                    stringValue: 'Student Name',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Total Marks',
                  },
                },
              ],
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };
      const newRowsQ2 = {
        updateCells: {
          rows: [
            {
              values: [
                {
                  userEnteredValue: {
                    stringValue: 'Student Name',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Total Marks',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Is Absent',
                  },
                },
              ],
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };
      const newRowsHWQ2 = {
        updateCells: {
          rows: [
            {
              values: [
                {
                  userEnteredValue: {
                    stringValue: 'Student Name',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Total Marks',
                  },
                },
              ],
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };
      const newRowsQ3 = {
        updateCells: {
          rows: [
            {
              values: [
                {
                  userEnteredValue: {
                    stringValue: 'Student Name',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Total Marks',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Is Absent',
                  },
                },
              ],
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };
      const newRowsHWQ3 = {
        updateCells: {
          rows: [
            {
              values: [
                {
                  userEnteredValue: {
                    stringValue: 'Student Name',
                  },
                },
                {
                  userEnteredValue: {
                    stringValue: 'Total Marks',
                  },
                },
              ],
            },
          ],
          start: {
            rowIndex: 0,
            columnIndex: 0,
            sheetId: '',
          },
          fields: 'userEnteredValue',
        },
      };
      for (let studentsQ1 = 0; studentsQ1 < teacherPanel?.studentsMark.length; studentsQ1++) {
        if (teacherPanel?.studentsMark[studentsQ1].academicPanelQ1?.length > 0) {
          for (let notesQ1 = 0; notesQ1 < teacherPanel?.studentsMark[studentsQ1].academicPanelQ1.length; notesQ1++) {
            newRowsQ1.updateCells.rows[0].values.push({
              userEnteredValue: {
                stringValue: `${teacherPanel?.studentsMark[studentsQ1].academicPanelQ1[notesQ1].notes} / ${teacherPanel?.studentsMark[studentsQ1].academicPanelQ1[notesQ1].marks}`,
              },
            });
          }
          break;
        }
      }
      for (let studentsQ1 = 0; studentsQ1 < teacherPanel?.studentsMark.length; studentsQ1++) {
        if (teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1?.length > 0) {
          for (let notesQ1 = 0; notesQ1 < teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1.length; notesQ1++) {
            newRowsHWQ1.updateCells.rows[0].values.push({
              userEnteredValue: {
                stringValue: `${teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1[notesQ1].notes} / ${teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1[notesQ1].marks}`,
              },
            });
          }
          break;
        }
      }
      for (let studentsQ2 = 0; studentsQ2 < teacherPanel?.studentsMark.length; studentsQ2++) {
        if (teacherPanel?.studentsMark[studentsQ2].academicPanelQ2?.length > 0) {
          for (let notesQ2 = 0; notesQ2 < teacherPanel?.studentsMark[studentsQ2].academicPanelQ2.length; notesQ2++) {
            newRowsQ2.updateCells.rows[0].values.push({
              userEnteredValue: {
                stringValue: `${teacherPanel?.studentsMark[studentsQ2].academicPanelQ2[notesQ2].notes} / ${teacherPanel?.studentsMark[studentsQ2].academicPanelQ2[notesQ2].marks}`,
              },
            });
          }
          break;
        }
      }
      for (let studentsQ2 = 0; studentsQ2 < teacherPanel?.studentsMark.length; studentsQ2++) {
        if (teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2?.length > 0) {
          for (let notesQ2 = 0; notesQ2 < teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2.length; notesQ2++) {
            newRowsHWQ2.updateCells.rows[0].values.push({
              userEnteredValue: {
                stringValue: `${teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2[notesQ2].notes} / ${teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2[notesQ2].marks}`,
              },
            });
          }
          break;
        }
      }
      for (let studentsQ3 = 0; studentsQ3 < teacherPanel?.studentsMark.length; studentsQ3++) {
        if (teacherPanel?.studentsMark[studentsQ3].academicPanelQ3?.length > 0) {
          for (let notesQ3 = 0; notesQ3 < teacherPanel?.studentsMark[studentsQ3].academicPanelQ3.length; notesQ3++) {
            newRowsQ3.updateCells.rows[0].values.push({
              userEnteredValue: {
                stringValue: `${teacherPanel?.studentsMark[studentsQ3].academicPanelQ3[notesQ3].notes} / ${teacherPanel?.studentsMark[studentsQ3].academicPanelQ3[notesQ3].marks}`,
              },
            });
          }
          break;
        }
      }
      for (let studentsQ3 = 0; studentsQ3 < teacherPanel?.studentsMark.length; studentsQ3++) {
        if (teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3?.length > 0) {
          for (let notesQ3 = 0; notesQ3 < teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3.length; notesQ3++) {
            newRowsHWQ3.updateCells.rows[0].values.push({
              userEnteredValue: {
                stringValue: `${teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3[notesQ3].notes} / ${teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3[notesQ3].marks}`,
              },
            });
          }
          break;
        }
      }
      const aMarksQ1 = csvDataMarks1.slice(1);
      const aMarksQ2 = csvDataMarks2.slice(1);
      const aMarksQ3 = csvDataMarks3.slice(1);
      const aSquemaQ1 = csvDataMarks1[0];
      const aSquemaQ2 = csvDataMarks2[0];
      const aSquemaQ3 = csvDataMarks3[0];
      const aMarksHWQ1 = csvDataHWMarks1.slice(1);
      const aMarksHWQ2 = csvDataHWMarks2.slice(1);
      const aMarksHWQ3 = csvDataHWMarks3.slice(1);
      const aSquemaHWQ1 = csvDataHWMarks1[0];
      const aSquemaHWQ2 = csvDataHWMarks2[0];
      const aSquemaHWQ3 = csvDataHWMarks3[0];
      for (let indexQ1 = 0; indexQ1 < aMarksQ1.length; indexQ1++) {
        const newValuesQ1 = {
          values: [],
        };
        let indexAlphabet = 2;
        for (const pQ1 in aSquemaQ1) {
          if (pQ1.substring(0, 4) === 'mark') {
            indexAlphabet += 1;
          }
        }
        const letterAlphabet = alphabet[indexAlphabet];
        for (const pQ1 in aMarksQ1[indexQ1]) {
          if (pQ1 === 'studentName' || aMarksQ1[indexQ1][pQ1] === '') {
            if (pQ1 === 'studentName') {
              newValuesQ1.values.unshift({
                userEnteredValue: {
                  stringValue: aMarksQ1[indexQ1][pQ1],
                },
              });
            } else {
              newValuesQ1.values.push({
                userEnteredValue: {
                  stringValue: aMarksQ1[indexQ1][pQ1],
                },
              });
            }
          } else if (pQ1 === 'isAbsent') {
            newValuesQ1.values.push({
              userEnteredValue: {
                boolValue: aMarksQ1[indexQ1][pQ1],
              },
            });
          } else if (pQ1.substring(0, 4) === 'mark' && aMarksQ1[indexQ1][pQ1] !== '') {
            newValuesQ1.values.push({
              userEnteredValue: {
                numberValue: aMarksQ1[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'totalMarks') {
            newValuesQ1.values.push({
              userEnteredValue: {
                formulaValue: `=IF(AND(SUM(D${aMarksQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksQ1[indexQ1].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelQ1_maxMarks}),SUM(D${aMarksQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksQ1[indexQ1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelQ1_maxMarks}")`,
              },
            });
          }
        }
        newRowsQ1.updateCells.rows.push(newValuesQ1);
      }
      for (let indexQ1 = 0; indexQ1 < aMarksHWQ1.length; indexQ1++) {
        const newValuesQ1 = {
          values: [],
        };
        let indexAlphabet = 1;
        for (const pQ1 in aSquemaHWQ1) {
          if (pQ1.substring(0, 4) === 'mark') {
            indexAlphabet += 1;
          }
        }
        indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
        const letterAlphabet = alphabet[indexAlphabet];
        for (const pQ1 in aMarksHWQ1[indexQ1]) {
          if (pQ1 === 'studentName' || aMarksHWQ1[indexQ1][pQ1] === '') {
            if (pQ1 === 'studentName') {
              newValuesQ1.values.unshift({
                userEnteredValue: {
                  stringValue: aMarksHWQ1[indexQ1][pQ1],
                },
              });
            } else {
              newValuesQ1.values.push({
                userEnteredValue: {
                  stringValue: aMarksHWQ1[indexQ1][pQ1],
                },
              });
            }
          } else if (pQ1.substring(0, 4) === 'mark' && aMarksHWQ1[indexQ1][pQ1] !== '') {
            newValuesQ1.values.push({
              userEnteredValue: {
                numberValue: aMarksHWQ1[indexQ1][pQ1],
              },
            });
          } else if (pQ1 === 'totalMarks') {
            newValuesQ1.values.push({
              userEnteredValue: {
                formulaValue: `=IF(AND(SUM(C${aMarksHWQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksHWQ1[indexQ1].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ1_maxMarks}),SUM(C${aMarksHWQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksHWQ1[indexQ1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ1_maxMarks}")`,
              },
            });
          }
        }
        newRowsHWQ1.updateCells.rows.push(newValuesQ1);
      }
      for (let indexQ2 = 0; indexQ2 < aMarksQ2.length; indexQ2++) {
        const newValuesQ2 = {
          values: [],
        };
        let indexAlphabet = 2;
        for (const pQ2 in aSquemaQ2) {
          if (pQ2.substring(0, 4) === 'mark') {
            indexAlphabet += 1;
          }
        }
        const letterAlphabet = alphabet[indexAlphabet];
        for (const pQ2 in aMarksQ2[indexQ2]) {
          if (pQ2 === 'studentName' || aMarksQ2[indexQ2][pQ2] === '') {
            if (pQ2 === 'studentName') {
              newValuesQ2.values.unshift({
                userEnteredValue: {
                  stringValue: aMarksQ2[indexQ2][pQ2],
                },
              });
            } else {
              newValuesQ2.values.push({
                userEnteredValue: {
                  stringValue: aMarksQ2[indexQ2][pQ2],
                },
              });
            }
          } else if (pQ2 === 'isAbsent') {
            newValuesQ2.values.push({
              userEnteredValue: {
                boolValue: aMarksQ2[indexQ2][pQ2],
              },
            });
          } else if (pQ2.substring(0, 4) === 'mark' && aMarksQ2[indexQ2][pQ2] !== '') {
            newValuesQ2.values.push({
              userEnteredValue: {
                numberValue: aMarksQ2[indexQ2][pQ2],
              },
            });
          } else if (pQ2 === 'totalMarks') {
            newValuesQ2.values.push({
              userEnteredValue: {
                formulaValue: `=IF(AND(SUM(D${aMarksQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksQ2[indexQ2].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelQ2_maxMarks}),SUM(D${aMarksQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksQ2[indexQ2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelQ2_maxMarks}")`,
              },
            });
          }
        }
        newRowsQ2.updateCells.rows.push(newValuesQ2);
      }
      for (let indexQ2 = 0; indexQ2 < aMarksHWQ2.length; indexQ2++) {
        const newValuesQ2 = {
          values: [],
        };
        let indexAlphabet = 1;
        for (const pQ2 in aSquemaHWQ2) {
          if (pQ2.substring(0, 4) === 'mark') {
            indexAlphabet += 1;
          }
        }
        indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
        const letterAlphabet = alphabet[indexAlphabet];
        for (const pQ2 in aMarksHWQ2[indexQ2]) {
          if (pQ2 === 'studentName' || aMarksHWQ2[indexQ2][pQ2] === '') {
            if (pQ2 === 'studentName') {
              newValuesQ2.values.unshift({
                userEnteredValue: {
                  stringValue: aMarksHWQ2[indexQ2][pQ2],
                },
              });
            } else {
              newValuesQ2.values.push({
                userEnteredValue: {
                  stringValue: aMarksHWQ2[indexQ2][pQ2],
                },
              });
            }
          } else if (pQ2.substring(0, 4) === 'mark' && aMarksHWQ2[indexQ2][pQ2] !== '') {
            newValuesQ2.values.push({
              userEnteredValue: {
                numberValue: aMarksHWQ2[indexQ2][pQ2],
              },
            });
          } else if (pQ2 === 'totalMarks') {
            newValuesQ2.values.push({
              userEnteredValue: {
                formulaValue: `=IF(AND(SUM(C${aMarksHWQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksHWQ2[indexQ2].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ2_maxMarks}),SUM(C${aMarksHWQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksHWQ2[indexQ2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ2_maxMarks}")`,
              },
            });
          }
        }
        newRowsHWQ2.updateCells.rows.push(newValuesQ2);
      }
      for (let indexQ3 = 0; indexQ3 < aMarksQ3.length; indexQ3++) {
        const newValuesQ3 = {
          values: [],
        };
        let indexAlphabet = 2;
        for (const pQ3 in aSquemaQ3) {
          if (pQ3.substring(0, 4) === 'mark') {
            indexAlphabet += 1;
          }
        }
        const letterAlphabet = alphabet[indexAlphabet];
        for (const pQ3 in aMarksQ3[indexQ3]) {
          if (pQ3 === 'studentName' || aMarksQ3[indexQ3][pQ3] === '') {
            if (pQ3 === 'studentName') {
              newValuesQ3.values.unshift({
                userEnteredValue: {
                  stringValue: aMarksQ3[indexQ3][pQ3],
                },
              });
            } else {
              newValuesQ3.values.push({
                userEnteredValue: {
                  stringValue: aMarksQ3[indexQ3][pQ3],
                },
              });
            }
          } else if (pQ3 === 'isAbsent') {
            newValuesQ3.values.push({
              userEnteredValue: {
                boolValue: aMarksQ3[indexQ3][pQ3],
              },
            });
          } else if (pQ3.substring(0, 4) === 'mark' && aMarksQ3[indexQ3][pQ3] !== '') {
            newValuesQ3.values.push({
              userEnteredValue: {
                numberValue: aMarksQ3[indexQ3][pQ3],
              },
            });
          } else if (pQ3 === 'totalMarks') {
            newValuesQ3.values.push({
              userEnteredValue: {
                formulaValue: `=IF(AND(SUM(D${aMarksQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksQ3[indexQ3].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelQ3_maxMarks}),SUM(D${aMarksQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksQ3[indexQ3].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelQ3_maxMarks}")`,
              },
            });
          }
        }
        newRowsQ3.updateCells.rows.push(newValuesQ3);
      }
      for (let indexQ3 = 0; indexQ3 < aMarksHWQ3.length; indexQ3++) {
        const newValuesQ3 = {
          values: [],
        };
        let indexAlphabet = 1;
        for (const pQ3 in aSquemaHWQ3) {
          if (pQ3.substring(0, 4) === 'mark') {
            indexAlphabet += 1;
          }
        }
        indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
        const letterAlphabet = alphabet[indexAlphabet];
        for (const pQ3 in aMarksHWQ3[indexQ3]) {
          if (pQ3 === 'studentName' || aMarksHWQ3[indexQ3][pQ3] === '') {
            if (pQ3 === 'studentName') {
              newValuesQ3.values.unshift({
                userEnteredValue: {
                  stringValue: aMarksHWQ3[indexQ3][pQ3],
                },
              });
            } else {
              newValuesQ3.values.push({
                userEnteredValue: {
                  stringValue: aMarksHWQ3[indexQ3][pQ3],
                },
              });
            }
          } else if (pQ3.substring(0, 4) === 'mark' && aMarksHWQ3[indexQ3][pQ3] !== '') {
            newValuesQ3.values.push({
              userEnteredValue: {
                numberValue: aMarksHWQ3[indexQ3][pQ3],
              },
            });
          } else if (pQ3 === 'totalMarks') {
            newValuesQ3.values.push({
              userEnteredValue: {
                formulaValue: `=IF(AND(SUM(C${aMarksHWQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksHWQ3[indexQ3].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ3_maxMarks}),SUM(C${aMarksHWQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksHWQ3[indexQ3].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ3_maxMarks}")`,
              },
            });
          }
        }
        newRowsHWQ3.updateCells.rows.push(newValuesQ3);
      }
      if (newRowsQ1.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            {
              repeatCell: {
                cell: {
                  dataValidation: {
                    condition: {
                      type: 'BOOLEAN',
                    },
                  },
                },
                range: {
                  sheetId: '',
                  startRowIndex: 1,
                  endRowIndex: newRowsQ1.updateCells.rows.length,
                  startColumnIndex: 2,
                  endColumnIndex: 3,
                },
                fields: 'dataValidation',
              },
            },
            newRowsQ1,
          ],
        });
      }
      if (newRowsHWQ1.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            newRowsHWQ1,
          ],
        });
      }
      if (newRowsQ2.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            {
              repeatCell: {
                cell: {
                  dataValidation: {
                    condition: {
                      type: 'BOOLEAN',
                    },
                  },
                },
                range: {
                  sheetId: '',
                  startRowIndex: 1,
                  endRowIndex: newRowsQ2.updateCells.rows.length,
                  startColumnIndex: 2,
                  endColumnIndex: 3,
                },
                fields: 'dataValidation',
              },
            },
            newRowsQ2,
          ],
        });
      }
      if (newRowsHWQ2.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            newRowsHWQ2,
          ],
        });
      }
      if (newRowsQ3.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            {
              repeatCell: {
                cell: {
                  dataValidation: {
                    condition: {
                      type: 'BOOLEAN',
                    },
                  },
                },
                range: {
                  sheetId: '',
                  startRowIndex: 1,
                  endRowIndex: newRowsQ3.updateCells.rows.length,
                  startColumnIndex: 2,
                  endColumnIndex: 3,
                },
                fields: 'dataValidation',
              },
            },
            newRowsQ3,
          ],
        });
      }
      if (newRowsHWQ3.updateCells.rows[1]?.values.length !== 0) {
        payloadPutSheet.push({
          requests: [
            {
              appendDimension: {
                sheetId: '',
                dimension: 'COLUMNS',
                length: 50,
              },
            },
            newRowsHWQ3,
          ],
        });
      }
      if (newRowsQ1.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          stringValue: 'Student Name',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Total Marks',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Is Absent',
                        },
                      },
                    ],
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      if (newRowsHWQ1.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          stringValue: 'Student Name',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Total Marks',
                        },
                      },
                    ],
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      if (newRowsQ2.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          stringValue: 'Student Name',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Total Marks',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Is Absent',
                        },
                      },
                    ],
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      if (newRowsHWQ2.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          stringValue: 'Student Name',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Total Marks',
                        },
                      },
                    ],
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      if (newRowsQ3.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          stringValue: 'Student Name',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Total Marks',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Is Absent',
                        },
                      },
                    ],
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      if (newRowsHWQ3.updateCells.rows[1]?.values.length === 0) {
        payloadPutSheet.push({
          requests: [
            {
              updateCells: {
                rows: [
                  {
                    values: [
                      {
                        userEnteredValue: {
                          stringValue: 'Student Name',
                        },
                      },
                      {
                        userEnteredValue: {
                          stringValue: 'Total Marks',
                        },
                      },
                    ],
                  },
                ],
                start: {
                  rowIndex: 0,
                  columnIndex: 0,
                  sheetId: '',
                },
                fields: 'userEnteredValue',
              },
            },
          ],
        });
      }
      const payloadCreateSheet = {
        properties: {
          title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
        },
        sheets: [
          {
            properties: {
              title: 'Marks_Q1_Exam_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q1_Homework_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q2_Exam_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q2_Homework_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q3_Exam_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q3_Homework_Marks',
            },
          },
        ],
      };
      setLoading(true);
      const gettoken = getLocalStorage('accessToken');
      const baseUrl = JSON.parse(gettoken);
      const uploader = new MediaUploader({
        token: baseUrl?.access_token,
        filename: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
        contentType: 'application/vnd.google-apps.spreadsheet',
        metadata: payloadCreateSheet,
        sheetMood: 'New',
        file: payloadPutSheet,
        onComplete: async () => {
          await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('export'), setOpenDialogErrorImport, setDialogErrorImportMessage));
          setLoading(false);
          setOpenSheet(true);
          const payload = {
            academicYear: isselectedYear,
            locationId: isSelectedlocation,
            courseId: isSelectedCourseId,
          };
          dispatch(getMarksDetails(payload, setOpenDialogErrorImport, setDialogErrorImportMessage));
          getGoogleSheet((i) => {
            const fileData = JSON.parse(i).files;
            if (fileData !== undefined) {
              const sNameSheet = fileData.filter(
                (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
              );
              if (sNameSheet.length !== 0) {
                setNameSheet(sNameSheet[0].name);
                setIdSheet(sNameSheet[0].id);
              } else {
                setNameSheet('Sheet has not been exported');
                setIdSheet();
              }
            } else {
              setShowTokenAdvice(true);
              setLoading(false);
            }
          });
        },
        onError: () => {
          setShowTokenAdvice(true);
          setLoading(false);
        },
      });
      uploader.upload();
    } else {
      setOpenSheetExists(true);
    }
  };

  const exportQuarterSheetExists = () => {
    const payloadPutSheet = [];
    const newRowsQ1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Is Absent',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsHWQ1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsQ2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Is Absent',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsHWQ2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsQ3 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Is Absent',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsHWQ3 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    for (let studentsQ1 = 0; studentsQ1 < teacherPanel?.studentsMark.length; studentsQ1++) {
      if (teacherPanel?.studentsMark[studentsQ1].academicPanelQ1?.length > 0) {
        for (let notesQ1 = 0; notesQ1 < teacherPanel?.studentsMark[studentsQ1].academicPanelQ1.length; notesQ1++) {
          newRowsQ1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsQ1].academicPanelQ1[notesQ1].notes} / ${teacherPanel?.studentsMark[studentsQ1].academicPanelQ1[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsQ1 = 0; studentsQ1 < teacherPanel?.studentsMark.length; studentsQ1++) {
      if (teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1?.length > 0) {
        for (let notesQ1 = 0; notesQ1 < teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1.length; notesQ1++) {
          newRowsHWQ1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1[notesQ1].notes} / ${teacherPanel?.studentsMark[studentsQ1].homeworkMarks.homeworkPanelQ1[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsQ2 = 0; studentsQ2 < teacherPanel?.studentsMark.length; studentsQ2++) {
      if (teacherPanel?.studentsMark[studentsQ2].academicPanelQ2?.length > 0) {
        for (let notesQ2 = 0; notesQ2 < teacherPanel?.studentsMark[studentsQ2].academicPanelQ2.length; notesQ2++) {
          newRowsQ2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsQ2].academicPanelQ2[notesQ2].notes} / ${teacherPanel?.studentsMark[studentsQ2].academicPanelQ2[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsQ2 = 0; studentsQ2 < teacherPanel?.studentsMark.length; studentsQ2++) {
      if (teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2?.length > 0) {
        for (let notesQ2 = 0; notesQ2 < teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2.length; notesQ2++) {
          newRowsHWQ2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2[notesQ2].notes} / ${teacherPanel?.studentsMark[studentsQ2].homeworkMarks.homeworkPanelQ2[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsQ3 = 0; studentsQ3 < teacherPanel?.studentsMark.length; studentsQ3++) {
      if (teacherPanel?.studentsMark[studentsQ3].academicPanelQ3?.length > 0) {
        for (let notesQ3 = 0; notesQ3 < teacherPanel?.studentsMark[studentsQ3].academicPanelQ3.length; notesQ3++) {
          newRowsQ3.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsQ3].academicPanelQ3[notesQ3].notes} / ${teacherPanel?.studentsMark[studentsQ3].academicPanelQ3[notesQ3].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsQ3 = 0; studentsQ3 < teacherPanel?.studentsMark.length; studentsQ3++) {
      if (teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3?.length > 0) {
        for (let notesQ3 = 0; notesQ3 < teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3.length; notesQ3++) {
          newRowsHWQ3.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3[notesQ3].notes} / ${teacherPanel?.studentsMark[studentsQ3].homeworkMarks.homeworkPanelQ3[notesQ3].marks}`,
            },
          });
        }
        break;
      }
    }
    const aMarksQ1 = csvDataMarks1.slice(1);
    const aMarksQ2 = csvDataMarks2.slice(1);
    const aMarksQ3 = csvDataMarks3.slice(1);
    const aSquemaQ1 = csvDataMarks1[0];
    const aSquemaQ2 = csvDataMarks2[0];
    const aSquemaQ3 = csvDataMarks3[0];
    const aMarksHWQ1 = csvDataHWMarks1.slice(1);
    const aMarksHWQ2 = csvDataHWMarks2.slice(1);
    const aMarksHWQ3 = csvDataHWMarks3.slice(1);
    const aSquemaHWQ1 = csvDataHWMarks1[0];
    const aSquemaHWQ2 = csvDataHWMarks2[0];
    const aSquemaHWQ3 = csvDataHWMarks3[0];
    for (let indexQ1 = 0; indexQ1 < aMarksQ1.length; indexQ1++) {
      const newValuesQ1 = {
        values: [],
      };
      let indexAlphabet = 2;
      for (const pQ1 in aSquemaQ1) {
        if (pQ1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pQ1 in aMarksQ1[indexQ1]) {
        if (pQ1 === 'studentName' || aMarksQ1[indexQ1][pQ1] === '') {
          if (pQ1 === 'studentName') {
            newValuesQ1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQ1[indexQ1][pQ1],
              },
            });
          } else {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: aMarksQ1[indexQ1][pQ1],
              },
            });
          }
        } else if (pQ1 === 'isAbsent') {
          newValuesQ1.values.push({
            userEnteredValue: {
              boolValue: aMarksQ1[indexQ1][pQ1],
            },
          });
        } else if (pQ1.substring(0, 4) === 'mark' && aMarksQ1[indexQ1][pQ1] !== '') {
          newValuesQ1.values.push({
            userEnteredValue: {
              numberValue: aMarksQ1[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'totalMarks') {
          newValuesQ1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(D${aMarksQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksQ1[indexQ1].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelQ1_maxMarks}),SUM(D${aMarksQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksQ1[indexQ1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelQ1_maxMarks}")`,
            },
          });
        }
      }
      newRowsQ1.updateCells.rows.push(newValuesQ1);
    }
    for (let indexQ1 = 0; indexQ1 < aMarksHWQ1.length; indexQ1++) {
      const newValuesQ1 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pQ1 in aSquemaHWQ1) {
        if (pQ1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pQ1 in aMarksHWQ1[indexQ1]) {
        if (pQ1 === 'studentName' || aMarksHWQ1[indexQ1][pQ1] === '') {
          if (pQ1 === 'studentName') {
            newValuesQ1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksHWQ1[indexQ1][pQ1],
              },
            });
          } else {
            newValuesQ1.values.push({
              userEnteredValue: {
                stringValue: aMarksHWQ1[indexQ1][pQ1],
              },
            });
          }
        } else if (pQ1.substring(0, 4) === 'mark' && aMarksHWQ1[indexQ1][pQ1] !== '') {
          newValuesQ1.values.push({
            userEnteredValue: {
              numberValue: aMarksHWQ1[indexQ1][pQ1],
            },
          });
        } else if (pQ1 === 'totalMarks') {
          newValuesQ1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksHWQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksHWQ1[indexQ1].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ1_maxMarks}),SUM(C${aMarksHWQ1[indexQ1].fieldNumber}:${letterAlphabet}${aMarksHWQ1[indexQ1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ1_maxMarks}")`,
            },
          });
        }
      }
      newRowsHWQ1.updateCells.rows.push(newValuesQ1);
    }
    for (let indexQ2 = 0; indexQ2 < aMarksQ2.length; indexQ2++) {
      const newValuesQ2 = {
        values: [],
      };
      let indexAlphabet = 2;
      for (const pQ2 in aSquemaQ2) {
        if (pQ2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pQ2 in aMarksQ2[indexQ2]) {
        if (pQ2 === 'studentName' || aMarksQ2[indexQ2][pQ2] === '') {
          if (pQ2 === 'studentName') {
            newValuesQ2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQ2[indexQ2][pQ2],
              },
            });
          } else {
            newValuesQ2.values.push({
              userEnteredValue: {
                stringValue: aMarksQ2[indexQ2][pQ2],
              },
            });
          }
        } else if (pQ2 === 'isAbsent') {
          newValuesQ2.values.push({
            userEnteredValue: {
              boolValue: aMarksQ2[indexQ2][pQ2],
            },
          });
        } else if (pQ2.substring(0, 4) === 'mark' && aMarksQ2[indexQ2][pQ2] !== '') {
          newValuesQ2.values.push({
            userEnteredValue: {
              numberValue: aMarksQ2[indexQ2][pQ2],
            },
          });
        } else if (pQ2 === 'totalMarks') {
          newValuesQ2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(D${aMarksQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksQ2[indexQ2].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelQ2_maxMarks}),SUM(D${aMarksQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksQ2[indexQ2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelQ2_maxMarks}")`,
            },
          });
        }
      }
      newRowsQ2.updateCells.rows.push(newValuesQ2);
    }
    for (let indexQ2 = 0; indexQ2 < aMarksHWQ2.length; indexQ2++) {
      const newValuesQ2 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pQ2 in aSquemaHWQ2) {
        if (pQ2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pQ2 in aMarksHWQ2[indexQ2]) {
        if (pQ2 === 'studentName' || aMarksHWQ2[indexQ2][pQ2] === '') {
          if (pQ2 === 'studentName') {
            newValuesQ2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksHWQ2[indexQ2][pQ2],
              },
            });
          } else {
            newValuesQ2.values.push({
              userEnteredValue: {
                stringValue: aMarksHWQ2[indexQ2][pQ2],
              },
            });
          }
        } else if (pQ2.substring(0, 4) === 'mark' && aMarksHWQ2[indexQ2][pQ2] !== '') {
          newValuesQ2.values.push({
            userEnteredValue: {
              numberValue: aMarksHWQ2[indexQ2][pQ2],
            },
          });
        } else if (pQ2 === 'totalMarks') {
          newValuesQ2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksHWQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksHWQ2[indexQ2].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ2_maxMarks}),SUM(C${aMarksHWQ2[indexQ2].fieldNumber}:${letterAlphabet}${aMarksHWQ2[indexQ2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ2_maxMarks}")`,
            },
          });
        }
      }
      newRowsHWQ2.updateCells.rows.push(newValuesQ2);
    }
    for (let indexQ3 = 0; indexQ3 < aMarksQ3.length; indexQ3++) {
      const newValuesQ3 = {
        values: [],
      };
      let indexAlphabet = 2;
      for (const pQ3 in aSquemaQ3) {
        if (pQ3.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pQ3 in aMarksQ3[indexQ3]) {
        if (pQ3 === 'studentName' || aMarksQ3[indexQ3][pQ3] === '') {
          if (pQ3 === 'studentName') {
            newValuesQ3.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQ3[indexQ3][pQ3],
              },
            });
          } else {
            newValuesQ3.values.push({
              userEnteredValue: {
                stringValue: aMarksQ3[indexQ3][pQ3],
              },
            });
          }
        } else if (pQ3 === 'isAbsent') {
          newValuesQ3.values.push({
            userEnteredValue: {
              boolValue: aMarksQ3[indexQ3][pQ3],
            },
          });
        } else if (pQ3.substring(0, 4) === 'mark' && aMarksQ3[indexQ3][pQ3] !== '') {
          newValuesQ3.values.push({
            userEnteredValue: {
              numberValue: aMarksQ3[indexQ3][pQ3],
            },
          });
        } else if (pQ3 === 'totalMarks') {
          newValuesQ3.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(D${aMarksQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksQ3[indexQ3].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelQ3_maxMarks}),SUM(D${aMarksQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksQ3[indexQ3].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelQ3_maxMarks}")`,
            },
          });
        }
      }
      newRowsQ3.updateCells.rows.push(newValuesQ3);
    }
    for (let indexQ3 = 0; indexQ3 < aMarksHWQ3.length; indexQ3++) {
      const newValuesQ3 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pQ3 in aSquemaHWQ3) {
        if (pQ3.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pQ3 in aMarksHWQ3[indexQ3]) {
        if (pQ3 === 'studentName' || aMarksHWQ3[indexQ3][pQ3] === '') {
          if (pQ3 === 'studentName') {
            newValuesQ3.values.unshift({
              userEnteredValue: {
                stringValue: aMarksHWQ3[indexQ3][pQ3],
              },
            });
          } else {
            newValuesQ3.values.push({
              userEnteredValue: {
                stringValue: aMarksHWQ3[indexQ3][pQ3],
              },
            });
          }
        } else if (pQ3.substring(0, 4) === 'mark' && aMarksHWQ3[indexQ3][pQ3] !== '') {
          newValuesQ3.values.push({
            userEnteredValue: {
              numberValue: aMarksHWQ3[indexQ3][pQ3],
            },
          });
        } else if (pQ3 === 'totalMarks') {
          newValuesQ3.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksHWQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksHWQ3[indexQ3].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ3_maxMarks}),SUM(C${aMarksHWQ3[indexQ3].fieldNumber}:${letterAlphabet}${aMarksHWQ3[indexQ3].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelQ3_maxMarks}")`,
            },
          });
        }
      }
      newRowsHWQ3.updateCells.rows.push(newValuesQ3);
    }
    if (newRowsQ1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 1,
                endRowIndex: newRowsQ1.updateCells.rows.length,
                startColumnIndex: 2,
                endColumnIndex: 3,
              },
              fields: 'dataValidation',
            },
          },
          newRowsQ1,
        ],
      });
    }
    if (newRowsHWQ1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsHWQ1,
        ],
      });
    }
    if (newRowsQ2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 1,
                endRowIndex: newRowsQ2.updateCells.rows.length,
                startColumnIndex: 2,
                endColumnIndex: 3,
              },
              fields: 'dataValidation',
            },
          },
          newRowsQ2,
        ],
      });
    }
    if (newRowsHWQ2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsHWQ2,
        ],
      });
    }
    if (newRowsQ3.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 1,
                endRowIndex: newRowsQ3.updateCells.rows.length,
                startColumnIndex: 2,
                endColumnIndex: 3,
              },
              fields: 'dataValidation',
            },
          },
          newRowsQ3,
        ],
      });
    }
    if (newRowsHWQ3.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsHWQ3,
        ],
      });
    }
    if (newRowsQ1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsHWQ1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsQ2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsHWQ2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsQ3.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsHWQ3.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    const payloadCreateSheet = {
      properties: {
        title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
      },
      sheets: [
        {
          properties: {
            title: 'Marks_Q1_Exam_Marks',
          },
        },
        {
          properties: {
            title: 'Marks_Q1_Homework_Marks',
          },
        },
        {
          properties: {
            title: 'Marks_Q2_Exam_Marks',
          },
        },
        {
          properties: {
            title: 'Marks_Q2_Homework_Marks',
          },
        },
        {
          properties: {
            title: 'Marks_Q3_Exam_Marks',
          },
        },
        {
          properties: {
            title: 'Marks_Q3_Homework_Marks',
          },
        },
      ],
    };
    setLoading(true);
    deleteGoogleSheet(idSheet);
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const uploader = new MediaUploader({
      token: baseUrl?.access_token,
      filename: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
      contentType: 'application/vnd.google-apps.spreadsheet',
      metadata: payloadCreateSheet,
      sheetMood: 'New',
      file: payloadPutSheet,
      sheetId: idSheet,
      onComplete: async () => {
        await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('export'), setOpenDialogErrorImport, setDialogErrorImportMessage));
        setLoading(false);
        setOpenSheet(true);
        const payload = {
          academicYear: isselectedYear,
          locationId: isSelectedlocation,
          courseId: isSelectedCourseId,
        };
        dispatch(getMarksDetails(payload, setOpenDialogErrorImport, setDialogErrorImportMessage));
        getGoogleSheet((i) => {
          const fileData = JSON.parse(i).files;
          if (fileData !== undefined) {
            const sNameSheet = fileData.filter(
              (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
            );
            if (sNameSheet.length !== 0) {
              setNameSheet(sNameSheet[0].name);
              setIdSheet(sNameSheet[0].id);
            } else {
              setNameSheet('Sheet has not been exported');
              setIdSheet();
            }
          } else {
            setShowTokenAdvice(true);
            setLoading(false);
          }
        });
      },
      onError: () => {
        setShowTokenAdvice(true);
        setLoading(false);
      },
    });
    uploader.upload();
  };

  const sendSemesterSheet = () => {
    if (idSheet === undefined) {
      const payloadPutSheet = [];
          const newRowsS1 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsHWS1 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsAssignmentS1 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsQuiz1S1 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsQuiz2S1 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsCoCurricularS1 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsS2 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsHWS2 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsAssignmentS2 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsQuiz1S2 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsQuiz2S2 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsCoCurricularS2 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          const newRowsS3 = {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          };
          for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
            if (teacherPanel?.studentsMark[studentsS1].academicPanelS1?.length > 0) {
              for (let notesS1 = 0; notesS1 < teacherPanel?.studentsMark[studentsS1].academicPanelS1.length; notesS1++) {
                newRowsS1.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${teacherPanel?.studentsMark[studentsS1].academicPanelS1[notesS1].notes} / ${teacherPanel?.studentsMark[studentsS1].academicPanelS1[notesS1].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
            let panel = teacherPanel?.studentsMark[studentsS1].homeworkMarks.homeworkPanelS1;
            if (systemBased === 'Quarter') {
              panel= teacherPanel?.studentsMark[studentsS1].homeworkMarks.homeworkPanelQ1;
            }
            if (panel?.length > 0) {
              for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
                newRowsHWS1.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
            let panel = teacherPanel?.studentsMark[studentsS1].assignmentMarks.assignmentPanelS1;
            if (panel?.length > 0) {
              for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
                newRowsAssignmentS1.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
            let panel = teacherPanel?.studentsMark[studentsS1].quizMarks.quiz1PanelS1;
            if (panel?.length > 0) {
              for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
                newRowsQuiz1S1.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
            let panel = teacherPanel?.studentsMark[studentsS1].quizMarks.quiz2PanelS1;
            if (panel?.length > 0 && isselectedYear === '2023-2024') {
              for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
                newRowsQuiz2S1.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
            let panel = teacherPanel?.studentsMark[studentsS1].studentAttendenceActivitiesMarks.AttendenceActivitiesS1;
            if (panel?.length > 0) {
              for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
                newRowsCoCurricularS1.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
            if (teacherPanel?.studentsMark[studentsS2].academicPanelS2?.length > 0) {
              for (let notesS2 = 0; notesS2 < teacherPanel?.studentsMark[studentsS2].academicPanelS2.length; notesS2++) {
                newRowsS2.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${teacherPanel?.studentsMark[studentsS2].academicPanelS2[notesS2].notes} / ${teacherPanel?.studentsMark[studentsS2].academicPanelS2[notesS2].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
            let panel = teacherPanel?.studentsMark[studentsS2].homeworkMarks.homeworkPanelS2;
            if (systemBased === 'Quarter') {
              panel= teacherPanel?.studentsMark[studentsS2].homeworkMarks.homeworkPanelQ2;
            }
            if (panel?.length > 0) {
              for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
                newRowsHWS2.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
            let panel = teacherPanel?.studentsMark[studentsS2].assignmentMarks.assignmentPanelS2;
            if (panel?.length > 0) {
              for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
                newRowsAssignmentS2.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
            let panel = teacherPanel?.studentsMark[studentsS2].quizMarks.quiz1PanelS2;
            if (panel?.length > 0) {
              for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
                newRowsQuiz1S2.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
            let panel = teacherPanel?.studentsMark[studentsS2].quizMarks.quiz2PanelS2;
            if (panel?.length > 0 && isselectedYear === '2023-2024') {
              for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
                newRowsQuiz2S2.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
            let panel = teacherPanel?.studentsMark[studentsS2].studentAttendenceActivitiesMarks.AttendenceActivitiesS2;
            if (panel?.length > 0) {
              for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
                newRowsCoCurricularS2.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
                  },
                });
              }
              break;
            }
          }
          for (let studentsS3 = 0; studentsS3 < teacherPanel?.studentsMark.length; studentsS3++) {
            if (teacherPanel?.studentsMark[studentsS3].academicPanelS3?.length > 0 && showS3Marks) {
              for (let notesS3 = 0; notesS3 < teacherPanel?.studentsMark[studentsS3].academicPanelS3.length; notesS3++) {
                newRowsS3.updateCells.rows[0].values.push({
                  userEnteredValue: {
                    stringValue: `${teacherPanel?.studentsMark[studentsS3].academicPanelS3[notesS3].notes} / ${teacherPanel?.studentsMark[studentsS3].academicPanelS3[notesS3].marks}`,
                  },
                });
              }
              break;
            }
          }
          const aMarksS1 = csvDataMarks1.slice(1);
          const aMarksS2 = csvDataMarks2.slice(1);
          const aMarksS3 = showS3Marks ? csvDataMarks3.slice(1) : [];
          const aSquemaS1 = csvDataMarks1[0];
          const aSquemaS2 = csvDataMarks2[0];
          const aSquemaS3 = showS3Marks ? csvDataMarks3[0] : [];
          const aMarksHWS1 = csvDataHWMarks1.slice(1);
          const aMarksHWS2 = csvDataHWMarks2.slice(1);
          const aSquemaHWS1 = csvDataHWMarks1[0];
          const aSquemaHWS2 = csvDataHWMarks2[0];
          const aMarksAssignmentS1 = csvDataAssignmentMarks1.slice(1);
          const aMarksAssignmentS2 = csvDataAssignmentMarks2.slice(1);
          const aSquemaAssignmentS1 = csvDataAssignmentMarks1[0];
          const aSquemaAssignmentS2 = csvDataAssignmentMarks2[0];
          const aMarksQuiz1S1 = csvDataQuiz1Marks1.slice(1);
          const aMarksQuiz1S2 = csvDataQuiz1Marks2.slice(1);
          const aSquemaQuiz1S1 = csvDataQuiz1Marks1[0];
          const aSquemaQuiz1S2 = csvDataQuiz1Marks2[0];
          const aMarksQuiz2S1 = csvDataQuiz2Marks1.slice(1);
          const aMarksQuiz2S2 = csvDataQuiz2Marks2.slice(1);
          const aSquemaQuiz2S1 = csvDataQuiz2Marks1[0];
          const aSquemaQuiz2S2 = csvDataQuiz2Marks2[0];
          const aMarksCoCurricularS1 = csvDataCoCurricularMarks1.slice(1);
          const aMarksCoCurricularS2 = csvDataCoCurricularMarks2.slice(1);
          const aSquemaCoCurricularS1 = csvDataCoCurricularMarks1[0];
          const aSquemaCoCurricularS2 = csvDataCoCurricularMarks2[0];
      
          for (let indexS1 = 0; indexS1 < aMarksS1.length; indexS1++) {
            const newValuesS1 = {
              values: [],
            };
            let indexAlphabet = 2;
            for (const pS1 in aSquemaS1) {
              if (pS1.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS1 in aMarksS1[indexS1]) {
              if (pS1 === 'studentName' || aMarksS1[indexS1][pS1] === '') {
                if (pS1 === 'studentName') {
                  newValuesS1.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksS1[indexS1][pS1],
                    },
                  });
                } else {
                  newValuesS1.values.push({
                    userEnteredValue: {
                      stringValue: aMarksS1[indexS1][pS1],
                    },
                  });
                }
              } else if (pS1 === 'isAbsent') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    boolValue: aMarksS1[indexS1][pS1],
                  },
                });
              } else if (pS1.substring(0, 4) === 'mark' && aMarksS1[indexS1][pS1] !== '') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    numberValue: aMarksS1[indexS1][pS1],
                  },
                });
              } else if (pS1 === 'totalMarks') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(D${aMarksS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelS1_maxMarks}),SUM(D${aMarksS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelS1_maxMarks}")`,
                  },
                });
              }
            }
            newRowsS1.updateCells.rows.push(newValuesS1);
          }
          for (let indexS1 = 0; indexS1 < aMarksHWS1.length; indexS1++) {
            const newValuesS1 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS1 in aSquemaHWS1) {
              if (pS1.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS1 in aMarksHWS1[indexS1]) {
              if (pS1 === 'studentName' || aMarksHWS1[indexS1][pS1] === '') {
                if (pS1 === 'studentName') {
                  newValuesS1.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksHWS1[indexS1][pS1],
                    },
                  });
                } else {
                  newValuesS1.values.push({
                    userEnteredValue: {
                      stringValue: aMarksHWS1[indexS1][pS1],
                    },
                  });
                }
              } else if (pS1.substring(0, 4) === 'mark' && aMarksHWS1[indexS1][pS1] !== '') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    numberValue: aMarksHWS1[indexS1][pS1],
                  },
                });
              } else if (pS1 === 'totalMarks') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksHWS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksHWS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS1_maxMarks}),SUM(C${aMarksHWS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksHWS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS1_maxMarks}")`,
                  },
                });
              }
            }
            newRowsHWS1.updateCells.rows.push(newValuesS1);
          }
          for (let indexS1 = 0; indexS1 < aMarksAssignmentS1.length; indexS1++) {
            const newValuesS1 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS1 in aSquemaAssignmentS1) {
              if (pS1.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS1 in aMarksAssignmentS1[indexS1]) {
              if (pS1 === 'studentName' || aMarksAssignmentS1[indexS1][pS1] === '') {
                if (pS1 === 'studentName') {
                  newValuesS1.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksAssignmentS1[indexS1][pS1],
                    },
                  });
                } else {
                  newValuesS1.values.push({
                    userEnteredValue: {
                      stringValue: aMarksAssignmentS1[indexS1][pS1],
                    },
                  });
                }
              } else if (pS1.substring(0, 4) === 'mark' && aMarksAssignmentS1[indexS1][pS1] !== '') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    numberValue: aMarksAssignmentS1[indexS1][pS1],
                  },
                });
              } else if (pS1 === 'totalMarks') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksAssignmentS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksAssignmentS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS1_maxMarks}),SUM(C${aMarksAssignmentS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksAssignmentS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS1_maxMarks}")`,
                  },
                });
              }
            }
            newRowsAssignmentS1.updateCells.rows.push(newValuesS1);
          }
          for (let indexS1 = 0; indexS1 < aMarksQuiz1S1.length; indexS1++) {
            const newValuesS1 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS1 in aSquemaQuiz1S1) {
              if (pS1.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS1 in aMarksQuiz1S1[indexS1]) {
              if (pS1 === 'studentName' || aMarksQuiz1S1[indexS1][pS1] === '') {
                if (pS1 === 'studentName') {
                  newValuesS1.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksQuiz1S1[indexS1][pS1],
                    },
                  });
                } else {
                  newValuesS1.values.push({
                    userEnteredValue: {
                      stringValue: aMarksQuiz1S1[indexS1][pS1],
                    },
                  });
                }
              } else if (pS1.substring(0, 4) === 'mark' && aMarksQuiz1S1[indexS1][pS1] !== '') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    numberValue: aMarksQuiz1S1[indexS1][pS1],
                  },
                });
              } else if (pS1 === 'totalMarks') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksQuiz1S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz1S1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS1_maxMarks}),SUM(C${aMarksQuiz1S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz1S1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS1_maxMarks}")`,
                  },
                });
              }
            }
            newRowsQuiz1S1.updateCells.rows.push(newValuesS1);
          }
          for (let indexS1 = 0; indexS1 < aMarksQuiz2S1.length; indexS1++) {
            const newValuesS1 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS1 in aSquemaQuiz2S1) {
              if (pS1.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS1 in aMarksQuiz2S1[indexS1]) {
              if (pS1 === 'studentName' || aMarksQuiz2S1[indexS1][pS1] === '') {
                if (pS1 === 'studentName') {
                  newValuesS1.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksQuiz2S1[indexS1][pS1],
                    },
                  });
                } else {
                  newValuesS1.values.push({
                    userEnteredValue: {
                      stringValue: aMarksQuiz2S1[indexS1][pS1],
                    },
                  });
                }
              } else if (pS1.substring(0, 4) === 'mark' && aMarksQuiz2S1[indexS1][pS1] !== '') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    numberValue: aMarksQuiz2S1[indexS1][pS1],
                  },
                });
              } else if (pS1 === 'totalMarks') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksQuiz2S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz2S1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS1_maxMarks}),SUM(C${aMarksQuiz2S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz2S1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS1_maxMarks}")`,
                  },
                });
              }
            }
            newRowsQuiz2S1.updateCells.rows.push(newValuesS1);
          }
          for (let indexS1 = 0; indexS1 < aMarksCoCurricularS1.length; indexS1++) {
            const newValuesS1 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS1 in aSquemaCoCurricularS1) {
              if (pS1.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS1 in aMarksCoCurricularS1[indexS1]) {
              if (pS1 === 'studentName' || aMarksCoCurricularS1[indexS1][pS1] === '') {
                if (pS1 === 'studentName') {
                  newValuesS1.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksCoCurricularS1[indexS1][pS1],
                    },
                  });
                } else {
                  newValuesS1.values.push({
                    userEnteredValue: {
                      stringValue: aMarksCoCurricularS1[indexS1][pS1],
                    },
                  });
                }
              } else if (pS1.substring(0, 4) === 'mark' && aMarksCoCurricularS1[indexS1][pS1] !== '') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    numberValue: aMarksCoCurricularS1[indexS1][pS1],
                  },
                });
              } else if (pS1 === 'totalMarks') {
                newValuesS1.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksCoCurricularS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS1_maxMarks}),SUM(C${aMarksCoCurricularS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS1_maxMarks}")`,
                  },
                });
              }
            }
            newRowsCoCurricularS1.updateCells.rows.push(newValuesS1);
          }
          for (let indexS2 = 0; indexS2 < aMarksS2.length; indexS2++) {
            const newValuesS2 = {
              values: [],
            };
            let indexAlphabet = 2;
            for (const pS2 in aSquemaS2) {
              if (pS2.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS2 in aMarksS2[indexS2]) {
              if (pS2 === 'studentName' || aMarksS2[indexS2][pS2] === '') {
                if (pS2 === 'studentName') {
                  newValuesS2.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksS2[indexS2][pS2],
                    },
                  });
                } else {
                  newValuesS2.values.push({
                    userEnteredValue: {
                      stringValue: aMarksS2[indexS2][pS2],
                    },
                  });
                }
              } else if (pS2 === 'isAbsent') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    boolValue: aMarksS2[indexS2][pS2],
                  },
                });
              } else if (pS2.substring(0, 4) === 'mark' && aMarksS2[indexS2][pS2] !== '') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    numberValue: aMarksS2[indexS2][pS2],
                  },
                });
              } else if (pS2 === 'totalMarks') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(D${aMarksS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelS2_maxMarks}),SUM(D${aMarksS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelS2_maxMarks}")`,
                  },
                });
              }
            }
            newRowsS2.updateCells.rows.push(newValuesS2);
          }
          for (let indexS2 = 0; indexS2 < aMarksHWS2.length; indexS2++) {
            const newValuesS2 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS2 in aSquemaHWS2) {
              if (pS2.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS2 in aMarksHWS2[indexS2]) {
              if (pS2 === 'studentName' || aMarksHWS2[indexS2][pS2] === '') {
                if (pS2 === 'studentName') {
                  newValuesS2.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksHWS2[indexS2][pS2],
                    },
                  });
                } else {
                  newValuesS2.values.push({
                    userEnteredValue: {
                      stringValue: aMarksHWS2[indexS2][pS2],
                    },
                  });
                }
              } else if (pS2.substring(0, 4) === 'mark' && aMarksHWS2[indexS2][pS2] !== '') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    numberValue: aMarksHWS2[indexS2][pS2],
                  },
                });
              } else if (pS2 === 'totalMarks') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksHWS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksHWS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS2_maxMarks}),SUM(C${aMarksHWS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksHWS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS2_maxMarks}")`,
                  },
                });
              }
            }
            newRowsHWS2.updateCells.rows.push(newValuesS2);
          }
          for (let indexS2 = 0; indexS2 < aMarksAssignmentS2.length; indexS2++) {
            const newValuesS2 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS2 in aSquemaAssignmentS2) {
              if (pS2.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS2 in aMarksAssignmentS2[indexS2]) {
              if (pS2 === 'studentName' || aMarksAssignmentS2[indexS2][pS2] === '') {
                if (pS2 === 'studentName') {
                  newValuesS2.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksAssignmentS2[indexS2][pS2],
                    },
                  });
                } else {
                  newValuesS2.values.push({
                    userEnteredValue: {
                      stringValue: aMarksAssignmentS2[indexS2][pS2],
                    },
                  });
                }
              } else if (pS2.substring(0, 4) === 'mark' && aMarksAssignmentS2[indexS2][pS2] !== '') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    numberValue: aMarksAssignmentS2[indexS2][pS2],
                  },
                });
              } else if (pS2 === 'totalMarks') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksAssignmentS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksAssignmentS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS2_maxMarks}),SUM(C${aMarksAssignmentS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksAssignmentS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS2_maxMarks}")`,
                  },
                });
              }
            }
            newRowsAssignmentS2.updateCells.rows.push(newValuesS2);
          }
          for (let indexS2 = 0; indexS2 < aMarksQuiz1S2.length; indexS2++) {
            const newValuesS2 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS2 in aSquemaQuiz1S2) {
              if (pS2.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS2 in aMarksQuiz1S2[indexS2]) {
              if (pS2 === 'studentName' || aMarksQuiz1S2[indexS2][pS2] === '') {
                if (pS2 === 'studentName') {
                  newValuesS2.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksQuiz1S2[indexS2][pS2],
                    },
                  });
                } else {
                  newValuesS2.values.push({
                    userEnteredValue: {
                      stringValue: aMarksQuiz1S2[indexS2][pS2],
                    },
                  });
                }
              } else if (pS2.substring(0, 4) === 'mark' && aMarksQuiz1S2[indexS2][pS2] !== '') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    numberValue: aMarksQuiz1S2[indexS2][pS2],
                  },
                });
              } else if (pS2 === 'totalMarks') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksQuiz1S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz1S2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS2_maxMarks}),SUM(C${aMarksQuiz1S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz1S2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS2_maxMarks}")`,
                  },
                });
              }
            }
            newRowsQuiz1S2.updateCells.rows.push(newValuesS2);
          }
          for (let indexS2 = 0; indexS2 < aMarksQuiz2S2.length; indexS2++) {
            const newValuesS2 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS2 in aSquemaQuiz2S2) {
              if (pS2.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS2 in aMarksQuiz2S2[indexS2]) {
              if (pS2 === 'studentName' || aMarksQuiz2S2[indexS2][pS2] === '') {
                if (pS2 === 'studentName') {
                  newValuesS2.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksQuiz2S2[indexS2][pS2],
                    },
                  });
                } else {
                  newValuesS2.values.push({
                    userEnteredValue: {
                      stringValue: aMarksQuiz2S2[indexS2][pS2],
                    },
                  });
                }
              } else if (pS2.substring(0, 4) === 'mark' && aMarksQuiz2S2[indexS2][pS2] !== '') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    numberValue: aMarksQuiz2S2[indexS2][pS2],
                  },
                });
              } else if (pS2 === 'totalMarks') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksQuiz2S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz2S2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS2_maxMarks}),SUM(C${aMarksQuiz2S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz2S2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS2_maxMarks}")`,
                  },
                });
              }
            }
            newRowsQuiz2S2.updateCells.rows.push(newValuesS2);
          }
          for (let indexS2 = 0; indexS2 < aMarksCoCurricularS2.length; indexS2++) {
            const newValuesS2 = {
              values: [],
            };
            let indexAlphabet = 1;
            for (const pS2 in aSquemaCoCurricularS2) {
              if (pS2.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS2 in aMarksCoCurricularS2[indexS2]) {
              if (pS2 === 'studentName' || aMarksCoCurricularS2[indexS2][pS2] === '') {
                if (pS2 === 'studentName') {
                  newValuesS2.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksCoCurricularS2[indexS2][pS2],
                    },
                  });
                } else {
                  newValuesS2.values.push({
                    userEnteredValue: {
                      stringValue: aMarksCoCurricularS2[indexS2][pS2],
                    },
                  });
                }
              } else if (pS2.substring(0, 4) === 'mark' && aMarksCoCurricularS2[indexS2][pS2] !== '') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    numberValue: aMarksCoCurricularS2[indexS2][pS2],
                  },
                });
              } else if (pS2 === 'totalMarks') {
                newValuesS2.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(C${aMarksCoCurricularS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS2_maxMarks}),SUM(C${aMarksCoCurricularS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS2_maxMarks}")`,
                  },
                });
              }
            }
            newRowsCoCurricularS2.updateCells.rows.push(newValuesS2);
          }
          for (let indexS3 = 0; indexS3 < aMarksS3.length; indexS3++) {
            const newValuesS3 = {
              values: [],
            };
            let indexAlphabet = 2;
            for (const pS3 in aSquemaS3) {
              if (pS3.substring(0, 4) === 'mark') {
                indexAlphabet += 1;
              }
            }
            const letterAlphabet = alphabet[indexAlphabet];
            for (const pS3 in aMarksS3[indexS3]) {
              if (pS3 === 'studentName' || aMarksS3[indexS3][pS3] === '') {
                if (pS3 === 'studentName') {
                  newValuesS3.values.unshift({
                    userEnteredValue: {
                      stringValue: aMarksS3[indexS3][pS3],
                    },
                  });
                } else {
                  newValuesS3.values.push({
                    userEnteredValue: {
                      stringValue: aMarksS3[indexS3][pS3],
                    },
                  });
                }
              } else if (pS3 === 'isAbsent') {
                newValuesS3.values.push({
                  userEnteredValue: {
                    boolValue: aMarksS3[indexS3][pS3],
                  },
                });
              } else if (pS3.substring(0, 4) === 'mark' && aMarksS3[indexS3][pS3] !== '') {
                newValuesS3.values.push({
                  userEnteredValue: {
                    numberValue: aMarksS3[indexS3][pS3],
                  },
                });
              } else if (pS3 === 'totalMarks') {
                newValuesS3.values.push({
                  userEnteredValue: {
                    formulaValue: `=IF(AND(SUM(D${aMarksS3[indexS3].fieldNumber}:${letterAlphabet}${aMarksS3[indexS3].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelS3_maxMarks}),SUM(D${aMarksS3[indexS3].fieldNumber}:${letterAlphabet}${aMarksS3[indexS3].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelS3_maxMarks}")`,
                  },
                });
              }
            }
            if (showS3Marks) {
              newRowsS3.updateCells.rows.push(newValuesS3);
            }
          }
          if (newRowsS1.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                {
                  repeatCell: {
                    cell: {
                      dataValidation: {
                        condition: {
                          type: 'BOOLEAN',
                        },
                      },
                    },
                    range: {
                      sheetId: '',
                      startRowIndex: 1,
                      endRowIndex: newRowsS1.updateCells.rows.length,
                      startColumnIndex: 2,
                      endColumnIndex: 3,
                    },
                    fields: 'dataValidation',
                  },
                },
                newRowsS1,
              ],
            });
          }
          if (newRowsHWS1.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsHWS1,
              ],
            });
          }
          if (newRowsAssignmentS1.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsAssignmentS1,
              ],
            });
          }
          if (newRowsQuiz1S1.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsQuiz1S1,
              ],
            });
          }
          if (newRowsQuiz2S1.updateCells.rows[1]?.values.length !== 0 && isselectedYear === '2023-2024') {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsQuiz2S1,
              ],
            });
          }
          if (newRowsCoCurricularS1.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsCoCurricularS1,
              ],
            });
          }
          if (newRowsS2.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                {
                  repeatCell: {
                    cell: {
                      dataValidation: {
                        condition: {
                          type: 'BOOLEAN',
                        },
                      },
                    },
                    range: {
                      sheetId: '',
                      startRowIndex: 1,
                      endRowIndex: newRowsS2.updateCells.rows.length,
                      startColumnIndex: 2,
                      endColumnIndex: 3,
                    },
                    fields: 'dataValidation',
                  },
                },
                newRowsS2,
              ],
            });
          }
          if (newRowsHWS2.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsHWS2,
              ],
            });
          }
          if (newRowsAssignmentS2.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsAssignmentS2,
              ],
            });
          }
          if (newRowsQuiz1S2.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsQuiz1S2,
              ],
            });
          }
          if (newRowsQuiz2S2.updateCells.rows[1]?.values.length !== 0 && isselectedYear === '2023-2024') {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsQuiz2S2,
              ],
            });
          }
          if (newRowsCoCurricularS2.updateCells.rows[1]?.values.length !== 0) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                newRowsCoCurricularS2,
              ],
            });
          }
          if (newRowsS3.updateCells.rows[1]?.values.length !== 0 && showS3Marks) {
            payloadPutSheet.push({
              requests: [
                {
                  appendDimension: {
                    sheetId: '',
                    dimension: 'COLUMNS',
                    length: 50,
                  },
                },
                {
                  repeatCell: {
                    cell: {
                      dataValidation: {
                        condition: {
                          type: 'BOOLEAN',
                        },
                      },
                    },
                    range: {
                      sheetId: '',
                      startRowIndex: 1,
                      endRowIndex: newRowsS3.updateCells.rows.length,
                      startColumnIndex: 2,
                      endColumnIndex: 3,
                    },
                    fields: 'dataValidation',
                  },
                },
                newRowsS3,
              ],
            });
          }
          if (newRowsS1.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Is Absent',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsHWS1.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsAssignmentS1.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsQuiz1S1.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsQuiz2S1.updateCells.rows[1]?.values.length === 0 && isselectedYear === '2023-2024') {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsCoCurricularS1.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsS2.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Is Absent',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsHWS2.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsAssignmentS2.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsQuiz1S2.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsQuiz2S2.updateCells.rows[1]?.values.length === 0 && isselectedYear === '2023-2024') {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsCoCurricularS2.updateCells.rows[1]?.values.length === 0) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          if (newRowsS3.updateCells.rows[1]?.values.length === 0 && showS3Marks) {
            payloadPutSheet.push({
              requests: [
                {
                  updateCells: {
                    rows: [
                      {
                        values: [
                          {
                            userEnteredValue: {
                              stringValue: 'Student Name',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Total Marks',
                            },
                          },
                          {
                            userEnteredValue: {
                              stringValue: 'Is Absent',
                            },
                          },
                        ],
                      },
                    ],
                    start: {
                      rowIndex: 0,
                      columnIndex: 0,
                      sheetId: '',
                    },
                    fields: 'userEnteredValue',
                  },
                },
              ],
            });
          }
          let payloadCreateSheet = {};
          if (systemBased === 'Quarter') {
            payloadCreateSheet = {
              properties: {
                title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
              },
              sheets: [
                {
                  properties: {
                    title: 'Marks_Q1_Exam_Marks',
                  },
                },
                {
                  properties: {
                    title: 'Marks_Q1_Homework_Marks',
                  },
                },
                {
                  properties: {
                    title: 'Marks_Q2_Exam_Marks',
                  },
                },
                {
                  properties: {
                    title: 'Marks_Q2_Homework_Marks',
                  },
                },
                {
                  properties: {
                    title: 'Marks_Q3_Exam_Marks',
                  },
                },
                {
                  properties: {
                    title: 'Marks_Q3_Homework_Marks',
                  },
                },
              ],
            }
          } else if (systemBased === 'Semester') {
            if (showS3 === 'PSTU' && showS3Marks) {
              payloadCreateSheet = {
                properties: {
                  title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
                },
                sheets: [
                  {
                    properties: {
                      title: 'Marks_S1_Exam_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Homework_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Assignment_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Quiz1_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Quiz2_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Co-Curricular_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Exam_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Homework_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Assignment_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Quiz3_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Quiz4_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Co-Curricular_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'PSTU_Exam_Marks',
                    },
                  },
                ],
              }
            } else if (showS3 === 'Supplementary' && showS3Marks) {
              payloadCreateSheet = {
                properties: {
                  title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
                },
                sheets: [
                  {
                    properties: {
                      title: 'Marks_S1_Exam_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Homework_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Assignment_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Quiz1_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Quiz2_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Co-Curricular_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Exam_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Homework_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Assignment_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Quiz3_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Quiz4_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Co-Curricular_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Supplementary_Exam_Marks',
                    },
                  },
                ],
              }
            } else {
              payloadCreateSheet = {
                properties: {
                  title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
                },
                sheets: [
                  {
                    properties: {
                      title: 'Marks_S1_Exam_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Homework_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Assignment_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Quiz1_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Quiz2_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S1_Co-Curricular_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Exam_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Homework_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Assignment_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Quiz3_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Quiz4_Marks',
                    },
                  },
                  {
                    properties: {
                      title: 'Marks_S2_Co-Curricular_Marks',
                    },
                  },
                ],
              }
            }
            if (isselectedYear !== '2023-2024') {
              payloadCreateSheet.sheets = payloadCreateSheet.sheets.filter(sheet => sheet.properties.title !== 'Marks_S1_Quiz2_Marks' && sheet.properties.title !== 'Marks_S2_Quiz4_Marks');
            
              let sheet = payloadCreateSheet.sheets.find(sheet => sheet.properties.title === 'Marks_S2_Quiz3_Marks');
              if (sheet) {
                sheet.properties.title = 'Marks_S2_Quiz2_Marks';
              }
            }
          }
          setLoading(true);
          const gettoken = getLocalStorage('accessToken');
          const baseUrl = JSON.parse(gettoken);
          const uploader = new MediaUploader({
            token: baseUrl?.access_token,
            filename: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
            contentType: 'application/vnd.google-apps.spreadsheet',
            metadata: payloadCreateSheet,
            sheetMood: 'New',
            file: payloadPutSheet,
            onComplete: async () => {
              await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('export'), setOpenDialogErrorImport, setDialogErrorImportMessage));
              setLoading(false);
              setOpenSheet(true);
              const payload = {
                academicYear: isselectedYear,
                locationId: isSelectedlocation,
                courseId: isSelectedCourseId,
              };
              dispatch(getMarksDetails(payload, setOpenDialogErrorImport, setDialogErrorImportMessage));
              getGoogleSheet((i) => {
                const fileData = JSON.parse(i).files;
                if (fileData !== undefined) {
                  const sNameSheet = fileData.filter(
                    (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
                  );
                  if (sNameSheet.length !== 0) {
                    setNameSheet(sNameSheet[0].name);
                    setIdSheet(sNameSheet[0].id);
                  } else {
                    setNameSheet('Sheet has not been exported');
                    setIdSheet();
                  }
                } else {
                  setShowTokenAdvice(true);
                  setLoading(false);
                }
              });
            },
            onError: () => {
              setShowTokenAdvice(true);
              setLoading(false);
            },
          });
          uploader.upload();
    } else {
      setOpenSheetExists(true);
    }
  };

  const exportSemesterSheetExists = () => {
    const payloadPutSheet = [];
    const newRowsS1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Is Absent',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsHWS1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsAssignmentS1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsQuiz1S1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsQuiz2S1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsCoCurricularS1 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsS2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Is Absent',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsHWS2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsAssignmentS2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsQuiz1S2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsQuiz2S2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsCoCurricularS2 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    const newRowsS3 = {
      updateCells: {
        rows: [
          {
            values: [
              {
                userEnteredValue: {
                  stringValue: 'Student Name',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Total Marks',
                },
              },
              {
                userEnteredValue: {
                  stringValue: 'Is Absent',
                },
              },
            ],
          },
        ],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };
    for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
      if (teacherPanel?.studentsMark[studentsS1].academicPanelS1?.length > 0) {
        for (let notesS1 = 0; notesS1 < teacherPanel?.studentsMark[studentsS1].academicPanelS1.length; notesS1++) {
          newRowsS1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsS1].academicPanelS1[notesS1].notes} / ${teacherPanel?.studentsMark[studentsS1].academicPanelS1[notesS1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
      let panel = teacherPanel?.studentsMark[studentsS1].homeworkMarks.homeworkPanelS1;
      if (systemBased === 'Quarter') {
        panel= teacherPanel?.studentsMark[studentsS1].homeworkMarks.homeworkPanelQ1;
      }
      if (panel?.length > 0) {
        for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
          newRowsHWS1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
      let panel = teacherPanel?.studentsMark[studentsS1].assignmentMarks.assignmentPanelS1;
      if (panel?.length > 0) {
        for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
          newRowsAssignmentS1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
      let panel = teacherPanel?.studentsMark[studentsS1].quizMarks.quiz1PanelS1;
      if (panel?.length > 0) {
        for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
          newRowsQuiz1S1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
      let panel = teacherPanel?.studentsMark[studentsS1].quizMarks.quiz2PanelS1;
      if (panel?.length > 0 && isselectedYear === '2023-2024') {
        for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
          newRowsQuiz2S1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS1 = 0; studentsS1 < teacherPanel?.studentsMark.length; studentsS1++) {
      let panel = teacherPanel?.studentsMark[studentsS1].studentAttendenceActivitiesMarks.AttendenceActivitiesS1;
      if (panel?.length > 0) {
        for (let notesQ1 = 0; notesQ1 < panel.length; notesQ1++) {
          newRowsCoCurricularS1.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ1].notes} / ${panel[notesQ1].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
      if (teacherPanel?.studentsMark[studentsS2].academicPanelS2?.length > 0) {
        for (let notesS2 = 0; notesS2 < teacherPanel?.studentsMark[studentsS2].academicPanelS2.length; notesS2++) {
          newRowsS2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsS2].academicPanelS2[notesS2].notes} / ${teacherPanel?.studentsMark[studentsS2].academicPanelS2[notesS2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
      let panel = teacherPanel?.studentsMark[studentsS2].homeworkMarks.homeworkPanelS2;
      if (systemBased === 'Quarter') {
        panel= teacherPanel?.studentsMark[studentsS2].homeworkMarks.homeworkPanelQ2;
      }
      if (panel?.length > 0) {
        for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
          newRowsHWS2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
      let panel = teacherPanel?.studentsMark[studentsS2].assignmentMarks.assignmentPanelS2;
      if (panel?.length > 0) {
        for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
          newRowsAssignmentS2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
      let panel = teacherPanel?.studentsMark[studentsS2].quizMarks.quiz1PanelS2;
      if (panel?.length > 0) {
        for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
          newRowsQuiz1S2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
      let panel = teacherPanel?.studentsMark[studentsS2].quizMarks.quiz2PanelS2;
      if (panel?.length > 0 && isselectedYear === '2023-2024') {
        for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
          newRowsQuiz2S2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS2 = 0; studentsS2 < teacherPanel?.studentsMark.length; studentsS2++) {
      let panel = teacherPanel?.studentsMark[studentsS2].studentAttendenceActivitiesMarks.AttendenceActivitiesS2;
      if (panel?.length > 0) {
        for (let notesQ2 = 0; notesQ2 < panel.length; notesQ2++) {
          newRowsCoCurricularS2.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${panel[notesQ2].notes} / ${panel[notesQ2].marks}`,
            },
          });
        }
        break;
      }
    }
    for (let studentsS3 = 0; studentsS3 < teacherPanel?.studentsMark.length; studentsS3++) {
      if (teacherPanel?.studentsMark[studentsS3].academicPanelS3?.length > 0 && showS3Marks) {
        for (let notesS3 = 0; notesS3 < teacherPanel?.studentsMark[studentsS3].academicPanelS3.length; notesS3++) {
          newRowsS3.updateCells.rows[0].values.push({
            userEnteredValue: {
              stringValue: `${teacherPanel?.studentsMark[studentsS3].academicPanelS3[notesS3].notes} / ${teacherPanel?.studentsMark[studentsS3].academicPanelS3[notesS3].marks}`,
            },
          });
        }
        break;
      }
    }
    const aMarksS1 = csvDataMarks1.slice(1);
    const aMarksS2 = csvDataMarks2.slice(1);
    const aMarksS3 = showS3Marks ? csvDataMarks3.slice(1) : [];
    const aSquemaS1 = csvDataMarks1[0];
    const aSquemaS2 = csvDataMarks2[0];
    const aSquemaS3 = showS3Marks ? csvDataMarks3[0] : [];
    const aMarksHWS1 = csvDataHWMarks1.slice(1);
    const aMarksHWS2 = csvDataHWMarks2.slice(1);
    const aSquemaHWS1 = csvDataHWMarks1[0];
    const aSquemaHWS2 = csvDataHWMarks2[0];
    const aMarksAssignmentS1 = csvDataAssignmentMarks1.slice(1);
    const aMarksAssignmentS2 = csvDataAssignmentMarks2.slice(1);
    const aSquemaAssignmentS1 = csvDataAssignmentMarks1[0];
    const aSquemaAssignmentS2 = csvDataAssignmentMarks2[0];
    const aMarksQuiz1S1 = csvDataQuiz1Marks1.slice(1);
    const aMarksQuiz1S2 = csvDataQuiz1Marks2.slice(1);
    const aSquemaQuiz1S1 = csvDataQuiz1Marks1[0];
    const aSquemaQuiz1S2 = csvDataQuiz1Marks2[0];
    const aMarksQuiz2S1 = csvDataQuiz2Marks1.slice(1);
    const aMarksQuiz2S2 = csvDataQuiz2Marks2.slice(1);
    const aSquemaQuiz2S1 = csvDataQuiz2Marks1[0];
    const aSquemaQuiz2S2 = csvDataQuiz2Marks2[0];
    const aMarksCoCurricularS1 = csvDataCoCurricularMarks1.slice(1);
    const aMarksCoCurricularS2 = csvDataCoCurricularMarks2.slice(1);
    const aSquemaCoCurricularS1 = csvDataCoCurricularMarks1[0];
    const aSquemaCoCurricularS2 = csvDataCoCurricularMarks2[0];

    for (let indexS1 = 0; indexS1 < aMarksS1.length; indexS1++) {
      const newValuesS1 = {
        values: [],
      };
      let indexAlphabet = 2;
      for (const pS1 in aSquemaS1) {
        if (pS1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS1 in aMarksS1[indexS1]) {
        if (pS1 === 'studentName' || aMarksS1[indexS1][pS1] === '') {
          if (pS1 === 'studentName') {
            newValuesS1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksS1[indexS1][pS1],
              },
            });
          } else {
            newValuesS1.values.push({
              userEnteredValue: {
                stringValue: aMarksS1[indexS1][pS1],
              },
            });
          }
        } else if (pS1 === 'isAbsent') {
          newValuesS1.values.push({
            userEnteredValue: {
              boolValue: aMarksS1[indexS1][pS1],
            },
          });
        } else if (pS1.substring(0, 4) === 'mark' && aMarksS1[indexS1][pS1] !== '') {
          newValuesS1.values.push({
            userEnteredValue: {
              numberValue: aMarksS1[indexS1][pS1],
            },
          });
        } else if (pS1 === 'totalMarks') {
          newValuesS1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(D${aMarksS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelS1_maxMarks}),SUM(D${aMarksS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelS1_maxMarks}")`,
            },
          });
        }
      }
      newRowsS1.updateCells.rows.push(newValuesS1);
    }
    for (let indexS1 = 0; indexS1 < aMarksHWS1.length; indexS1++) {
      const newValuesS1 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS1 in aSquemaHWS1) {
        if (pS1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS1 in aMarksHWS1[indexS1]) {
        if (pS1 === 'studentName' || aMarksHWS1[indexS1][pS1] === '') {
          if (pS1 === 'studentName') {
            newValuesS1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksHWS1[indexS1][pS1],
              },
            });
          } else {
            newValuesS1.values.push({
              userEnteredValue: {
                stringValue: aMarksHWS1[indexS1][pS1],
              },
            });
          }
        } else if (pS1.substring(0, 4) === 'mark' && aMarksHWS1[indexS1][pS1] !== '') {
          newValuesS1.values.push({
            userEnteredValue: {
              numberValue: aMarksHWS1[indexS1][pS1],
            },
          });
        } else if (pS1 === 'totalMarks') {
          newValuesS1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksHWS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksHWS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS1_maxMarks}),SUM(C${aMarksHWS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksHWS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS1_maxMarks}")`,
            },
          });
        }
      }
      newRowsHWS1.updateCells.rows.push(newValuesS1);
    }
    for (let indexS1 = 0; indexS1 < aMarksAssignmentS1.length; indexS1++) {
      const newValuesS1 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS1 in aSquemaAssignmentS1) {
        if (pS1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS1 in aMarksAssignmentS1[indexS1]) {
        if (pS1 === 'studentName' || aMarksAssignmentS1[indexS1][pS1] === '') {
          if (pS1 === 'studentName') {
            newValuesS1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksAssignmentS1[indexS1][pS1],
              },
            });
          } else {
            newValuesS1.values.push({
              userEnteredValue: {
                stringValue: aMarksAssignmentS1[indexS1][pS1],
              },
            });
          }
        } else if (pS1.substring(0, 4) === 'mark' && aMarksAssignmentS1[indexS1][pS1] !== '') {
          newValuesS1.values.push({
            userEnteredValue: {
              numberValue: aMarksAssignmentS1[indexS1][pS1],
            },
          });
        } else if (pS1 === 'totalMarks') {
          newValuesS1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksAssignmentS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksAssignmentS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS1_maxMarks}),SUM(C${aMarksAssignmentS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksAssignmentS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS1_maxMarks}")`,
            },
          });
        }
      }
      newRowsAssignmentS1.updateCells.rows.push(newValuesS1);
    }
    for (let indexS1 = 0; indexS1 < aMarksQuiz1S1.length; indexS1++) {
      const newValuesS1 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS1 in aSquemaQuiz1S1) {
        if (pS1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS1 in aMarksQuiz1S1[indexS1]) {
        if (pS1 === 'studentName' || aMarksQuiz1S1[indexS1][pS1] === '') {
          if (pS1 === 'studentName') {
            newValuesS1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQuiz1S1[indexS1][pS1],
              },
            });
          } else {
            newValuesS1.values.push({
              userEnteredValue: {
                stringValue: aMarksQuiz1S1[indexS1][pS1],
              },
            });
          }
        } else if (pS1.substring(0, 4) === 'mark' && aMarksQuiz1S1[indexS1][pS1] !== '') {
          newValuesS1.values.push({
            userEnteredValue: {
              numberValue: aMarksQuiz1S1[indexS1][pS1],
            },
          });
        } else if (pS1 === 'totalMarks') {
          newValuesS1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksQuiz1S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz1S1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS1_maxMarks}),SUM(C${aMarksQuiz1S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz1S1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS1_maxMarks}")`,
            },
          });
        }
      }
      newRowsQuiz1S1.updateCells.rows.push(newValuesS1);
    }
    for (let indexS1 = 0; indexS1 < aMarksQuiz2S1.length; indexS1++) {
      const newValuesS1 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS1 in aSquemaQuiz2S1) {
        if (pS1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS1 in aMarksQuiz2S1[indexS1]) {
        if (pS1 === 'studentName' || aMarksQuiz2S1[indexS1][pS1] === '') {
          if (pS1 === 'studentName') {
            newValuesS1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQuiz2S1[indexS1][pS1],
              },
            });
          } else {
            newValuesS1.values.push({
              userEnteredValue: {
                stringValue: aMarksQuiz2S1[indexS1][pS1],
              },
            });
          }
        } else if (pS1.substring(0, 4) === 'mark' && aMarksQuiz2S1[indexS1][pS1] !== '') {
          newValuesS1.values.push({
            userEnteredValue: {
              numberValue: aMarksQuiz2S1[indexS1][pS1],
            },
          });
        } else if (pS1 === 'totalMarks') {
          newValuesS1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksQuiz2S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz2S1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS1_maxMarks}),SUM(C${aMarksQuiz2S1[indexS1].fieldNumber}:${letterAlphabet}${aMarksQuiz2S1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS1_maxMarks}")`,
            },
          });
        }
      }
      newRowsQuiz2S1.updateCells.rows.push(newValuesS1);
    }
    for (let indexS1 = 0; indexS1 < aMarksCoCurricularS1.length; indexS1++) {
      const newValuesS1 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS1 in aSquemaCoCurricularS1) {
        if (pS1.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS1 in aMarksCoCurricularS1[indexS1]) {
        if (pS1 === 'studentName' || aMarksCoCurricularS1[indexS1][pS1] === '') {
          if (pS1 === 'studentName') {
            newValuesS1.values.unshift({
              userEnteredValue: {
                stringValue: aMarksCoCurricularS1[indexS1][pS1],
              },
            });
          } else {
            newValuesS1.values.push({
              userEnteredValue: {
                stringValue: aMarksCoCurricularS1[indexS1][pS1],
              },
            });
          }
        } else if (pS1.substring(0, 4) === 'mark' && aMarksCoCurricularS1[indexS1][pS1] !== '') {
          newValuesS1.values.push({
            userEnteredValue: {
              numberValue: aMarksCoCurricularS1[indexS1][pS1],
            },
          });
        } else if (pS1 === 'totalMarks') {
          newValuesS1.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksCoCurricularS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS1[indexS1].fieldNumber})<=${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS1_maxMarks}),SUM(C${aMarksCoCurricularS1[indexS1].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS1[indexS1].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS1_maxMarks}")`,
            },
          });
        }
      }
      newRowsCoCurricularS1.updateCells.rows.push(newValuesS1);
    }
    for (let indexS2 = 0; indexS2 < aMarksS2.length; indexS2++) {
      const newValuesS2 = {
        values: [],
      };
      let indexAlphabet = 2;
      for (const pS2 in aSquemaS2) {
        if (pS2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS2 in aMarksS2[indexS2]) {
        if (pS2 === 'studentName' || aMarksS2[indexS2][pS2] === '') {
          if (pS2 === 'studentName') {
            newValuesS2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksS2[indexS2][pS2],
              },
            });
          } else {
            newValuesS2.values.push({
              userEnteredValue: {
                stringValue: aMarksS2[indexS2][pS2],
              },
            });
          }
        } else if (pS2 === 'isAbsent') {
          newValuesS2.values.push({
            userEnteredValue: {
              boolValue: aMarksS2[indexS2][pS2],
            },
          });
        } else if (pS2.substring(0, 4) === 'mark' && aMarksS2[indexS2][pS2] !== '') {
          newValuesS2.values.push({
            userEnteredValue: {
              numberValue: aMarksS2[indexS2][pS2],
            },
          });
        } else if (pS2 === 'totalMarks') {
          newValuesS2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(D${aMarksS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelS2_maxMarks}),SUM(D${aMarksS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelS2_maxMarks}")`,
            },
          });
        }
      }
      newRowsS2.updateCells.rows.push(newValuesS2);
    }
    for (let indexS2 = 0; indexS2 < aMarksHWS2.length; indexS2++) {
      const newValuesS2 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS2 in aSquemaHWS2) {
        if (pS2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS2 in aMarksHWS2[indexS2]) {
        if (pS2 === 'studentName' || aMarksHWS2[indexS2][pS2] === '') {
          if (pS2 === 'studentName') {
            newValuesS2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksHWS2[indexS2][pS2],
              },
            });
          } else {
            newValuesS2.values.push({
              userEnteredValue: {
                stringValue: aMarksHWS2[indexS2][pS2],
              },
            });
          }
        } else if (pS2.substring(0, 4) === 'mark' && aMarksHWS2[indexS2][pS2] !== '') {
          newValuesS2.values.push({
            userEnteredValue: {
              numberValue: aMarksHWS2[indexS2][pS2],
            },
          });
        } else if (pS2 === 'totalMarks') {
          newValuesS2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksHWS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksHWS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS2_maxMarks}),SUM(C${aMarksHWS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksHWS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].homeworkMarks.homeworkPanelS2_maxMarks}")`,
            },
          });
        }
      }
      newRowsHWS2.updateCells.rows.push(newValuesS2);
    }
    for (let indexS2 = 0; indexS2 < aMarksAssignmentS2.length; indexS2++) {
      const newValuesS2 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS2 in aSquemaAssignmentS2) {
        if (pS2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS2 in aMarksAssignmentS2[indexS2]) {
        if (pS2 === 'studentName' || aMarksAssignmentS2[indexS2][pS2] === '') {
          if (pS2 === 'studentName') {
            newValuesS2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksAssignmentS2[indexS2][pS2],
              },
            });
          } else {
            newValuesS2.values.push({
              userEnteredValue: {
                stringValue: aMarksAssignmentS2[indexS2][pS2],
              },
            });
          }
        } else if (pS2.substring(0, 4) === 'mark' && aMarksAssignmentS2[indexS2][pS2] !== '') {
          newValuesS2.values.push({
            userEnteredValue: {
              numberValue: aMarksAssignmentS2[indexS2][pS2],
            },
          });
        } else if (pS2 === 'totalMarks') {
          newValuesS2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksAssignmentS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksAssignmentS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS2_maxMarks}),SUM(C${aMarksAssignmentS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksAssignmentS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].assignmentMarks.assignmentPanelS2_maxMarks}")`,
            },
          });
        }
      }
      newRowsAssignmentS2.updateCells.rows.push(newValuesS2);
    }
    for (let indexS2 = 0; indexS2 < aMarksQuiz1S2.length; indexS2++) {
      const newValuesS2 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS2 in aSquemaQuiz1S2) {
        if (pS2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS2 in aMarksQuiz1S2[indexS2]) {
        if (pS2 === 'studentName' || aMarksQuiz1S2[indexS2][pS2] === '') {
          if (pS2 === 'studentName') {
            newValuesS2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQuiz1S2[indexS2][pS2],
              },
            });
          } else {
            newValuesS2.values.push({
              userEnteredValue: {
                stringValue: aMarksQuiz1S2[indexS2][pS2],
              },
            });
          }
        } else if (pS2.substring(0, 4) === 'mark' && aMarksQuiz1S2[indexS2][pS2] !== '') {
          newValuesS2.values.push({
            userEnteredValue: {
              numberValue: aMarksQuiz1S2[indexS2][pS2],
            },
          });
        } else if (pS2 === 'totalMarks') {
          newValuesS2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksQuiz1S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz1S2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS2_maxMarks}),SUM(C${aMarksQuiz1S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz1S2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz1PanelS2_maxMarks}")`,
            },
          });
        }
      }
      newRowsQuiz1S2.updateCells.rows.push(newValuesS2);
    }
    for (let indexS2 = 0; indexS2 < aMarksQuiz2S2.length; indexS2++) {
      const newValuesS2 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS2 in aSquemaQuiz2S2) {
        if (pS2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS2 in aMarksQuiz2S2[indexS2]) {
        if (pS2 === 'studentName' || aMarksQuiz2S2[indexS2][pS2] === '') {
          if (pS2 === 'studentName') {
            newValuesS2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksQuiz2S2[indexS2][pS2],
              },
            });
          } else {
            newValuesS2.values.push({
              userEnteredValue: {
                stringValue: aMarksQuiz2S2[indexS2][pS2],
              },
            });
          }
        } else if (pS2.substring(0, 4) === 'mark' && aMarksQuiz2S2[indexS2][pS2] !== '') {
          newValuesS2.values.push({
            userEnteredValue: {
              numberValue: aMarksQuiz2S2[indexS2][pS2],
            },
          });
        } else if (pS2 === 'totalMarks') {
          newValuesS2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksQuiz2S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz2S2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS2_maxMarks}),SUM(C${aMarksQuiz2S2[indexS2].fieldNumber}:${letterAlphabet}${aMarksQuiz2S2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].quizMarks.quiz2PanelS2_maxMarks}")`,
            },
          });
        }
      }
      newRowsQuiz2S2.updateCells.rows.push(newValuesS2);
    }
    for (let indexS2 = 0; indexS2 < aMarksCoCurricularS2.length; indexS2++) {
      const newValuesS2 = {
        values: [],
      };
      let indexAlphabet = 1;
      for (const pS2 in aSquemaCoCurricularS2) {
        if (pS2.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      indexAlphabet = indexAlphabet === 1 ? 2 : indexAlphabet;
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS2 in aMarksCoCurricularS2[indexS2]) {
        if (pS2 === 'studentName' || aMarksCoCurricularS2[indexS2][pS2] === '') {
          if (pS2 === 'studentName') {
            newValuesS2.values.unshift({
              userEnteredValue: {
                stringValue: aMarksCoCurricularS2[indexS2][pS2],
              },
            });
          } else {
            newValuesS2.values.push({
              userEnteredValue: {
                stringValue: aMarksCoCurricularS2[indexS2][pS2],
              },
            });
          }
        } else if (pS2.substring(0, 4) === 'mark' && aMarksCoCurricularS2[indexS2][pS2] !== '') {
          newValuesS2.values.push({
            userEnteredValue: {
              numberValue: aMarksCoCurricularS2[indexS2][pS2],
            },
          });
        } else if (pS2 === 'totalMarks') {
          newValuesS2.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(C${aMarksCoCurricularS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS2[indexS2].fieldNumber})<=${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS2_maxMarks}),SUM(C${aMarksCoCurricularS2[indexS2].fieldNumber}:${letterAlphabet}${aMarksCoCurricularS2[indexS2].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].studentAttendenceActivitiesMarks.AttendenceActivitiesS2_maxMarks}")`,
            },
          });
        }
      }
      newRowsCoCurricularS2.updateCells.rows.push(newValuesS2);
    }
    for (let indexS3 = 0; indexS3 < aMarksS3.length; indexS3++) {
      const newValuesS3 = {
        values: [],
      };
      let indexAlphabet = 2;
      for (const pS3 in aSquemaS3) {
        if (pS3.substring(0, 4) === 'mark') {
          indexAlphabet += 1;
        }
      }
      const letterAlphabet = alphabet[indexAlphabet];
      for (const pS3 in aMarksS3[indexS3]) {
        if (pS3 === 'studentName' || aMarksS3[indexS3][pS3] === '') {
          if (pS3 === 'studentName') {
            newValuesS3.values.unshift({
              userEnteredValue: {
                stringValue: aMarksS3[indexS3][pS3],
              },
            });
          } else {
            newValuesS3.values.push({
              userEnteredValue: {
                stringValue: aMarksS3[indexS3][pS3],
              },
            });
          }
        } else if (pS3 === 'isAbsent') {
          newValuesS3.values.push({
            userEnteredValue: {
              boolValue: aMarksS3[indexS3][pS3],
            },
          });
        } else if (pS3.substring(0, 4) === 'mark' && aMarksS3[indexS3][pS3] !== '') {
          newValuesS3.values.push({
            userEnteredValue: {
              numberValue: aMarksS3[indexS3][pS3],
            },
          });
        } else if (pS3 === 'totalMarks') {
          newValuesS3.values.push({
            userEnteredValue: {
              formulaValue: `=IF(AND(SUM(D${aMarksS3[indexS3].fieldNumber}:${letterAlphabet}${aMarksS3[indexS3].fieldNumber})<=${teacherPanel?.studentsMark[0].academicPanelS3_maxMarks}),SUM(D${aMarksS3[indexS3].fieldNumber}:${letterAlphabet}${aMarksS3[indexS3].fieldNumber}),"The sum cannot be more than ${teacherPanel?.studentsMark[0].academicPanelS3_maxMarks}")`,
            },
          });
        }
      }
      if (showS3Marks) {
        newRowsS3.updateCells.rows.push(newValuesS3);
      }
    }
    if (newRowsS1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 1,
                endRowIndex: newRowsS1.updateCells.rows.length,
                startColumnIndex: 2,
                endColumnIndex: 3,
              },
              fields: 'dataValidation',
            },
          },
          newRowsS1,
        ],
      });
    }
    if (newRowsHWS1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsHWS1,
        ],
      });
    }
    if (newRowsAssignmentS1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsAssignmentS1,
        ],
      });
    }
    if (newRowsQuiz1S1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsQuiz1S1,
        ],
      });
    }
    if (newRowsQuiz2S1.updateCells.rows[1]?.values.length !== 0 && isselectedYear === '2023-2024') {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsQuiz2S1,
        ],
      });
    }
    if (newRowsCoCurricularS1.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsCoCurricularS1,
        ],
      });
    }
    if (newRowsS2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 1,
                endRowIndex: newRowsS2.updateCells.rows.length,
                startColumnIndex: 2,
                endColumnIndex: 3,
              },
              fields: 'dataValidation',
            },
          },
          newRowsS2,
        ],
      });
    }
    if (newRowsHWS2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsHWS2,
        ],
      });
    }
    if (newRowsAssignmentS2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsAssignmentS2,
        ],
      });
    }
    if (newRowsQuiz1S2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsQuiz1S2,
        ],
      });
    }
    if (newRowsQuiz2S2.updateCells.rows[1]?.values.length !== 0 && isselectedYear === '2023-2024') {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsQuiz2S2,
        ],
      });
    }
    if (newRowsCoCurricularS2.updateCells.rows[1]?.values.length !== 0) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          newRowsCoCurricularS2,
        ],
      });
    }
    if (newRowsS3.updateCells.rows[1]?.values.length !== 0 && showS3Marks) {
      payloadPutSheet.push({
        requests: [
          {
            appendDimension: {
              sheetId: '',
              dimension: 'COLUMNS',
              length: 50,
            },
          },
          {
            repeatCell: {
              cell: {
                dataValidation: {
                  condition: {
                    type: 'BOOLEAN',
                  },
                },
              },
              range: {
                sheetId: '',
                startRowIndex: 1,
                endRowIndex: newRowsS3.updateCells.rows.length,
                startColumnIndex: 2,
                endColumnIndex: 3,
              },
              fields: 'dataValidation',
            },
          },
          newRowsS3,
        ],
      });
    }
    if (newRowsS1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsHWS1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsAssignmentS1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsQuiz1S1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsQuiz2S1.updateCells.rows[1]?.values.length === 0 && isselectedYear === '2023-2024') {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsCoCurricularS1.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsS2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsHWS2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsAssignmentS2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsQuiz1S2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsQuiz2S2.updateCells.rows[1]?.values.length === 0 && isselectedYear === '2023-2024') {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsCoCurricularS2.updateCells.rows[1]?.values.length === 0) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    if (newRowsS3.updateCells.rows[1]?.values.length === 0 && showS3Marks) {
      payloadPutSheet.push({
        requests: [
          {
            updateCells: {
              rows: [
                {
                  values: [
                    {
                      userEnteredValue: {
                        stringValue: 'Student Name',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Total Marks',
                      },
                    },
                    {
                      userEnteredValue: {
                        stringValue: 'Is Absent',
                      },
                    },
                  ],
                },
              ],
              start: {
                rowIndex: 0,
                columnIndex: 0,
                sheetId: '',
              },
              fields: 'userEnteredValue',
            },
          },
        ],
      });
    }
    let payloadCreateSheet = {};
    if (systemBased === 'Quarter') {
      payloadCreateSheet = {
        properties: {
          title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
        },
        sheets: [
          {
            properties: {
              title: 'Marks_Q1_Exam_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q1_Homework_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q2_Exam_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q2_Homework_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q3_Exam_Marks',
            },
          },
          {
            properties: {
              title: 'Marks_Q3_Homework_Marks',
            },
          },
        ],
      }
    } else if (systemBased === 'Semester') {
      if (showS3 === 'PSTU' && showS3Marks) {
        payloadCreateSheet = {
          properties: {
            title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
          },
          sheets: [
            {
              properties: {
                title: 'Marks_S1_Exam_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Homework_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Assignment_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Quiz1_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Quiz2_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Co-Curricular_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Exam_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Homework_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Assignment_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Quiz3_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Quiz4_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Co-Curricular_Marks',
              },
            },
            {
              properties: {
                title: 'PSTU_Exam_Marks',
              },
            },
          ],
        }
      } else if (showS3 === 'Supplementary' && showS3Marks) {
        payloadCreateSheet = {
          properties: {
            title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
          },
          sheets: [
            {
              properties: {
                title: 'Marks_S1_Exam_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Homework_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Assignment_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Quiz1_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Quiz2_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Co-Curricular_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Exam_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Homework_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Assignment_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Quiz3_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Quiz4_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Co-Curricular_Marks',
              },
            },
            {
              properties: {
                title: 'Supplementary_Exam_Marks',
              },
            },
          ],
        }
      } else {
        payloadCreateSheet = {
          properties: {
            title: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
          },
          sheets: [
            {
              properties: {
                title: 'Marks_S1_Exam_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Homework_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Assignment_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Quiz1_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Quiz2_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S1_Co-Curricular_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Exam_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Homework_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Assignment_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Quiz3_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Quiz4_Marks',
              },
            },
            {
              properties: {
                title: 'Marks_S2_Co-Curricular_Marks',
              },
            },
          ],
        }
      }
      if (isselectedYear !== '2023-2024') {
        payloadCreateSheet.sheets = payloadCreateSheet.sheets.filter(sheet => sheet.properties.title !== 'Marks_S1_Quiz2_Marks' && sheet.properties.title !== 'Marks_S2_Quiz4_Marks');
      
        let sheet = payloadCreateSheet.sheets.find(sheet => sheet.properties.title === 'Marks_S2_Quiz3_Marks');
        if (sheet) {
          sheet.properties.title = 'Marks_S2_Quiz2_Marks';
        }
      }
    }
    setLoading(true);
    deleteGoogleSheet(idSheet);
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const uploader = new MediaUploader({
      token: baseUrl?.access_token,
      filename: `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
      contentType: 'application/vnd.google-apps.spreadsheet',
      metadata: payloadCreateSheet,
      sheetMood: 'New',
      file: payloadPutSheet,
      sheetId: idSheet,
      onComplete: async () => {
        await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('export'), setOpenDialogErrorImport, setDialogErrorImportMessage));
        setLoading(false);
        setOpenSheet(true);
        const payload = {
          academicYear: isselectedYear,
          locationId: isSelectedlocation,
          courseId: isSelectedCourseId,
        };
        dispatch(getMarksDetails(payload, setOpenDialogErrorImport, setDialogErrorImportMessage));
        getGoogleSheet((i) => {
          const fileData = JSON.parse(i).files;
          if (fileData !== undefined) {
            const sNameSheet = fileData.filter(
              (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
            );
            if (sNameSheet.length !== 0) {
              setNameSheet(sNameSheet[0].name);
              setIdSheet(sNameSheet[0].id);
            } else {
              setNameSheet('Sheet has not been exported');
              setIdSheet();
            }
          } else {
            setShowTokenAdvice(true);
            setLoading(false);
          }
        });
      },
      onError: () => {
        setShowTokenAdvice(true);
        setLoading(false);
      },
    });
    uploader.upload();
  }
  
  const sendSheet = async () => {
    await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('export'), setOpenDialogErrorImport, setDialogErrorImportMessage));
    if(systemBased === 'Semester') {
      sendSemesterSheet();
    }else{
      sendQuarterSheet();
    }
  };
  const exportSheetExists = async () => {
    await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('export'), setOpenDialogErrorImport, setDialogErrorImportMessage));
    setOpenSheetExists(false);
    if(systemBased === 'Semester') {
      exportSemesterSheetExists();
    }else{
      exportQuarterSheetExists();
    }
  };
  const UpdateMarksBeforeMarksAdded = async (payloadEdit) => {
    await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('import'), setOpenDialogErrorImport, setDialogErrorImportMessage));
    dispatch(updateMarksStudents(payloadEdit, refreshList, setOpenDialogErrorImport, setLoading, setDialogErrorImportMessage, t));
  };

  const saveDBFromSheet = async (data) => {
    setLoading(true);
    let DialogErrorMark = true;
    const payloadEdit = [];
    const payloadAdd = [];
    data.sheets.map((l) => {
      for (let iRow = 1; iRow < l.data[0]?.rowData?.length; iRow++) {
        const titles = l.data[0].rowData[0].values;
        const info = l.data[0].rowData[iRow].values;
        const numRow = iRow - 1;
        let nameQ = l.properties.title;
        let panel = csvDataMarks[numRow];
        let quiz;
        let marks;
        switch (nameQ) {
          case 'Marks_Q1_Exam_Marks':
            nameQ = 'Q1';
            marks = 'Exam_Marks';
            break;
          case 'Marks_Q1_Homework_Marks':
            nameQ = 'Q1';
            panel = csvDataMarks[numRow].homeworkMarks;
            marks = 'Homework_Marks';
            break;
          case 'Marks_Q2_Exam_Marks':
            nameQ = 'Q2';
            marks = 'Exam_Marks';
            break;
          case 'Marks_Q2_Homework_Marks':
            nameQ = 'Q2';
            panel = csvDataMarks[numRow].homeworkMarks;
            marks = 'Homework_Marks';
            break;
          case 'Marks_Q3_Exam_Marks':
            nameQ = 'Q3';
            marks = 'Exam_Marks';
            break;
          case 'Marks_Q3_Homework_Marks':
            nameQ = 'Q3';
            panel = csvDataMarks[numRow].homeworkMarks;
            marks = 'Homework_Marks';
            break;
          case 'Marks_S1_Exam_Marks':
            nameQ = 'S1';
            marks = 'Exam_Marks';
            break;
          case 'Marks_S1_Homework_Marks':
            nameQ = 'S1';
            panel = csvDataMarks[numRow].homeworkMarks;
            marks = 'Homework_Marks';
            break;
          case 'Marks_S1_Assignment_Marks':
            nameQ = 'S1';
            panel = csvDataMarks[numRow].assignmentMarks;
            marks = 'Assignment_Marks';
            break;
          case 'Marks_S1_Quiz1_Marks':
            nameQ = 'S1';
            panel = csvDataMarks[numRow].quizMarks;
            quiz = 'quiz1';
            marks = 'Quiz_Marks';
            break;
          case 'Marks_S1_Quiz2_Marks':
            nameQ = 'S1';
            panel = csvDataMarks[numRow].quizMarks;
            quiz = 'quiz2';
            marks = 'Quiz_Marks';
            break;
          case 'Marks_S1_Co-Curricular_Marks':
            nameQ = 'S1';
            panel = csvDataMarks[numRow].studentAttendenceActivitiesMarks;
            marks = 'Co-Curricular_Marks';
            break;
          case 'Marks_S2_Exam_Marks':
            nameQ = 'S2';
            marks = 'Exam_Marks';
            break;
          case 'Marks_S2_Homework_Marks':
            nameQ = 'S2';
            panel = csvDataMarks[numRow].homeworkMarks;
            marks = 'Homework_Marks';
            break;
          case 'Marks_S2_Assignment_Marks':
            nameQ = 'S2';
            panel = csvDataMarks[numRow].assignmentMarks;
            marks = 'Assignment_Marks';
            break;
          case 'Marks_S2_Quiz3_Marks':
            nameQ = 'S2';
            panel = csvDataMarks[numRow].quizMarks;
            quiz = 'quiz1';
            marks = 'Quiz_Marks';
            break;
          case 'Marks_S2_Quiz4_Marks':
            nameQ = 'S2';
            panel = csvDataMarks[numRow].quizMarks;
            quiz = 'quiz2';
            marks = 'Quiz_Marks';
            break;
          case 'Marks_S2_Co-Curricular_Marks':
            nameQ = 'S2';
            panel = csvDataMarks[numRow].studentAttendenceActivitiesMarks;
            marks = 'Co-Curricular_Marks';
            break;
          case 'PSTU_Exam_Marks':
            nameQ = 'S3';
            marks = 'Exam_Marks';
            break;
          case 'Supplementary_Exam_Marks':
            nameQ = 'S3';
            marks = 'Exam_Marks';
            break;
          default:
            break;
        }
        for (let iMarks = (marks === 'Exam_Marks' ? 3 : 2); iMarks < titles.length; iMarks++) {
          const id = panel[`${nameQ}_id`]?.[quiz] !== undefined ? panel[`${nameQ}_id`][quiz][iMarks - 2] : (typeof panel[`${nameQ}_id`] === 'object' || Array.isArray(panel[`${nameQ}_id`])) ? panel[`${nameQ}_id`][iMarks - 2] : panel[`${nameQ}_id`]
          if (info[iMarks] !== undefined && info[iMarks].formattedValue !== '' && info[iMarks].formattedValue !== undefined && id !== undefined) {
            const title = titles[iMarks].formattedValue;
            const maxMark = title.split(' / ');
            if (parseFloat(maxMark[1]) < parseFloat(info[iMarks].formattedValue)) {
              setOpenDialogErrorMarkImport(true);
              DialogErrorMark = false;
            }
          }
        }
      }
    });
    if (DialogErrorMark === true && openDialogExportFirst === false) {
      data.sheets.map((l) => {
        for (let iRow = 1; iRow < l.data[0]?.rowData?.length; iRow++) {
          const titles = l.data[0].rowData[0].values;
          const info = l.data[0].rowData[iRow].values;
          const numRow = iRow - 1;
          if (csvDataMarks[numRow] !== undefined) {
            const payloadRow = {
              academicYear: isselectedYear,
              courseId: isSelectedCourseId,
              idStudent: '',
              isAttended: '',
              locationId: isSelectedlocation,
              quarter: '',
              studentId: '',
              studentDetailMarks: [],
            };
            let nameQ = l.properties.title;
            let panel = csvDataMarks[numRow];
            let quiz;
            let panelMark;
            let marks;
            switch (nameQ) {
              case 'Marks_Q1_Exam_Marks':
                nameQ = 'Q1';
                panelMark = aQ1IDs;
                marks = 'Exam_Marks';
                break;
              case 'Marks_Q1_Homework_Marks':
                nameQ = 'Q1';
                panel = csvDataMarks[numRow].homeworkMarks;
                panelMark = aQ1HWIDs;
                marks = 'Homework_Marks';
                break;
              case 'Marks_Q2_Exam_Marks':
                nameQ = 'Q2';
                panelMark = aQ2IDs;
                marks = 'Exam_Marks';
                break;
              case 'Marks_Q2_Homework_Marks':
                nameQ = 'Q2';
                panelMark = aQ2HWIDs;
                panel = csvDataMarks[numRow].homeworkMarks;
                marks = 'Homework_Marks';
                break;
              case 'Marks_Q3_Exam_Marks':
                nameQ = 'Q3';
                panelMark = aQ3IDs;
                marks = 'Exam_Marks';
                break;
              case 'Marks_Q3_Homework_Marks':
                nameQ = 'Q3';
                panel = csvDataMarks[numRow].homeworkMarks;
                panelMark = aQ3HWIDs;
                marks = 'Homework_Marks';
                break;
              case 'Marks_S1_Exam_Marks':
                nameQ = 'S1';
                panelMark = aS1IDs;
                marks = 'Exam_Marks';
                break;
              case 'Marks_S1_Homework_Marks':
                nameQ = 'S1';
                panel = csvDataMarks[numRow].homeworkMarks;
                panelMark = aS1HWIDs;
                marks = 'Homework_Marks';
                break;
              case 'Marks_S1_Assignment_Marks':
                nameQ = 'S1';
                panel = csvDataMarks[numRow].assignmentMarks;
                panelMark = aS1AssignmentIDs;
                marks = 'Assignment_Marks';
                break;
              case 'Marks_S1_Quiz1_Marks':
                nameQ = 'S1';
                panel = csvDataMarks[numRow].quizMarks;
                panelMark = aS1Quiz1IDs;
                marks = 'Quiz_Marks';
                quiz = 'quiz1';
                break;
              case 'Marks_S1_Quiz2_Marks':
                nameQ = 'S1';
                panel = csvDataMarks[numRow].quizMarks;
                panelMark = aS1Quiz2IDs;
                marks = 'Quiz_Marks';
                quiz = 'quiz2';
                break;
              case 'Marks_S1_Co-Curricular_Marks':
                nameQ = 'S1';
                panel = csvDataMarks[numRow].studentAttendenceActivitiesMarks;
                panelMark = aS1CoCurricularIDs;
                marks = 'Co-Curricular_Marks';
                break;
              case 'Marks_S2_Exam_Marks':
                nameQ = 'S2';
                panelMark = aS2IDs;
                marks = 'Exam_Marks';
                break;
              case 'Marks_S2_Homework_Marks':
                nameQ = 'S2';
                panel = csvDataMarks[numRow].homeworkMarks;
                panelMark = aS2HWIDs;
                marks = 'Homework_Marks';
                break;
              case 'Marks_S2_Assignment_Marks':
                nameQ = 'S2';
                panel = csvDataMarks[numRow].assignmentMarks;
                panelMark = aS2AssignmentIDs;
                marks = 'Assignment_Marks';
                break;
              case 'Marks_S2_Quiz3_Marks':
                nameQ = 'S2';
                panel = csvDataMarks[numRow].quizMarks;
                panelMark = aS2Quiz1IDs;
                marks = 'Quiz_Marks';
                quiz = 'quiz1';
                break;
              case 'Marks_S2_Quiz4_Marks':
                nameQ = 'S2';
                panel = csvDataMarks[numRow].quizMarks;
                panelMark = aS2Quiz2IDs;
                marks = 'Quiz_Marks';
                quiz = 'quiz2';
                break;
              case 'Marks_S2_Co-Curricular_Marks':
                nameQ = 'S2';
                panel = csvDataMarks[numRow].studentAttendenceActivitiesMarks;
                panelMark = aS2CoCurricularIDs;
                marks = 'Co-Curricular_Marks';
                break;
              case 'PSTU_Exam_Marks':
                nameQ = 'S3';
                panelMark = aS3IDs;
                marks = 'Exam_Marks';
                break;
              case 'Supplementary_Exam_Marks':
                nameQ = 'S3';
                panelMark = aS3IDs;
                marks = 'Exam_Marks';
                break;
              default:
                break;
            }
            payloadRow.quarter = nameQ;
            const nameQId = panel[`${nameQ}_id`];
            const quizArray = nameQId?.[quiz];
            const isNameQIdObject = typeof nameQId === 'object' || Array.isArray(nameQId);
            const isQuizArrayObject = typeof quizArray === 'object' || Array.isArray(quizArray);
            const isLengthGreaterThan1 = isQuizArrayObject ? quizArray?.length > 1 : isNameQIdObject ? nameQId?.length > 1 : false;
            if ((isNameQIdObject || isQuizArrayObject) && isLengthGreaterThan1) {
              payloadRow.idStudent = panel[`${nameQ}_id`][quiz] !== undefined ? panel[`${nameQ}_id`][quiz][0] : (typeof panel[`${nameQ}_id`] === 'object' || Array.isArray(panel[`${nameQ}_id`])) ? panel[`${nameQ}_id`][0] : panel[`${nameQ}_id`];
              payloadRow.studentId = panel[`${nameQ}_id`][quiz] !== undefined ? panel[`${nameQ}_id`][quiz][0] : (typeof panel[`${nameQ}_id`] === 'object' || Array.isArray(panel[`${nameQ}_id`])) ? panel[`${nameQ}_id`][0] : panel[`${nameQ}_id`];
              const idSet = new Set();
              for (let iMarks = (marks === 'Exam_Marks' ? 3 : 2); iMarks < titles.length; iMarks++) {
                if ((info[iMarks] !== undefined && info[iMarks].formattedValue !== '' && info[iMarks].formattedValue !== undefined) || info[2].formattedValue === 'TRUE') {
                  const panelMarkId = panel[`${nameQ}_id`][quiz] !== undefined ? panel[`${nameQ}_id`][quiz][iMarks - 1] : panel[`${nameQ}_id`][iMarks - (marks === 'Exam_Marks' ? 2 : 1)];
                  if (panelMarkId !== undefined) {
                    switch (marks) {
                      case 'Exam_Marks':
                        payloadRow.isAttended = !info[2].formattedValue || info[2].formattedValue === 'FALSE' ? true : false;
                        if (!idSet.has(panelMarkId)) {
                          idSet.add(panelMarkId);
                          payloadRow.studentDetailMarks.push({
                            academicPanelMarksId: panelMarkId,
                            obtainedMarks: parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue),
                          });
                        } else {
                          for (const item of panel[`academicPanel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const academicPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                academicPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Homework_Marks':
                        if (!idSet.has(panelMarkId)) {
                          idSet.add(panelMarkId);
                          payloadRow.studentDetailMarks.push({
                            homeworkPanelMarksId: panelMarkId,
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                          });
                        } else {
                          for (const item of panel[`homeworkPanel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const homeworkPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                homeworkPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Assignment_Marks':
                        if (!idSet.has(panelMarkId)) {
                          idSet.add(panelMarkId);
                          payloadRow.studentDetailMarks.push({
                            assignmentPanelMarksId: panelMarkId,
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                          });
                        } else {
                          for (const item of panel[`assignmentPanel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const assignmentPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                assignmentPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Quiz_Marks':
                        if (!idSet.has(panelMarkId)) {
                          idSet.add(panelMarkId);
                          payloadRow.studentDetailMarks.push({
                            quizPanelMarksId: panelMarkId,
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                            quiz: quiz,
                          });
                        } else {
                          for (const item of panel[`${quiz}Panel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const quizPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                quizPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Co-Curricular_Marks':
                        if (!idSet.has(panelMarkId)) {
                          idSet.add(panelMarkId);
                          payloadRow.studentDetailMarks.push({
                            attendenceActivitiesPanelMarksId: panelMarkId,
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                          });
                        } else {
                          for (const item of panel[`AttendenceActivities${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const attendenceActivitiesPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                attendenceActivitiesPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                    }
                  } else {
                    switch (marks) {
                      case 'Exam_Marks':
                        if (!idSet.has(panelMark[iMarks - 2]) && panelMark[iMarks - 2]) {
                          idSet.add(panelMark[iMarks - 2]);
                          payloadRow.isAttended = !info[2].formattedValue || info[2].formattedValue === 'FALSE' ? true : false;
                          payloadRow.studentDetailMarks.push({
                            academicPanelMarksId: panelMark[iMarks - 3],
                            obtainedMarks: parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue),
                          });
                        } else {
                          for (const item of panel[`academicPanel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const academicPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                academicPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Homework_Marks':
                        if (!idSet.has(panelMark[iMarks - 2]) && panelMark[iMarks - 2]) {
                          idSet.add(panelMark[iMarks - 2]);
                          payloadRow.studentDetailMarks.push({
                            homeworkPanelMarksId: panelMark[iMarks - 2],
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                          });
                        } else {
                          for (const item of panel[`homeworkPanel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const homeworkPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                homeworkPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Assignment_Marks':
                        if (!idSet.has(panelMark[iMarks - 2]) && panelMark[iMarks - 2]) {
                          idSet.add(panelMark[iMarks - 2]);
                          payloadRow.studentDetailMarks.push({
                            assignmentPanelMarksId: panelMark[iMarks - 2],
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                          });
                        } else {
                          for (const item of panel[`assignmentPanel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const assignmentPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                assignmentPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                      case 'Quiz_Marks':
                        if (!idSet.has(panelMark[iMarks - 2]) && panelMark[iMarks - 2]) {
                          idSet.add(panelMark[iMarks - 2]);
                          payloadRow.studentDetailMarks.push({
                            quizPanelMarksId: panelMark[iMarks - 2],
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                            quiz: quiz,
                          });
                        } else {
                          const ids = new Set();
                          for (const item of panel[`${quiz}Panel${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const quizPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                quizPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          }
                        }
                        break;
                      case 'Co-Curricular_Marks':
                        if (!idSet.has(panelMark[iMarks - 2]) && panelMark[iMarks - 2]) {
                          idSet.add(panelMark[iMarks - 2]);
                          payloadRow.studentDetailMarks.push({
                            attendenceActivitiesPanelMarksId: panelMark[iMarks - 2],
                            obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                          });
                        } else {
                          for (const item of panel[`AttendenceActivities${nameQ}`]) {
                            const title = titles[iMarks].formattedValue.split(" / ");
                            const conditionMet = title[0] === item.notes && title[1] == item.marks;

                            if (!idSet.has(item.id) && conditionMet) {
                              const attendenceActivitiesPanelMarksId = item.id;
                              const obtainedMarks = parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue);
                              payloadRow.studentDetailMarks.push({
                                attendenceActivitiesPanelMarksId,
                                obtainedMarks,
                              });
                              idSet.add(item.id);
                              break;
                            }
                          } 
                        }
                        break;
                    }
                  }
                }
              }
              if (payloadRow.idStudent !== undefined && payloadRow.idStudent !== '' && (payloadRow.studentDetailMarks[0]?.academicPanelMarksId !== undefined || payloadRow.studentDetailMarks[0]?.homeworkPanelMarksId !== undefined || payloadRow.studentDetailMarks[0]?.assignmentPanelMarksId !== undefined || payloadRow.studentDetailMarks[0]?.quizPanelMarksId !== undefined || payloadRow.studentDetailMarks[0]?.attendenceActivitiesPanelMarksId !== undefined)) {
                payloadEdit.push(payloadRow);
              }
            } else {
              let studentID;
              const idArray = (typeof panel[`${nameQ}_id`] === 'object' || Array.isArray(panel[`${nameQ}_id`])) ? panel[`${nameQ}_id`] : [panel[`${nameQ}_id`]];
              if (quiz === undefined) {
                studentID = idArray[0];
              } else {
                studentID = (typeof idArray[quiz] === 'object' || Array.isArray(idArray[quiz])) ? idArray[quiz][0] : idArray[quiz] !== undefined ? idArray[quiz] : idArray[0];
              }
              payloadRow.idStudent = studentID;
              payloadRow.studentId = studentID;
              for (let iMarks = (marks === 'Exam_Marks' ? 3 : 2); iMarks < titles.length; iMarks++) {
                if ((info?.[iMarks] !== undefined && info?.[iMarks]?.formattedValue !== '' && info?.[iMarks]?.formattedValue !== undefined && panelMark?.[iMarks - (marks === 'Exam_Marks' ? 3 : 2)] !== undefined) || info?.[2]?.formattedValue === 'TRUE') {
                  switch (marks) {
                    case 'Exam_Marks':
                      payloadRow.isAttended = !info[2].formattedValue || info[2].formattedValue === 'FALSE' ? true : false;
                      payloadRow.studentDetailMarks.push({
                        academicPanelMarksId: panelMark[iMarks - 3],
                        obtainedMarks: parseFloat(info[2].formattedValue === 'TRUE' ? 0 : info[iMarks].formattedValue),
                      });
                      break;
                    case 'Homework_Marks':
                      payloadRow.studentDetailMarks.push({
                        homeworkPanelMarksId: panelMark[iMarks - 2],
                        obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                      });
                      break;
                    case 'Assignment_Marks':
                      payloadRow.studentDetailMarks.push({
                        assignmentPanelMarksId: panelMark[iMarks - 2],
                        obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                      });
                      break;
                    case 'Quiz_Marks':
                      payloadRow.studentDetailMarks.push({
                        quizPanelMarksId: panelMark[iMarks - 2],
                        obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                        quiz: quiz,
                      });
                      break;
                    case 'Co-Curricular_Marks':
                      payloadRow.studentDetailMarks.push({
                        attendenceActivitiesPanelMarksId: panelMark[iMarks - 2],
                        obtainedMarks: parseFloat(info[iMarks].formattedValue ? info[iMarks].formattedValue : 0),
                      });
                      break;
                  }
                }
              }
              if (payloadRow.idStudent !== undefined && payloadRow.idStudent !== '' && payloadRow.studentDetailMarks.length > 0) {
                payloadAdd.push(payloadRow);
              }
            }
          }
        }
      });
    }
    if (payloadAdd.length > 0) {
      dispatch(createStudentMarks(payloadAdd, UpdateMarksBeforeMarksAdded, payloadEdit, setOpenDialogErrorImport, setLoading, setDialogErrorImportMessage, t));
    } else {
      await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('import'), setOpenDialogErrorImport, setDialogErrorImportMessage));
      dispatch(updateMarksStudents(payloadEdit, refreshList, setOpenDialogErrorImport, setLoading, setDialogErrorImportMessage, t));
    }
  };

  const updateMarksTimePayload = (type) => {
    return {
      syncType: 'marks',
      type,
      syncTime: new Date(),
      locationId: isSelectedlocation,
      academicYear: isselectedYear,
      courseId: isSelectedCourseId,
    };
  }

  const renderSheet = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            {t('SHEET_EXISTS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}>
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('CANCEL')}
          onClick={handleSheetDialog}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={exportSheetExists}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const getGoogleSheetbyId = (id, callback) => {
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://sheets.googleapis.com/v4/spreadsheets/${id}?includeGridData=true`);
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
    xhr.onload = () => {
      callback(xhr.responseText);
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };
  const ImportCsv = () => {
    setOpenImportSheet(false);
    getGoogleSheet((i) => {
      const fileData = JSON.parse(i).files;
      if (fileData !== undefined) {
        const sNameSheet = fileData.filter(
          (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
        );

        getGoogleSheetbyId(sNameSheet[0].id, (data) => {
          const fileDataById = JSON.parse(data);
          saveDBFromSheet(fileDataById);
        });
        setShowTokenAdvice(true);
        setLoading(false);
      }
    });
  };
  const handleImportDialog = () => {
    setOpenDialog(false);
  };
  const renderMissingContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('SHEET_MISSING')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={handleImportDialog}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderErrorGetData = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ERROR_MESSAGE_GET_MARKS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setOpenDialogErrorGetData(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderExportFirst = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('EXPORT_FIRST_MESSAGE')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => {
            setOpenImportSheet(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderImportTextContent = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.importDialogContent}
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('IMPORT_FROM_SHEET_ADVICE')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setOpenImportSheet(false)}
          btntype={Buttons.SECONDARY}
        />
        <DialogAtom
          isOpen={openDialogExportFirst}
          dialogHeading={t('EXPORT_FIRST')}
          customClass={classes.DialogAtom}
          content={renderExportFirst()}
          secHandle={() => {
            setOpenImportSheet(false);
          }}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={ImportCsv}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const ClickOnImportCsv = async () => {
    getGoogleSheet((i) => {
      const fileData = JSON.parse(i).files;
      if (fileData !== undefined) {
        const sNameSheet = fileData.filter(
          (item) => item.name === `${isSelectedlocationName} ${isSelectedCourseName}(${isselectedYear}) Marks Sync Sheet`,
        );

        const webViewLink = sNameSheet.length === 0 ? [] : sNameSheet[0]?.webViewLink.split('/');
        if (webViewLink[3] === 'spreadsheets') {
          setOpenImportSheet(true);
        } else {
          setOpenDialog(true);
        }
      } else {
        setShowTokenAdvice(true);
        setLoading(false);
      }
    });
    if (openDialogExportFirst === false) {
      await dispatch(saveImportExportSheetTimes(updateMarksTimePayload('import'), setOpenDialogErrorImport, setDialogErrorImportMessage));
    }
  };
  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('MARKS_SYNC')}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.direction}>
        <Grid xs={12}>
          <SyncFilters
            assignedYears={assignedYears}
            isselectedYear={isselectedYear}
            locationData={locationData}
            isSelectedlocation={isSelectedlocation}
            courseData={courseData}
            isSelectedCourse={isSelectedCourse}
            handleYearDropDownChange={handleYearDropDownChange}
            handlelocationDropDownChange={handlelocationDropDownChange}
            handleClassWeekDropDownChange={handleClassWeekDropDownChange}
            loading={loading}
            isSelectedCourseName={isSelectedCourseName}
            nameSheet={nameSheet}
            createAtSheet={createAtSheet}
            setNameSheet={setNameSheet}
            csvDataMarks1={csvDataMarks1}
            setCsvDataMarks1={setCsvDataMarks1}
            csvDataMarks2={csvDataMarks2}
            setCsvDataMarks2={setCsvDataMarks2}
            csvDataMarks3={csvDataMarks3}
            setCsvDataMarks3={setCsvDataMarks3}
            sendSheet={sendSheet}
            setLoading={setLoading}
            showDataMarks={showDataMarks}
            setShowDataMarks={setShowDataMarks}
            setShowNameSheet={setShowNameSheet}
            showNameSheet={showNameSheet}
            importSheet={ClickOnImportCsv}
            exportSheetTime={exportSheetTime}
            importSheetTime={importSheetTime}
            systemBased={systemBased}
            showS3={showS3}
            sBorderBottomQ1={sBorderBottomQ1}
            sBorderBottomQ2={sBorderBottomQ2}
            sBorderBottomQ3={sBorderBottomQ3}
            sBorderBottomS1={sBorderBottomS1}
            sBorderBottomS2={sBorderBottomS2}
            sBorderBottomS3={sBorderBottomS3}
            handleChange={handleChangeMarksButtons}
            marksType={marksType}
            systemBasedType={systemBasedType}
            sBorderBottomExamMarks={sBorderBottomExamMarks}
            sBorderBottomHomeworkMarks={sBorderBottomHomeworkMarks}
            sBorderBottomAssignmentMarks={sBorderBottomAssignmentMarks}
            sBorderBottomCoCurricularMarks={sBorderBottomCoCurricularMarks}
            sBorderBottomQuiz1Marks={sBorderBottomQuiz1Marks}
            sBorderBottomQuiz2Marks={sBorderBottomQuiz2Marks}
          />
        </Grid>

      </Grid>
      <DialogAtom
        isOpen={bOpenDialog}
        dialogHeading={t('IMPORT_FROM_SHEET')}
        customClass={classes.DialogAtom}
        content={renderMissingContent()}
        secHandle={() => setOpenDialog(false)}
      />
      <DialogAtom
        isOpen={bOpenImportSheet}
        dialogHeading={t('IMPORT_FROM_SHEET')}
        customClass={classes.DialogAtom}
        content={renderImportTextContent()}
        secHandle={() => setOpenImportSheet(false)}
      />
      <DialogAtom
        isOpen={openDialogErrorGetData}
        dialogHeading={t('DATA_ERROR')}
        customClass={classes.DialogAtom}
        content={renderErrorGetData()}
        secHandle={() => setOpenImportSheet(false)}
      />
      <DialogAtom
        isOpen={openSheet}
        dialogHeading={t('STUDENT_EXPORT')}
        content={renderOkContent()}
        secHandle={() => setOpenSheet(false)}
      />
      <DialogAtom
        isOpen={openSheetExists}
        dialogHeading={t('STUDENT_EXPORT')}
        content={renderSheet()}
        secHandle={() => setOpenSheetExists(false)}
      />
      <DialogAtom
        isOpen={openDialogError}
        dialogHeading={t('TOKEN_EXPIRED_TITLE')}
        content={renderErrorSheet()}
        secHandle={() => setOpenDialogError(false)}
      />
      <DialogAtom
        isOpen={openDialogErrorImport}
        dialogHeading={t('STUDENT_IMPORT')}
        content={renderErrorImportSheet()}
        secHandle={() => setOpenDialogErrorImport(false)}
      />
      <DialogAtom
        isOpen={openDialogErrorMarkImport}
        dialogHeading={t('MAX_MARKS')}
        content={renderErrorImportMarkSheet()}
        secHandle={() => setOpenDialogErrorMarkImport(false)}
      />
    </Box>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import {
  ListItemText, Menu, MenuItem,
  IconButton, Box, Grid,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BookmarksOutlinedIcon from '@mui/icons-material/ImportContactsTwoTone';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { NavigateRoutes } from '../../../../constant';
import styles from './navigation-style';
import useStyles from '../../../../custom-hooks/useStyles';
import FileTextIcon from '../../../../assets/svg/fileText';
import UsersIcon from '../../../../assets/svg/users';
import AdminstrationIcon from '../../../../assets/svg/administration';
import BarChartIcon from '../../../../assets/svg/barChartSVG';
// import AssignmentIconSVG from '../../../../assets/svg/assignment';
import AttendanceIcon from '../../../../assets/svg/attendance';
import { getLocalStorage, setLocalStorage } from '../../../../utils/localStorageMethod';
import userRoles from '../../../../constant/userRoles';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import encryptedUserRoles from '../../../../constant/encryptedUserRoles';
import { setTermsAndConditionSettingDetails, fetchAllPstuFaqs } from '../../../../store/actions/getAdmin';

export default function Header() {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElResource, setAnchorElResource] = React.useState(null);
  const [anchorElSync, setAnchorElSync] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [anchorElAcademicPanel, setAnchorElAcademicPanel] = React.useState(null);

  const open = Boolean(anchorEl);
  const openResource = Boolean(anchorElResource);
  const openSync = Boolean(anchorElSync);
  const openMenu = Boolean(anchorElMenu);
  const openAcademicPanel = Boolean(anchorElAcademicPanel);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const tempUserRoleLocalStorage = getLocalStorage('tempUserRole');
  const tempUserRole = decryptedUserRoles[tempUserRoleLocalStorage];
  const hasCurriculumRole = JSON.parse(getLocalStorage('hasCurriculumRole'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdministrationNavigation = (route) => {
    navigate(route);
    setAnchorEl(null);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickSync = (event) => {
    setAnchorElSync(event.currentTarget);
  };
  const handleSyncClose = () => {
    setAnchorElSync(null);
  };

  const handleSyncNavigation = (user) => {
    const route = user === 'LC_Marks' ? (NavigateRoutes.LC_MARKS_SYNC)
      : user === 'LC_Section' ? (NavigateRoutes.LC_SECTION_SYNC) : user === 'Teacher_Sync' && (NavigateRoutes.TEACHER_SYNC_VIEW);
    navigate(route);
    setAnchorElSync(null);
  };

  const handleClickResources = (event) => {
    setAnchorElResource(event.currentTarget);
  };

  const handleCloseResource = () => {
    setAnchorElResource(null);
  };

  const handleResourceNavigation = (user) => {
    const route = user === 'Student'
      ? ((isAdmin || isTempAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isTempRegionCoordinator || isCurriculum || hasCurriculumRole) ? NavigateRoutes.STUDENTS_RESOURCES
        : isLocationCoordinator || isTempLocationCoordinator ? NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_RESOURCES
          : isTeacher && NavigateRoutes.TEACHER_PANEL_STUDENTS_RESOURCES)
      : ((isAdmin || isTempAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isTempRegionCoordinator || isCurriculum || hasCurriculumRole) ? NavigateRoutes.TEACHERS_RESOURCES
        : isLocationCoordinator || isTempLocationCoordinator ? NavigateRoutes.LOCATION_COORDINATOR_TEACHERS_RESOURCES
          : isTeacher && NavigateRoutes.TEACHER_PANEL_TEACHERS_RESOURCES);

    setAnchorElResource(null);

    if (route === location.pathname) {
      navigate(0);
    } else {
      navigate(route);
    }
  };

  const handleClickAcademicPanel = (event) => {
    setAnchorElAcademicPanel(event.currentTarget);
  };
  const handleCloseAcademicPanel = () => {
    setAnchorElAcademicPanel(null);
  };

  const handleAcademicPanelNavigation = (user) => {
    let route = user === 'Schema'
      ? ((isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum || hasCurriculumRole) && (`${NavigateRoutes.ACADEMIC_PANEL_SCHEMA}`))
      : user === 'Assignments' ? ((isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum || hasCurriculumRole) && (`${NavigateRoutes.HOMEWORK_ASSIGNMENTS}`))
        : user === 'Quiz' ? ((isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum || hasCurriculumRole) && (`${NavigateRoutes.QUIZ_SCHEMA}`))
          : (isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum || hasCurriculumRole) && NavigateRoutes.HOMEWORK_SCHEMA;

    if (user === 'CurricularActivities') {
      route = (isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && NavigateRoutes.CURRICULAR_ACTIVITIES_SCHEMA;
    }

    if (user === 'AssignmentsSchema') {
      route = (isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && NavigateRoutes.ASSIGNMENTS_SCHEMA;
    }

    navigate(route);
    setAnchorElAcademicPanel(null);
  };
  //   const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setTermsAndConditionSettingDetails());
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllPstuFaqs());
  }, []);

  const userEmail = getLocalStorage('userEmailId');
  const getGoogleClassroom = `https://accounts.google.com/ServiceLogin?service=mail&passive=${true}&Email=${userEmail}&continue=https://classroom.google.com/`;

  const handlePanelChange = (role, tempRole) => {
    if (tempRole === encryptedUserRoles.SYSTEM_ADMIN) {
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.ADMIN_DASHBOARD);
    } else if (tempRole === encryptedUserRoles.SUPER_USER) {
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.ADMIN_DASHBOARD);
    } else if (tempRole === encryptedUserRoles.CURRICULUM) {
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.STUDENTS_RESOURCES);
    } else if (tempRole === encryptedUserRoles.REGION_COORDINATOR) {
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.ADMIN_DASHBOARD);
    } else if (tempRole === encryptedUserRoles.LOCATION_COORDINATOR) {
      setLocalStorage('isImpersonateLC', false);
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD);
    } else if (tempRole === encryptedUserRoles.TEACHER) {
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.TEACHER_DASHBOARD);
    } else if (tempRole === encryptedUserRoles.STUDENT) {
      setLocalStorage('userRole', tempRole);
      setLocalStorage('tempUserRole', role);
      navigate(NavigateRoutes.STUDENT_DASHBOARD);
    }
    setAnchorElMenu(null);
  };

  const isAdmin = (userRole === userRoles.SYSTEM_ADMIN);
  const isTempAdmin = (tempUserRole === userRoles.SYSTEM_ADMIN);

  const isSuperUser = (userRole === userRoles.SUPER_USER);
  const isTempSuperUser = (tempUserRole === userRoles.SUPER_USER);

  // const isMenuAccess = (tempUserRole === userRoles.SYSTEM_ADMIN || tempUserRole === userRoles.REGION_COORDINATOR);
  const isRegionCoordinator = (userRole === userRoles.REGION_COORDINATOR);
  const isTempRegionCoordinator = (tempUserRole === userRoles.REGION_COORDINATOR);

  const isLocationCoordinator = (userRole === userRoles.LOCATION_COORDINATOR);
  const isTempLocationCoordinator = (tempUserRole === userRoles.LOCATION_COORDINATOR);

  const isCurriculum = (userRole === userRoles.CURRICULUM);
  const isTempCurriculum = (tempUserRole === userRoles.CURRICULUM);

  const isTeacher = (userRole === userRoles.TEACHER);
  const tokenAlertRedirection = () => {
    useEffect(() => {
      // eslint-disable-next-line no-alert
      const timer = setTimeout(() => alert('You are not logged in, Please login to continue'), window.location.assign('/'), 2000);
      return () => clearTimeout(timer);
    }, []);
  };
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Grid container flexDirection="row" display="flex" className={classes.divWrapper}>
        <Grid item className={classes.HeaderWrapper}>
          <Box>
            <div>
              {localStorage.getItem('token')
                ? (
                  <IconButton
                    className={classes.iconsHoverHeader}
                    onClick={handleClickMenu}
                    aria-owns={anchorElMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                  >
                    <MenuIcon className={classes.menuIcon} />
                  </IconButton>
                ) : tokenAlertRedirection()}
              <Menu
                id="demo-customized-menu"
                anchorEl={anchorElMenu}
                open={openMenu}
                onClose={handleCloseMenu}
                // onMouseLeave={handleCloseMenu}
                className={classes.mainMenuItemLink}
              >
                {((isAdmin || isTempAdmin) && !isCurriculum) && (
                  <MenuItem onClick={() => handlePanelChange(tempUserRoleLocalStorage, encryptedUserRoles.SYSTEM_ADMIN)}>
                    <span className={classes.menuItem}>
                      <SecurityOutlinedIcon />
                      {t('ADMIN_PANEL')}

                    </span>
                  </MenuItem>
                )}

                {(isSuperUser || isTempSuperUser) && (
                  <MenuItem onClick={() => handlePanelChange(tempUserRoleLocalStorage, encryptedUserRoles.SUPER_USER)}>
                    <span className={classes.menuItem}>
                      <SecurityOutlinedIcon />
                      {t('ADMIN_PANEL')}
                    </span>
                  </MenuItem>
                )}

                {(isCurriculum || isTempCurriculum) && (
                  <MenuItem onClick={() => handlePanelChange(tempUserRoleLocalStorage, encryptedUserRoles.CURRICULUM)}>
                    <span className={classes.menuItem}>
                      <SecurityOutlinedIcon />
                      {t('ADMIN_PANEL')}
                    </span>
                  </MenuItem>
                )}

                {(isRegionCoordinator || isTempRegionCoordinator) && (
                  <MenuItem onClick={() => handlePanelChange(tempUserRoleLocalStorage, encryptedUserRoles.REGION_COORDINATOR)}>
                    <span className={classes.menuItem}>
                      <SecurityOutlinedIcon />
                      {t('ADMIN_PANEL')}
                    </span>
                  </MenuItem>
                )}

                {(isAdmin || isTempAdmin || isRegionCoordinator || isLocationCoordinator || isTempLocationCoordinator
                  || isTempRegionCoordinator || isSuperUser || isTempSuperUser) && (
                    <MenuItem onClick={() => handlePanelChange(tempUserRoleLocalStorage, encryptedUserRoles.LOCATION_COORDINATOR)}>
                      <span className={classes.menuItem}>
                        <LocationOnOutlinedIcon />
                        {t('LOCATION_PANEL')}
                      </span>
                    </MenuItem>
                )}

                {(isAdmin || isTempAdmin || isRegionCoordinator || isLocationCoordinator || isTeacher
                  || isSuperUser || isTempSuperUser) && (
                    <MenuItem onClick={() => handlePanelChange(tempUserRoleLocalStorage, encryptedUserRoles.TEACHER)}>
                      <span className={classes.menuItem}>
                        <SchoolOutlinedIcon />
                        {t('TEACHER_PANEL')}
                      </span>
                    </MenuItem>
                )}
              </Menu>
            </div>
          </Box>
          <Stack direction="row" spacing={1} className={classes.menuWrapper}>

            {(isAdmin || isSuperUser || isRegionCoordinator || isLocationCoordinator) && (
              <>
                <Link to={(isAdmin || isSuperUser || isRegionCoordinator) ? NavigateRoutes.ADMIN_DASHBOARD : NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${location.pathname === NavigateRoutes.ADMIN_DASHBOARD ? classes.buttonActive : location.pathname === NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD ? classes.buttonActive : ''}`}
                  >
                    <BarChartIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('DASHBOARD')} className={`${classes.menuItem} ${(location.pathname === (NavigateRoutes.ADMIN_DASHBOARD || NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD)) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>

                <Link to={(isAdmin || isSuperUser || isRegionCoordinator) ? NavigateRoutes.STUDENTS_VIEW : NavigateRoutes.LC_STUDENTS} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${location.pathname === NavigateRoutes.STUDENTS_VIEW ? classes.buttonActive : location.pathname === NavigateRoutes.LC_STUDENTS ? classes.buttonActive : ''}`}
                  >
                    <ContactPageOutlinedIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('STUDENTS')} className={`${classes.menuItem} ${(location.pathname === (NavigateRoutes.STUDENTS_VIEW || NavigateRoutes.LC_STUDENTS)) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>
              </>
            )}

            {isTeacher && (
              <>
                <Link to={NavigateRoutes.TEACHER_DASHBOARD} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === (NavigateRoutes.TEACHER_DASHBOARD)) ? classes.buttonActive : ''}`}
                  >
                    <BarChartIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('DASHBOARD')} className={`${classes.menuItem} ${(location.pathname === (NavigateRoutes.TEACHER_DASHBOARD)) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>
                <Link to={NavigateRoutes.MY_CLASS} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.MY_CLASS) ? classes.buttonActive : ''}`}
                  >
                    <UsersIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('MY_CLASS')} className={`${classes.menuItem} ${(location.pathname === NavigateRoutes.MY_CLASS) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>
              </>
            )}

            {(isLocationCoordinator) && (
              <>
                <Link
                  to={NavigateRoutes.LC_CLASSES}
                  className={classes.menuLink}
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.LC_CLASSES) ? classes.buttonActive : ''}`}
                  >
                    <UsersIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('CLASSES')} className={`${classes.menuItem} ${(location.pathname === NavigateRoutes.LC_CLASSES) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>
                <Link
                  to={NavigateRoutes.LC_ANNOUNCEMENTS}
                  className={classes.menuLink}
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.LC_ANNOUNCEMENTS) ? classes.buttonActive : ''}`}
                  >
                    <ChatBubbleOutlineRoundedIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('ANNOUNCEMENTS')} className={`${classes.menuItem} ${(location.pathname === NavigateRoutes.LC_ANNOUNCEMENTS) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>
              </>
            )}

            {(isAdmin || isSuperUser || isRegionCoordinator) && (
              <div>
                <Link
                  to={NavigateRoutes.RC_ANNOUNCEMENTS}
                  className={classes.menuLink}
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.RC_ANNOUNCEMENTS) ? classes.buttonActive : ''}`}
                  >
                    <ChatBubbleOutlineRoundedIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('ANNOUNCEMENTS')} className={`${classes.menuItem} ${(location.pathname === NavigateRoutes.RC_ANNOUNCEMENTS) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>
                <IconButton
                  className={`${classes.iconsHoverHeader} ${classes.adminIcon}
                ${(location.pathname === NavigateRoutes.USER_MANAGER_VIEW
                      || location.pathname === NavigateRoutes.ROLE_MANAGER_VIEW
                      || location.pathname === NavigateRoutes.REGION_MANAGER_VIEW) ? classes.buttonActive : ''}`}
                  aria-owns={anchorEl ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <AdminstrationIcon className={classes.menuItemIcon} />
                  <ListItemText primary={t('ADMINISTRATION')} className={classes.menuItem} />
                  <KeyboardArrowDownIcon className={classes.keyDownArrow} />
                </IconButton>
                <Menu
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onMouseLeave={handleClose}
                  className={classes.menuItemLink}
                >
                  <MenuItem onClick={() => handleAdministrationNavigation(NavigateRoutes.USER_MANAGER_VIEW)}>
                    <Link to={NavigateRoutes.USER_MANAGER_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                      <ListItemText primary={t('USERMANAGER')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  {(isAdmin) && (
                    <>
                      <MenuItem onClick={() => handleAdministrationNavigation(NavigateRoutes.ROLE_MANAGER_VIEW)}>
                        <Link to={NavigateRoutes.ROLE_MANAGER_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                          <ListItemText primary={t('ROLE_MANAGER')} className={classes.menuItem} />
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={() => handleAdministrationNavigation(NavigateRoutes.REGION_MANAGER_VIEW)}>
                        <Link to={NavigateRoutes.REGION_MANAGER_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                          <ListItemText primary={t('REGION_MANAGER')} className={classes.menuItem} />
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={() => handleAdministrationNavigation(NavigateRoutes.LOCATION_MANAGER_VIEW)}>
                        <Link to={NavigateRoutes.LOCATION_MANAGER_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                          <ListItemText primary={t('LOCATION_MANAGER')} className={classes.menuItem} />
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={() => handleAdministrationNavigation(NavigateRoutes.COURSE_MANAGER_VIEW)}>
                        <Link to={NavigateRoutes.COURSE_MANAGER_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                          <ListItemText primary={t('COURSE_MANAGER')} className={classes.menuItem} />
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={() => handleAdministrationNavigation(NavigateRoutes.EXAM_CENTER_MANAGER_VIEW)}>
                        <Link to={NavigateRoutes.EXAM_CENTER_MANAGER_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                          <ListItemText primary={t('EXAM_CENTER_MANAGER')} className={classes.menuItem} />
                        </Link>
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </div>
            )}
            {(isAdmin || isSuperUser || isRegionCoordinator || isLocationCoordinator || isCurriculum
              || isTeacher)
              && (
                <div>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.STUDENTS_RESOURCES) ? classes.buttonActive : ''} ${(location.pathname === NavigateRoutes.TEACHERS_RESOURCES) ? classes.buttonActive : ''}`}
                    aria-owns={anchorEl ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClickResources}

                  >
                    <FileTextIcon className={classes.menuItemIcon} />
                    <ListItemText primary={t('RESOURCES')} className={classes.menuItem} />
                    <KeyboardArrowDownIcon className={classes.keyDownArrow} />
                  </IconButton>
                  <Menu
                    id="demo-customized-menu"
                    anchorEl={anchorElResource}
                    open={openResource}
                    onClose={handleCloseResource}
                    onMouseLeave={handleCloseResource}
                    className={classes.menuItemLink}
                  >
                    <MenuItem onClick={() => handleResourceNavigation('Student')}>
                      <Link
                        to={
                          // eslint-disable-next-line no-nested-ternary
                          (isAdmin || isTempAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isTempRegionCoordinator || isCurriculum) ? NavigateRoutes.STUDENTS_RESOURCES
                            : isLocationCoordinator || isTempLocationCoordinator ? NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_RESOURCES
                              : isTeacher && NavigateRoutes.TEACHER_PANEL_STUDENTS_RESOURCES
                        }
                        className={classes.menuLink}
                        style={{ textDecoration: 'none' }}
                      >
                        <ListItemText primary={t('STUDENT_RESOURCES')} className={classes.menuItem} />
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={() => handleResourceNavigation('Teacher')}>
                      <Link
                        to={
                          // eslint-disable-next-line no-nested-ternary
                          (isAdmin || isTempAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isTempRegionCoordinator || isCurriculum) ? NavigateRoutes.TEACHERS_RESOURCES
                            : isLocationCoordinator || isTempLocationCoordinator ? NavigateRoutes.LOCATION_COORDINATOR_TEACHERS_RESOURCES
                              : isTeacher && NavigateRoutes.TEACHER_PANEL_TEACHERS_RESOURCES
                        }
                        className={classes.menuLink}
                        style={{ textDecoration: 'none' }}
                      >
                        <ListItemText primary={t('TEACHER_RESOURCES')} className={classes.menuItem} />
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
              )}
            {(isAdmin || isCurriculum || hasCurriculumRole) && (
              <div>
                <IconButton
                  className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.ACADEMIC_PANEL)
                    ? classes.buttonActive
                    : ''} ${(location.pathname === NavigateRoutes.HOMEWORK_SCHEMA)
                    ? classes.buttonActive
                    : ''} ${(location.pathname === NavigateRoutes.HOMEWORK_ASSIGNMENTS)
                    ? classes.buttonActive
                    : ''} ${(location.pathname === NavigateRoutes.ACADEMIC_PANEL_SCHEMA)
                    ? classes.buttonActive
                    : ''} ${(location.pathname === NavigateRoutes.QUIZ_SCHEMA)
                    ? classes.buttonActive
                    : ''} ${(location.pathname === `${NavigateRoutes.ACADEMIC_PANEL}/`)
                    ? classes.buttonActive
                    : ''}
                    `}
                  onClick={handleClickAcademicPanel}
                >
                  <FileTextIcon className={classes.menuItemIcon} />
                  <ListItemText primary={t('ACADEMIC_PANEL')} className={classes.menuItem} />
                  <KeyboardArrowDownIcon className={classes.keyDownArrow} />
                </IconButton>
                <Menu
                  id="demo-customized-menu"
                  anchorEl={anchorElAcademicPanel}
                  open={openAcademicPanel}
                  onClose={handleCloseAcademicPanel}
                  onMouseLeave={handleCloseAcademicPanel}
                  className={classes.menuItemLink}
                >
                  <MenuItem onClick={() => handleAcademicPanelNavigation('Schema')}>
                    <Link
                      to={(isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && (`${NavigateRoutes.ACADEMIC_PANEL_SCHEMA}`)}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('ACADEMIC_PANEL_SCHEMA')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleAcademicPanelNavigation('Assignments')}>
                    <Link
                      to={(isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && (`${NavigateRoutes.HOMEWORK_ASSIGNMENTS}`)}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('HOMEWORK_ASSIGNMENTS')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleAcademicPanelNavigation('AssignmentsSchema')}>
                    <Link
                      to={(isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && (`${NavigateRoutes.HOMEWORK_ASSIGNMENTS}`)}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('ASSIGNMENTS_SCHEMA')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleAcademicPanelNavigation('Homework')}>
                    <Link
                      to={(isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && NavigateRoutes.HOMEWORK_SCHEMA}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('HOMEWORK_SCHEMA')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleAcademicPanelNavigation('Quiz')}>
                    <Link
                      to={(isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && NavigateRoutes.QUIZ_SCHEMA}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('QUIZ_SCHEMA')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => handleAcademicPanelNavigation('CurricularActivities')}>
                    <Link
                      to={(isAdmin || isSuperUser || isTempSuperUser || isRegionCoordinator || isCurriculum) && NavigateRoutes.CURRICULAR_ACTIVITIES_SCHEMA}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('CURRICULAR_ACTIVITIES_SCHEMA')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                </Menu>
              </div>
            )}

            {(isAdmin) && (
              <Link to={NavigateRoutes.EXPORTED_SHEET} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                <IconButton
                  className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.EXPORTED_SHEET) ? classes.buttonActive : ''}`}
                >
                  <AttendanceIcon className={classes.menuItemIcon} />
                  {' '}
                  <ListItemText primary={t('EXPORTED_SHEET')} className={`${classes.menuItem} ${(location.pathname === NavigateRoutes.EXPORTED_SHEET) ? classes.buttonActive : ''}`} />
                </IconButton>
              </Link>
            )}

            {isTeacher && (
              <>
                <Link to={NavigateRoutes.TEACHER_ATTECNDANCE_VIEW} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.TEACHER_ATTECNDANCE_VIEW) ? classes.buttonActive : ''}`}
                  >
                    <AttendanceIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('ATTENDANCE')} className={`${classes.menuItem} ${(location.pathname === NavigateRoutes.TEACHER_ATTECNDANCE_VIEW) ? classes.buttonActive : ''}`} />
                  </IconButton>
                </Link>

                <a href={getGoogleClassroom} target="_blank" className={classes.menuLink} style={{ textDecoration: 'none' }} rel="noreferrer">
                  <IconButton
                    className={`${classes.iconsHoverHeader} ${(location.pathname === '') ? classes.buttonActive : ''}`}
                  >
                    <BookmarksOutlinedIcon className={classes.menuItemIcon} />
                    {' '}
                    <ListItemText primary={t('CLASSROOM')} className={`${classes.menuItem} ${(location.pathname === '') ? classes.buttonActive : ''}`} />
                  </IconButton>
                </a>
              </>
            )}

            {(isLocationCoordinator) && (
              <div>
                <IconButton
                  className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.LC_MARKS_SYNC) ? classes.buttonActive : ''} ${(location.pathname === NavigateRoutes.LC_SECTION_SYNC) ? classes.buttonActive : ''}`}
                  aria-owns={anchorEl ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleClickSync}
                >
                  <FileTextIcon className={classes.menuItemIcon} />
                  <ListItemText primary={t('SYNC')} className={classes.menuItem} />
                  <KeyboardArrowDownIcon className={classes.keyDownArrow} />
                </IconButton>
                <Menu
                  id="demo-customized-menu"
                  anchorEl={anchorElSync}
                  open={openSync}
                  onClose={handleSyncClose}
                  onMouseLeave={handleSyncClose}
                  className={classes.menuItemLink}
                >
                  <MenuItem onClick={() => { handleSyncNavigation('LC_Marks'); }}>
                    <Link to={NavigateRoutes.LC_MARKS_SYNC} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                      <ListItemText primary={t('MARKS_SYNC')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={() => { handleSyncNavigation('LC_Section'); }}>
                    <Link to={NavigateRoutes.LC_SECTION_SYNC} className={classes.menuLink} style={{ textDecoration: 'none' }}>
                      <ListItemText primary={t('SECTION_SYNC')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                </Menu>
              </div>
            )}

            {isTeacher && (
              <div>
                <IconButton
                  className={`${classes.iconsHoverHeader} ${(location.pathname === NavigateRoutes.TEACHER_SYNC_VIEW) ? classes.buttonActive : ''} ${(location.pathname === '') ? classes.buttonActive : ''}`}
                  aria-owns={anchorEl ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleClickSync}

                >
                  <FileTextIcon className={classes.menuItemIcon} />
                  <Link
                    to={NavigateRoutes.TEACHER_SYNC_VIEW}
                    className={classes.menuLink}
                    style={{ textDecoration: 'none' }}
                  >
                    <ListItemText primary={t('SYNC_ONE')} className={classes.menuItem} />
                  </Link>
                  {/* <KeyboardArrowDownIcon className={classes.keyDownArrow} /> */}
                </IconButton>
                {/* <Menu
                  id="demo-customized-menu"
                  anchorEl={anchorElSync}
                  open={openSync}
                  onClose={handleSyncClose}
                  onMouseLeave={handleSyncClose}
                  className={classes.menuItemLink}
                >
                  <MenuItem onClick={() => { handleSyncNavigation('Teacher_Sync'); }}>
                    <Link
                      to={NavigateRoutes.TEACHER_SYNC_VIEW}
                      className={classes.menuLink}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItemText primary={t('SYNC_ONE')} className={classes.menuItem} />
                    </Link>
                  </MenuItem>
                </Menu> */}
              </div>
            )}

          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

/* eslint-disable arrow-spacing */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import moment from 'moment';
import { setLocalStorage } from '../../utils/localStorageMethod';
import Constant from '../constant/index';
import {
  addTeacherService,
  getAllTeachersService,
  getAssignedLocationsService,
  getClassroomsService,
  createSectionsByLocationYearCourseService,
  getAssignedYearsDetailsService,
  getStudentsByLocationService,
  getSectionDetailsService,
  updateMarksService,
  updateHomeworkMarksService,
  getCourseLocationService,
  getLocationDetailsService,
  getTeachersService,
  getAcademicYearDatesByYearService,
  getAcademicYearDatesService,
  academicYearDatesService,
  getImportSheetTime,
  getExportSheetTime,
  createMarksService,
  createHomeworkMarksService,
  getAcademicYearsInfoService,
  getAcademicYearsService,
  getAcademicYearsServiceStatus,
  postAcademicYearsServiceStatus,
  saveBonusMarksService,
  deleteGoogleSheetbyIdService,
} from '../services/auth';

export const setSelectedYear = (payload) => (dispatch) => {
  dispatch({
    type: Constant.SET_SELECTED_YEAR,
    payload,
  });
};

export const setSelectedLocation = (payload, loadFalse) => (dispatch) => {
  dispatch({
    type: Constant.SET_SELECTED_LOCATION,
    payload,
  });
  if (loadFalse) loadFalse();
};

export const setSelectedCourse = (payload) => (dispatch) => {
  dispatch({
    type: Constant.SET_SELECTED_COURSE,
    payload,
  });
};

export const addTeacher = (payload, refreshList, setCleanLog = false) => async () => {
  try {
    const res = await addTeacherService(payload);
    refreshList();
    if (setCleanLog) {
      setCleanLog(true);
    }
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
export const getClassrooms = (payload, loadRefreshData) => (dispatch) => {
  getClassroomsService(payload)
    .then((response) => {
      dispatch({
        type: Constant.GET_CLASSROOMS,
        payload: response?.data,
      });
      loadRefreshData && loadRefreshData();
    })
    .catch((error) => {
      loadRefreshData && loadRefreshData();
      console.log('error :', error);
    });
};
export const createSectionsByLocationYearCourse = (payload, setLoading) => async (dispatch) => {
  try {
    const res = await createSectionsByLocationYearCourseService(payload);
    setLocalStorage('ADD_CLASSROOMS_SECTIONS', JSON.stringify(res?.data));
    dispatch({
      type: Constant.ADD_CLASSROOMS_SECTIONS,
      payload: res?.data,
    });
    setLoading(false);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteGoogleSheetbyId = (payload) => () => {
  deleteGoogleSheetbyIdService(payload)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getLocationDetails = (payload) => (dispatch) => {
  getLocationDetailsService(payload)
    .then((response) => {
      const { data } = response;
      const locationDetails = {
        locationName: `${data.locationData.name}`,
        locationAddress: `${data.locationData.locationAddress.address}`,
        teacher: `${data.teachers.length}`,
        scheduledHours: `${moment(data.locationData.start_time, 'hh:mm').format('LT')} - ${moment(data.locationData.end_time, 'hh:mm').format('LT')}`,
        scheduledDay: `${data.locationData.class_timing}`,
        coordinatorName: `${data.primaryCoordinatorData[0].user.first_name} ${data.primaryCoordinatorData[0].user.last_name}`,
        coordinatorEmail: `${data.primaryCoordinatorData[0].user.manabadi_email}`,
        coordinatorPhoneNo: `${data.primaryCoordinatorData[0].user.contact_number}`,
      };
      dispatch({
        type: Constant.GET_LOCATION_DETAILS,
        payload: locationDetails,
      });
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAcademicYears = (setLoading) => (dispatch) => {
  getAcademicYearsInfoService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ACADEMIC_YEARS,
        payload: response.data,
      });
      setLoading && setLoading();
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAllAcademicYears = (setLoading) => (dispatch) => {
  getAcademicYearsService()
    .then((response) => {
      dispatch({
        type: Constant.GET_ACADEMIC_YEARS,
        payload: response.data,
      });
      setLoading && setLoading();
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAllAcademicYearsStatus = (setLoading) => (dispatch) => {
  getAcademicYearsServiceStatus()
    .then((response) => {
      const years = response.data.map((obj) => {
        return (
          obj.academicYear
        );
      });
      const result = response.data.filter((obj) => obj.isCurrentYear === true);
      setLocalStorage('academicYear', result[0].academicYear);
      dispatch({
        type: Constant.GET_ACADEMIC_YEARS,
        payload: years,
      });
      dispatch({
        type: Constant.GET_ACADEMIC_YEARS_STATUS,
        payload: response.data,
      });
      setLoading && setLoading();
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const postAcademicYearsStatus = (payload, setLoading, setRefreshPage) => (dispatch) => {
  postAcademicYearsServiceStatus(payload)
    .then((response) => {
      dispatch({
        type: Constant.POST_ACADEMIC_YEARS_STATUS,
        payload: response.data,
      });
      localStorage.setItem('currentAcademicYear', response?.data?.academicYear);
      setLoading && setLoading();
      setRefreshPage(true);
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAssignedYearsDetails = (body, setLoading, setCleanLog = false) => (dispatch) => {
  if (!body?.academicYear) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  body.academicYear = body?.academicYear.replace(/\s/g, '');
  getAssignedYearsDetailsService(body)
    .then((response) => {
      dispatch({
        type: Constant.GET_ASSIGNED_YEARS_DETAILS,
        payload: response.data,
      });
      setLoading();
      if (setCleanLog) {
        setCleanLog(true);
      }
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAssignedLocations = (setLoading) => (dispatch) => {
  getAssignedLocationsService()
    .then((response) => {
      const locationData = response?.data?.locationData?.map((i) => ({
        id: i?.location_id,
        name: i?.location?.isActive ? `${i?.location?.name} - ${i?.location?.locationAddress?.address}`
          : `(InActive) - ${i?.location?.name} - ${i?.location?.locationAddress?.address}`,
        shortName: i?.location?.name,
        isActive: i?.location?.isActive,
      }));
      const yearData = response?.data?.yearData?.map((i) => ({
        id: i?.academic_year,
        name: i?.academic_year,
      }));
      const getVal = (str) => str.split('-')[0];
      const mainYearData = yearData.sort((a, b) => getVal(b?.name) - getVal(a?.name));
      dispatch({
        type: Constant.GET_ASSIGNED_LOCATIONS,
        payload: locationData,
      });
      dispatch({
        type: Constant.GET_ASSIGNED_YEARS,
        payload: mainYearData,
      });
      setLoading && setLoading();
    })
    .catch((error) => {
      setLoading && setLoading();
      console.log('error :', error);
    });
};

export const getAllTeachers = (setLoading) => (dispatch) => {
  getAllTeachersService()
    .then((res) => {
      const teachersData = res?.data?.users?.map((i) => ({
        key: i?.id,
        id: i?.id,
        value: `${i?.firstName} ${i?.lastName} `,
        email: i?.manabadiEmail,
        checked: false,
      }));
      dispatch({
        type: Constant.GET_ALL_TEACHERS,
        payload: teachersData,
      });

      setLoading && setLoading();
    })
    .catch((error) => {
      setLoading && setLoading();
      console.log('error :', error);
    });
};

export const getStudentsByLocation = (body, setLoading, refreshList) => (dispatch) => {
  getStudentsByLocationService(body)
    .then((response) => {
      dispatch({
        type: Constant.GET_STUDENTS_BY_LOCATION,
        payload: response.data,
      });
      setLoading(false);
    }).catch((error) => {
      if (error.readyState === 4 && error.status === 0) {
        setTimeout(() => {
          refreshList(body);
        }, 2000);
      } else {
        setLoading(false);
      }
      console.log('error :', error);
    });
};

export const getSectionDetails = (payload, setLoading, formikValues) => (dispatch) => {
  setLoading && setLoading(true);
  getSectionDetailsService(payload)
    .then((res) => {
      dispatch({
        type: Constant.SET_SECTION_DETAILS,
        payload: res.data,
      });
      if (formikValues?.year && formikValues?.locationId && formikValues?.courseId) {
        setLoading && setLoading(false);
        const importPayload = {
          syncType: 'section',
          type: 'import',
          locationId: formikValues?.locationId,
          academicYear: formikValues?.year,
          courseId: formikValues?.courseId,
        };
        getImportSheetTime(importPayload).then((importres) => {
          if (importres?.data?.length) {
            const importDate = moment(importres?.data[0]?.sync_time).format('MM-DD-YYYY HH:mm:ss');
            setLoading && setLoading(false);
            dispatch({
              type: Constant.SET_IMPORT_TIME,
              payload: moment(importDate).fromNow(),
            });
            dispatch({
              type: Constant.SET_CREATED_SHEET_TIME,
              payload: importDate,
            });
          } else {
            dispatch({
              type: Constant.SET_IMPORT_TIME,
              payload: false,
            });
            dispatch({
              type: Constant.SET_CREATED_SHEET_TIME,
              payload: '',
            });
          }
        }).catch((err) => {
          setLoading && setLoading(false);
          console.log(err);
        });

        const exportPayload = {
          syncType: 'section',
          type: 'export',
          locationId: formikValues?.locationId,
          academicYear: formikValues?.year,
          courseId: formikValues?.courseId,
        };
        getExportSheetTime(exportPayload).then((exportResponse) => {
          if (exportResponse?.data?.length) {
            const exportDate = moment(exportResponse?.data[0]?.sync_time).format('MM-DD-YYYY HH:mm:ss');
            dispatch({
              type: Constant.SET_EXPORT_TIME,
              payload: moment(exportDate).fromNow(),
            });
            dispatch({
              type: Constant.SET_EXPORTED_SHEET_TIME,
              payload: exportDate,
            });
          } else {
            dispatch({
              type: Constant.SET_EXPORT_TIME,
              payload: false,
            });
            dispatch({
              type: Constant.SET_EXPORTED_SHEET_TIME,
              payload: '',
            });
          }
        }).catch((err) => {
          console.log(err);
        });
      }
    }).catch((error) => {
      setLoading && setLoading(false);
      console.log('error :', error);
    });
};

export const getMarksDetails = (detailes, setOpenDialogErrorImport, setDialogErrorImportMessage) => (dispatch) => {
  if (detailes?.academicYear && detailes?.locationId && detailes?.courseId) {
    const importPayload = {
      syncType: 'marks',
      type: 'import',
      locationId: detailes?.locationId,
      academicYear: detailes?.academicYear,
      courseId: detailes?.courseId,
    };

    getImportSheetTime(importPayload).then((importResponse) => {
      if (importResponse?.data?.length) {
        const importDate = moment(importResponse?.data[0]?.sync_time).format('MM-DD-YYYY HH:mm:ss');
        dispatch({
          type: Constant.SET_IMPORT_TIME,
          payload: moment(importDate).fromNow(),
        });
        dispatch({
          type: Constant.SET_CREATED_SHEET_TIME,
          payload: importDate,
        });
      } else {
        dispatch({
          type: Constant.SET_IMPORT_TIME,
          payload: false,
        });
        dispatch({
          type: Constant.SET_CREATED_SHEET_TIME,
          payload: false,
        });
      }
    }).catch((err) => {
      if (err.permissions) {
        setOpenDialogErrorImport(true);
        setDialogErrorImportMessage(`${err.message} needed permissions ${err.permissions}`);
      }
      console.log(err);
    });

    const exportPayload = {
      syncType: 'marks',
      type: 'export',
      locationId: detailes?.locationId,
      academicYear: detailes?.academicYear,
      courseId: detailes?.courseId,
    };

    getExportSheetTime(exportPayload).then((exportResponse) => {
      if (exportResponse?.data?.length) {
        const exportDate = moment(exportResponse?.data[0]?.sync_time).format('MM-DD-YYYY HH:mm:ss');
        dispatch({
          type: Constant.SET_EXPORT_TIME,
          payload: moment(exportDate).fromNow(),
        });
        dispatch({
          type: Constant.SET_EXPORTED_SHEET_TIME,
          payload: exportDate,
        });
      } else {
        dispatch({
          type: Constant.SET_EXPORT_TIME,
          payload: false,
        });
        dispatch({
          type: Constant.SET_EXPORTED_SHEET_TIME,
          payload: false,
        });
      }
    }).catch((err) => {
      if (err.permissions) {
        setOpenDialogErrorImport(true);
        setDialogErrorImportMessage(`${err.message} needed permissions ${err.permissions}`);
      }
      console.log(err);
    });
  }
};

export const updateStudentMarks = (payload, refreshList) => {
  const {
    studentId, courseName, quarterName,
    studentDetailMarks,
    academicYear, isPresent, locationId, isAttended,
  } = payload;
  const updateMarksPayload = {
    studentId,
    courseId: courseName,
    academicYear,
    quarter: quarterName,
    studentDetailMarks,
    locationId,
    isPresent,
    isAttended,
  };
  updateMarksService(updateMarksPayload)
    .then(() => {
      refreshList();
    }).catch((error) => {
      console.log('error :', error);
    });
};
export const createStudentMarks = (payload, refreshList) => {
  const {
    studentId, courseName, quarterName,
    studentDetailMarks,
    academicYear, isPresent, locationId, isAttended,
  } = payload;
  const updateMarksPayload = {
    studentId,
    courseId: courseName,
    academicYear,
    quarter: quarterName,
    studentDetailMarks,
    locationId,
    isPresent,
    isAttended,
  };
  createMarksService(updateMarksPayload)
    .then(() => {
      refreshList();
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const updateStudentMarksOld = (payload, refreshList) => async () => {
  try {
    const {
      studentId, courseName, quarterName,
      studentDetailMarks,
      academicYear, isPresent, locationId,
    } = payload;
    const updateMarksPayload = {
      studentId,
      courseId: courseName,
      academicYear,
      quarter: quarterName,
      studentDetailMarks,
      locationId,
      isPresent,
    };
    const res = await updateMarksService(updateMarksPayload);
    if (Promise.resolve(res)) {
      refreshList();
    }
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateStudentHomeworkMarks = (payload, refreshList) => async () => {
  try {
    const {
      studentId, courseName, quarterName,
      academicYear, studentHomeworkMarksDetail, isPresent, isAttended, locationId,
    } = payload;
    const homeworkPayload = {
      studentId,
      academicYear,
      courseId: courseName,
      quarter: quarterName,
      studentHomeworkMarksDetail,
      isPresent,
      isAttended,
      locationId,
    };
    const res = await updateHomeworkMarksService(homeworkPayload);
    if (Promise.resolve(res)) {
      refreshList();
    }
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createStudentHomeworkMarks = (payload, refreshList) => async () => {
  try {
    const {
      studentId, courseName, quarterName,
      academicYear, studentHomeworkMarksDetail, isPresent, isAttended,
    } = payload;
    const homeworkPayload = {
      studentId,
      academicYear,
      courseId: courseName,
      quarter: quarterName,
      studentHomeworkMarksDetail,
      isPresent,
      isAttended,
    };
    const res = await createHomeworkMarksService(homeworkPayload);
    if (Promise.resolve(res)) {
      refreshList();
    }
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTeachers = (body) => (dispatch) => {
  getTeachersService(body)
    .then((response) => {
      dispatch({
        type: Constant.GET_TEACHERS,
        payload: response?.data,
      });
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAllLocationCourse = (body) => async (dispatch) => {
  dispatch({
    type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
    payload: [],
  });
  dispatch({
    type: Constant.GET_ALL_TEACHER_RECOURCES,
    payload: [],
  });

  if (body?.locationId) {
    await getCourseLocationService(body)
      .then((response) => {
        const locationCourseData = response?.data?.map((i) => ({
          id: i?.course?.id,
          name: `${i?.course?.name}`,
        }));
        dispatch({
          type: Constant.GET_ALL_LOCATION_COURSE,
          payload: locationCourseData,
        });
      })
      .catch((error) => {
        console.log('error :', error);
      });
  }
};

export const getAcademicYearDatesByYear = (body, setIsNewAcademicYear) => (dispatch) => {
  getAcademicYearDatesByYearService(body)
    .then((response) => {
      dispatch({
        type: Constant.GET_ACADEMIC_YEAR_DATES_BY_YEAR,
        payload: response?.data,
      });
      localStorage.setItem('currentDobCutoff', moment(response?.data.dobCutoff).format('MM/DD/YYYY'));
      setIsNewAcademicYear(false);
    })
    .catch(() => {
    });
};

export const getAcademicYearDates = (payload, year, reload, setLoading, setSuccessMsg, setCleanLog = false) => (dispatch) => {
  getAcademicYearDatesService(year, payload)
    .then((response) => {
      setLoading(false);
      dispatch({
        type: Constant.GET_ACADEMIC_YEAR_DATES,
        payload: response?.data,
      });
      setSuccessMsg('Academic Dates Saved/Updated Successfully');
      if (setCleanLog) {
        setCleanLog(true);
      }
    })
    .catch(() => {
      setLoading(false);
    });
};

export const academicYearDates = (payload, setLoading) => (dispatch) => {
  academicYearDatesService(payload)
    .then((response) => {
      setLoading && setLoading(false);
      dispatch({
        type: Constant.ACADEMIC_YEAR_DATES,
        payload: response?.data,
      });
    })
    .catch(() => {
      setLoading && setLoading(false);
    });
};

export const saveBonusMarks = async (payload) => {
  await saveBonusMarksService(payload).catch((err) => {
    console.log('err in saving bonus', err);
  });
};

export default {
  getClassrooms,
  createSectionsByLocationYearCourse,
  addTeacher,
  getAssignedLocations,
  getAllTeachers,
  getAcademicYears,
  getAssignedYearsDetails,
  getStudentsByLocation,
  updateStudentMarks,
  getAllLocationCourse,
  updateStudentHomeworkMarks,
  getAcademicYearDates,
  getAcademicYearDatesByYear,
  academicYearDates,
};

/* eslint-disable */
import { calculateAnnualScore, getTotalMarks, isNullOrUndefined } from '../../../utils/methods';
import moment from 'moment';

function Student(courseData) {
  const rows = courseData?.map((course, index) => ({
    ...course,
    key: index,
    id: course?.id,
    profilePhoto: `${course?.studentInfo?.profilePhoto}  ` || '',
    studentName: `${course?.studentInfo?.firstName} ${course?.studentInfo?.lastName}  ` || '',
    age: moment().diff(course?.dateOfBirth, 'years', false),
    gender: `${course?.studentInfo?.gender}` || '',
    parentName: `${course?.parent1Info?.firstName} ${course?.parent1Info?.lastName}  ` || '',
    phoneNumber: course?.parent1Info?.contactNumber || '',
    tShirtSize: course?.enrolled_courses[0]?.tShirtSize || '',
    course: course?.enrolled_courses?.map((i) => `${i?.course?.name}`).join(','),
    section: course?.enrolled_courses[0]?.google_class?.section || '',

    homeworkQ1: course?.homeworkMarks?.Q1?.obtainedMarks || 0,
    homeworkQ2: course?.homeworkMarks?.Q2?.obtainedMarks || 0,
    homeworkQ3: course?.homeworkMarks?.Q3?.obtainedMarks || 0,
    homeworkS1: course?.homeworkMarks?.S1?.calculatedWeightage || 0,
    homeworkS2: course?.homeworkMarks?.S2?.calculatedWeightage || 0,
    Q1HomeWorkWeightage: course?.homeworkMarks?.Q1?.weightage,
    Q2HomeWorkWeightage: course?.homeworkMarks?.Q2?.weightage,
    Q3HomeWorkWeightage: course?.homeworkMarks?.Q3?.weightage,
    S1HomeWorkWeightage: course?.homeworkMarks?.S1?.weightage,
    S2HomeWorkWeightage: course?.homeworkMarks?.S2?.weightage,
    S1HomeworkMarks: (course?.homeworkMarks?.S1?.weightage / 100) * course?.homeworkMarks?.S1?.calculatedWeightage,
    S2HomeworkMarks: (course?.homeworkMarks?.S2?.weightage / 100) * course?.homeworkMarks?.S2?.calculatedWeightage,

    marksQ1: course?.marks?.Q1?.obtainedMarks || 0,
    marksQ2: course?.marks?.Q2?.obtainedMarks || 0,
    marksQ3: course?.marks?.Q3?.obtainedMarks || 0,
    marksS1: course?.marks?.S1?.calculatedWeightage || 0,
    marksS2: course?.marks?.S2?.calculatedWeightage || 0,
    marksS3: course?.marks?.S3?.calculatedWeightage || 0,
    Q1PassingCriteria: course?.marks?.Q1?.passingCriteria,
    Q2PassingCriteria: course?.marks?.Q2?.passingCriteria,
    Q3PassingCriteria: course?.marks?.Q3?.passingCriteria,
    S1PassingCriteria: course?.marks?.S1?.passingCriteria,
    S2PassingCriteria: course?.marks?.S2?.passingCriteria,
    S3PassingCriteria: course?.marks?.S3?.passingCriteria,
    S1Marks: (course?.marks?.S1?.weightage / 100) * course?.marks?.S1?.calculatedWeightage,
    S2Marks: (course?.marks?.S2?.weightage / 100) * course?.marks?.S2?.calculatedWeightage,

    Q1Attended: course?.marks?.Q1?.isAttended,
    Q2Attended: course?.marks?.Q2?.isAttended,
    Q3Attended: course?.marks?.Q3?.isAttended,
    S1Attended: course?.marks?.S1?.isAttended,
    S2Attended: course?.marks?.S2?.isAttended,
    S3Attended: course?.marks?.S3?.isAttended,

    quizS1Q1: course?.quizMarks?.['S1Quiz 1']?.calculatedWeightage || 0,
    quizS1Q2: course?.quizMarks?.['S1Quiz 2']?.calculatedWeightage || 0,
    S1Q1QuizWeightage: course?.quizMarks?.['S1Quiz 1']?.weightage,
    S1Q2QuizWeightage: course?.quizMarks?.['S1Quiz 2']?.weightage,
    S1QuizMarks: (((course?.quizMarks?.['S1Quiz 1']?.weightage / 100) * course?.quizMarks?.['S1Quiz 1']?.calculatedWeightage || 0)
      + ((course?.quizMarks?.['S1Quiz 2']?.weightage / 100) * course?.quizMarks?.['S1Quiz 2']?.calculatedWeightage || 0)),

    quizS2Q1: course?.quizMarks?.['S2Quiz 1']?.calculatedWeightage || 0,
    quizS2Q2: course?.quizMarks?.['S2Quiz 2']?.calculatedWeightage || 0,
    S2Q1QuizWeightage: course?.quizMarks?.['S2Quiz 1']?.weightage,
    S2Q2QuizWeightage: course?.quizMarks?.['S2Quiz 2']?.weightage,
    S2QuizMarks: (((course?.quizMarks?.['S2Quiz 1']?.weightage / 100) * course?.quizMarks?.['S2Quiz 1']?.calculatedWeightage || 0)
      + ((course?.quizMarks?.['S2Quiz 2']?.weightage / 100) * course?.quizMarks?.['S2Quiz 2']?.calculatedWeightage || 0)),

    assignmentS1: course?.assignmentMarks?.S1?.calculatedWeightage || 0,
    assignmentS2: course?.assignmentMarks?.S2?.calculatedWeightage || 0,
    S1AssignmentWeightage: course?.assignmentMarks?.S1?.weightage,
    S2AssignmentWeightage: course?.assignmentMarks?.S2?.weightage,
    S1AssignmentMarks: (course?.assignmentMarks?.S1?.weightage / 100) * course?.assignmentMarks?.S1?.calculatedWeightage,
    S2AssignmentMarks: (course?.assignmentMarks?.S2?.weightage / 100) * course?.assignmentMarks?.S2?.calculatedWeightage,

    curricularS1: course?.attendenceActivitiesMarks?.S1?.calculatedWeightage || 0,
    curricularS2: course?.attendenceActivitiesMarks?.S2?.calculatedWeightage || 0,
    S1CurricularWeightage: course?.attendenceActivitiesMarks?.S1?.weightage,
    S2CurricularWeightage: course?.attendenceActivitiesMarks?.S2?.weightage,
    S1CurricularMarks: (course?.attendenceActivitiesMarks?.S1?.weightage / 100) * course?.attendenceActivitiesMarks?.S1?.calculatedWeightage,
    S2CurricularMarks: (course?.attendenceActivitiesMarks?.S2?.weightage / 100) * course?.attendenceActivitiesMarks?.S2?.calculatedWeightage,

    S1Total: getTotalMarks(course, 'S1', course?.enrolled_courses?.map((i) => `${i?.course?.name}`).join(',')),
    S2Total: getTotalMarks(course, 'S2', course?.enrolled_courses?.map((i) => `${i?.course?.name}`).join(',')),

    bonus: course?.bonus || '',
    grade: (course?.marks?.Q1?.isAttended || course?.marks?.Q2?.isAttended || course?.marks?.Q3?.isAttended
      || (isNullOrUndefined(course?.marks?.S1?.isAttended) && isNullOrUndefined(course?.marks?.S2?.isAttended)) || isNullOrUndefined(course?.marks?.S3?.isAttended)) ? (course?.grade) : 'N/A',
    gpa: (course?.marks?.Q1?.isAttended || course?.marks?.Q2?.isAttended || course?.marks?.Q3?.isAttended
      || (isNullOrUndefined(course?.marks?.S1?.isAttended) && isNullOrUndefined(course?.marks?.S2?.isAttended)) || isNullOrUndefined(course?.marks?.S3?.isAttended)) ? (course?.gpa) : 'N/A',
    annualScore: (course?.marks?.Q1?.isAttended || course?.marks?.Q2?.isAttended || course?.marks?.Q3?.isAttended
      || (isNullOrUndefined(course?.marks?.S1?.isAttended) && isNullOrUndefined(course?.marks?.S2?.isAttended)) || isNullOrUndefined(course?.marks?.S3?.isAttended))
      ? calculateAnnualScore(course?.enrolled_courses?.map((i) => `${i?.course?.name}`).join(','), course?.marks?.Q3?.maximumMarks, course?.annualScore, course?.marks?.Q3?.calculatedWeightage) : 'N/A',
    newReturning: course?.newReturning ? 'R' : 'N',
    teachersName: (course?.class_teachers || [])
      .map((i) => `${i?.user?.first_name} ${i?.user?.last_name}`)
      .join(','),
  }));
  return rows;
}
function SwapCourse(row) {
  const data = {
    studentId: row?.row?.id,
    studentName: `${row?.row?.studentInfo?.firstName} ${row?.row?.studentInfo?.lastName}`,
    acedemicYear: row?.row?.enrolled_courses[0]?.academicYear,
    courseFrom: row?.row?.enrolled_courses[0]?.course?.name,
    courseTo: row?.row?.enrolled_courses[0]?.course?.id,
    sectionFrom: row?.row?.enrolled_courses[0]?.google_class?.section,
    sectionTo: row?.row?.enrolled_courses[0]?.google_class?.id,
    locationTo: row?.row?.enrolled_courses[0]?.location?.id,
    locationFrom: row?.row?.enrolled_courses[0]?.location?.name,
  };
  return data;
}
export { Student, SwapCourse };

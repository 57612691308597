import { colors, fonts } from '../../theme';

const locationsMapStyles = (() => ({
  containerStyle: {
    width: '100%',
    height: '600px',
  },
  GPSIcon: {
    alignSelf: 'center !important',
    cursor: 'pointer',
    textAlign: 'center',
    paddingLeft: '0px !important',
  },
  innerContainer: {
    padding: '10px',
  },
  innerContainerSide: {
    padding: '15px',
  },
  locationsGrid: {
    position: 'relative',
  },
  MultiValueinnerContainer: {
    paddingLeft: '10px !important',
    marginTop: '-10px !important',
    '@media (max-width: 900px)': {
      paddingBottom: '30px',
      paddingRight: '10px',
    },
  },
  locationDataBox: {
    overflow: 'scroll',
    position: 'fixed',
    display: 'block',
    height: '85%',
    width: '32%',
    overflowX: 'auto',
    '@media (max-width: 900px)': {
      width: '100%',
      position: 'absolute',
      height: '380px',
    },
  },
  errorText: {
    color: colors.errorText,
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw',
    },
  },
  loaderStyle: {
    display: 'flex',
    top: '0',
    left: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    background: 'rgba(0,0,0,0.3)',
    zIndex: '999',
  },
  locData: {
    display: 'flex',
  },
  locDataAdr: {
    marginLeft: '25px',
  },
  locDataAdrCourses: {
    marginLeft: '25px',
    flexBasis: 'max-content',
    maxWidth: 'max-content',
  },
  locDataIcon: {
    paddingRight: '5px',
  },
  contactUsLinks: {
    fontSize: '15px',
    fontWeight: 500,
    textAlign: 'left',
    color: `${colors.black} !important`,
    textDecoration: 'none',
    alignSelf: 'center',
    fontFamily: `${fonts.fontType.roboto} !important`,
    '@media (max-width: 1024px)': {
      fontSize: 14,
    },
  },
  allLocNum: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    background: 'white',
    width: '100%',
    fontSize: '12px',
    '@media (max-width: 899px)': {
      position: 'fixed',
    },
  },
  ChipSection: {
    marginLeft: '2%',
    marginBottom: '1%',
    padding: '2%',
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'none',
    cursor: 'default',
    height: '32px',
    display: 'inline-flex',
    outline: 0,
    fontSize: '0.8125rem',
    boxSizing: 'border-box',
    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    borderRadius: '16px',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
  },
}));
export default locationsMapStyles;

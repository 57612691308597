import { colors, fonts } from '../../theme';

const headerStyle = (() => ({
  HeaderWrapper: {
    width: '100%',
    alignItems: 'center',
    textAlign: '-webkit-center',
  },
  iconWrapper: {
    alignItems: 'center',
    display: 'flow',
    '@media only screen and (min-width: 300px) and (max-width: 425px)': {
      display: 'flex !important',
    },
  },
  formWrapper: {
    backgroundImage: 'url(https://storage.googleapis.com/manabadi-portal-bucket/Others/donate-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '500px',
    textAlign: 'center',
    borderRadius: '20px',
    margin: '0 100px 2px 100px',
    padding: '20px',
    '@media only screen and (max-width: 600px)': {
      margin: '10px',
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      backgroundRepeat: 'round',
      minHeight: '100vh',
      padding: '5px',
    },
    '@media only screen and (min-width: 601px) and (max-width: 1023px)': {
      margin: '10px 50px 2px 50px',
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      padding: '5px',
    },
  },
  cardRight: {
    background: 'white',
    textAlign: '-webkit-center',
    borderRadius: '20px',
    padding: '20px 0',
  },
  paper: {
    padding: '15px',
    width: '90%',
    boxShadow: 'none',
  },
  divWrapper: {
    background: colors.white,
    width: '100% !important',
  },
  headerLogo: {
    cursor: 'pointer',
    width: '100%',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
    '@media only screen and (min-width: 601px) and (max-width: 1023px)': {
      width: '100%',
    },
  },
  errorText: {
    color: colors.errorText,
    textAlign: 'left',
    marginTop: '3px',
  },
  performTextField: {
    '& .Mui-error': {
      color: colors.errorText,
      fontFamily: `${fonts.fontType.roboto} !important`,
      letterSpacing: 'inherit',
      marginLeft: 0,
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '@media (min-width: 1025px)': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        backgroundColor: colors.white,
        color: colors.placeHolderColor,
        fontSize: '12px',
      },
    },
    '& .MuiSelect-select': {
      fontSize: '12px !important',
      textAlign: 'left',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
      '@media (min-width: 1025px)': {
        backgroundImage: 'linear-gradient(to bottom, #fff, #E4E4E4)',
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: colors.primary,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px !important',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink.MuiInputLabel-root': {
      color: `${colors.primary} !important`,
      fontSize: '15px !important',
      '@media (min-width: 1025px)': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.primary} !important`,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& textarea': {
        resize: 'vertical',
        padding: '10px',
      },
      '& .MuiInputLabel-root': {
        fontFamily: `${fonts.fontType.roboto} !important`,
        '@media (min-width: 1025px)': {
          fontFamily: `${fonts.fontType.roboto} !important`,
        },
        color: `${colors.placeHolderColor} !important`,
      },
      '&.MuiInputLabel-root.Mui-focused': {
        color: `${colors.placeHolderColor} !important`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
    },
    '& fieldset': {
      '& legend span': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  notes: {
    width: '-webkit-fill-available',
    padding: '10px',
    resize: 'vertical',
    fontFamily: `${fonts.fontType.roboto} !important`,
    fontSize: '12px',
    borderRadius: '4px',
    height: '40px !important',
  },
  inputLabel: {
    transform: 'translate(14px, 12px) scale(1)',
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  formControl: {
    width: '100%',
  },
  headerBox1: {
    display: 'ruby',
  },
  descBox: {
    display: 'ruby',
    paddingBottom: '20px',
  },
  headerBox2: {
    textAlign: 'center',
    width: '90%',
  },
  header1: {
    fontSize: 'xx-large',
    color: colors.white,
    '@media only screen and (max-width: 600px)': {
      fontSize: 'x-large',
    },
  },
  header2: {
    paddingBottom: '20px',
    fontSize: 'medium',
    color: colors.white,
    '@media only screen and (max-width: 600px)': {
      fontSize: 'x-small',
    },
  },
  header3: {
    color: '#2E7D32',
    fontSize: '24px',
    fontWeight: '900',
  },
  header4: {
    fontSize: 'small',
    color: colors.black,
    textAlign: 'center',
  },
  adornment: {
    '& .MuiOutlinedInput-input': {
      paddingLeft: '0px !important',
    },
  },
}));
export default headerStyle;

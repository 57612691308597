/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Popover, TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import DialogAtom from '../../../components/atoms/dialog';
import StudentsList from '../students-list';
import SelectColumns from '../select-columns';
import {
  createStudentHomeworkMarks,
  createStudentMarks,
  getAssignedLocations,
  getStudentsByLocation,
  updateStudentHomeworkMarks,
  updateStudentMarks,
} from '../../../store/actions/getLocationCoordinator';
import UpdateMarks from '../update-marks';
import { getAllCourses, getStudentEnrollmentHistory } from '../../../store/actions/getStudent';
import { makeUpMarks } from '../../../store/actions/getTeacherView';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import BonusMarks from '../bonus-marks';
import {
  getAllQuarterFields,
  getAllSemesterFields,
  getQuarterCSVHeaders,
  getSemesterCSVHeaders,
  getAllSemesterFields2023,
  getSemesterCSVHeaders2023
} from '../students-list/columns';
import { updateCurricularActivitiesMarks } from '../../../store/actions/curricular-activities';
import { updateAssignmentsMarks } from '../../../store/actions/getAssignmentsSchema';
import { updateQuizMarks } from '../../../store/actions/quizSchema';
import { getLocalStorage } from '../../../utils/localStorageMethod';

export default function Classes() {
  const [isMarks, setIsMarks] = useState(false);
  const [isBonusMarks, setIsBonusMarks] = useState(false);
  const [listPayload, setListPayload] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [updateMarksContent, setUpdateMarksContent] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const [isColumnSelectOpen, setDialogOpen] = useState(false);
  const [updateMakupMarks, setUpdateMakupMarks] = useState(false);
  const [updateData, setData] = useState();
  const [updateTypeData, setUpdateType] = useState();
  const [updateQuarterData, setQuarter] = useState();
  const [showMakeupMarks, setShowMakeupMarks] = useState();
  const [passingCriteria, setPassingCriteria] = useState();
  const [number, setNumber] = useState();
  const [maxNumber, setMaxNumber] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [studentContent, setStudentContent] = useState();
  const [visibleFields, setVisibleFields] = useState([]);
  const [validSemester, setValidSemester] = useState(false);
  const academicYear = getLocalStorage('academicYear');
  const [acadYear, setAcadYear] = useState(academicYear);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [openDialogPermissionsError, setOpenDialogPermissionsError] = useState(false);
  const [dialogErrorImportMessage, setDialogErrorImportMessage] = useState('');
  const getEnrollDetails = async (studentData) => {
    setAssignLoading(true);
    const payload = {
      studentId: studentData?.studentId,
      courseId: studentData?.courseTo,
      academicYear: studentData?.academicYear,
      quarter: studentData?.selectedQuarter,
    };
    dispatch(getStudentEnrollmentHistory(payload, setAssignLoading));
  };

  const QUARTER_OPTIONS = [
    { id: 'Q1', name: 'Q1' },
    { id: 'Q2', name: 'Q2' },
    { id: 'Q3', name: 'Q3' },
  ];

  const getSemesterOptions = (courseName, type) => {
    return (type === 'marks') ? (courseName === 'BalaBadi - I' || courseName === 'BalaBadi - II'
      || courseName === 'Telugu-3' || courseName === 'Telugu-1') ? [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }]
      : (courseName === 'Prakasam' || courseName === 'Prabhasam' || courseName === 'Telugu-2' || courseName === 'Telugu-4') ? [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }, { id: 'S3', name: 'PSTU' }]
        : [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }, { id: 'S3', name: 'SUPPLEMENTARY' }]
      : [{ id: 'S1', name: 'S1' }, { id: 'S2', name: 'S2' }];
  };

  React.useEffect(() => {
    if (validSemester) {
      if (acadYear === '2023-2024') {
        setVisibleFields(getAllSemesterFields2023(t));
        setFileHeaders(getSemesterCSVHeaders2023(t));
      } else {
        setVisibleFields(getAllSemesterFields(t));
        setFileHeaders(getSemesterCSVHeaders(t));
      }
    } else {
      setFileHeaders(getQuarterCSVHeaders(t));
      setVisibleFields(getAllQuarterFields(t));
    }
  }, [validSemester, acadYear]);

  React.useEffect(async () => {
    if (studentContent) {
      setAssignLoading(true);

      const studentInfo = {
        firstName: `${updateData?.row?.studentInfo?.firstName} ${updateData?.row?.studentInfo?.middleName} ${updateData?.row?.studentInfo?.lastName}`,
        courseTo: updateData?.row?.enrolled_courses[0]?.course?.id,
        studentId: updateData?.row?.id,
        academicYear: updateData?.row?.enrolled_courses[0].academicYear,
        selectedQuarter,
        location: updateData?.row?.enrolled_courses[0]?.location?.id,
        quarterOptions: validSemester ? getSemesterOptions(updateData?.row?.enrolled_courses[0]?.course?.name, updateTypeData) : QUARTER_OPTIONS,
        updateType: updateTypeData,
        validSemester,
        quizName: 'Quiz 1',
      };
      setUpdateMarksContent(studentInfo);
      await getEnrollDetails(studentInfo).finally(() => {
      });
    }
  }, [selectedQuarter]);

  const marksOpen = async (data, updateType, quarter) => {
    setAssignLoading(true);

    if (updateType === 'bonus') {
      const studentInfo = {
        courseId: data?.row?.enrolled_courses[0]?.course?.id,
        studentId: data?.row?.id,
        academicYear: data?.row?.enrolled_courses[0].academicYear,
        locationId: data?.row?.enrolled_courses[0]?.location?.id,
      };
      setUpdateMarksContent(studentInfo);
      setIsBonusMarks(true);
      setAnchorEl(null);
      return;
    }

    const studentInfo = {
      firstName: `${data?.row?.studentInfo?.firstName} ${data?.row?.studentInfo?.middleName} ${data?.row?.studentInfo?.lastName}`,
      courseTo: data?.row?.enrolled_courses[0]?.course?.id,
      studentId: data?.row?.id,
      academicYear: data?.row?.enrolled_courses[0].academicYear,
      selectedQuarter: quarter,
      location: data?.row?.enrolled_courses[0]?.location?.id,
      quarterOptions: validSemester ? getSemesterOptions(data?.row?.enrolled_courses[0]?.course?.name, updateType) : QUARTER_OPTIONS,
      updateType,
      validSemester,
      quizName: 'Quiz 1',
    };
    setUpdateMarksContent(studentInfo);
    await getEnrollDetails(studentInfo).finally(() => {
      setIsMarks(true);
      setAnchorEl(null);
    });
  };
  const marksClose = () => {
    setIsMarks(false);
    setUpdateMarksContent('');
  };

  const bonusMarksClose = () => {
    setIsBonusMarks(false);
    setUpdateMarksContent('');
  };

  useEffect(() => {
    const loadFalse = () => setLoading(false);
    dispatch(getAssignedLocations(loadFalse));
    dispatch(getAllCourses());
  }, []);

  const refreshList = (payload) => {
    if (!loading) {
      setLoading(true);
    }
    setListPayload(payload);
    setTimeout(() => {
      dispatch(getStudentsByLocation(payload, setLoading, refreshList));
    }, 1000);
  };
  const updateMarks = (payload) => {
    const loadFalse = () => {
      refreshList(listPayload);
    };
    if (payload.updateType === 'marks') {
      setLoading(true);
      dispatch(updateStudentMarks(payload, loadFalse));
      marksClose();
    } else if (payload.updateType === 'homework-marks') {
      dispatch(updateStudentHomeworkMarks(payload, loadFalse));
      marksClose();
    } else if (payload.updateType === 'curricular-marks') {
      dispatch(updateCurricularActivitiesMarks(payload, loadFalse));
      marksClose();
    } else if (payload.updateType === 'assignments') {
      dispatch(updateAssignmentsMarks(payload, loadFalse));
      marksClose();
    } else if (payload.updateType === 'quiz1-marks') {
      dispatch(updateQuizMarks(payload, loadFalse));
      marksClose();
    } else if (payload.updateType === 'quiz2-marks') {
      dispatch(updateQuizMarks(payload, loadFalse));
      marksClose();
    }
  };

  const createMarks = (payload) => {
    const loadFalse = () => {
      refreshList(listPayload);
    };
    if (payload.updateType === 'marks') {
      setLoading(true);
      dispatch(createStudentMarks(payload, loadFalse));
      marksClose();
    } else {
      dispatch(createStudentHomeworkMarks(payload, loadFalse));
      marksClose();
    }
  };

  const setOpenDialog = (event, dataUpdate, dataUpdateType, dataQuarterUpdate) => {
    if (dataUpdate?.row?.course === 'BalaBadi - I' || dataUpdate?.row?.course === 'BalaBadi - II') {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
    if (dataUpdateType === 'marks') {
      setShowMakeupMarks(dataUpdate?.row?.marks[dataQuarterUpdate]?.obtainedMarks);
      setPassingCriteria(dataUpdate?.row?.marks[dataQuarterUpdate]?.passingCriteria);
    } else {
      setShowMakeupMarks(dataUpdate?.row?.homeworkMarks[dataQuarterUpdate]?.obtainedMarks);
    }
    setData(dataUpdate);
    setUpdateType(dataUpdateType);
    setQuarter(dataQuarterUpdate);
  };

  const handleUpdateMakupMarksClose = () => {
    setUpdateMakupMarks(false);
  };

  const handleUpdateMakupMarksOpen = () => {
    const maxAddNumber = passingCriteria - showMakeupMarks;
    setMaxNumber(parseFloat(maxAddNumber).toFixed(1));
    setUpdateMakupMarks(true);
    setAnchorEl(null);
    setNumber('');
  };

  const validateNumber = (event) => {
    const value = parseFloat(event.target.value) || event.target.value;
    const setValue = value <= maxNumber ? event.target.value : number;
    const num = setValue.split('.');
    if (num.length > 2) return;
    if (/^\d+$/.test(num[0])) {
      if (num[1]) {
        if (/^\d+$/.test(num[1])) {
          if (num[1].length > 2) {
            setNumber(Number(setValue).toFixed(2));
          } else {
            setNumber(setValue);
          }
        }
      } else {
        setNumber(setValue);
      }
    } else if (setValue === '') {
      setNumber(setValue);
    }
  };

  const handleUpdateMakupMarks = (updateMakeUpData) => {
    if (number) {
      const payload = {
        courseId: updateMakeUpData?.row?.enrolled_courses[0]?.course?.id,
        studentId: updateMakeUpData?.row?.id,
        academicPanelId: updateMakeUpData?.row?.marks[updateQuarterData]?.academicPanelId,
        academicYear: updateMakeUpData?.row?.enrolled_courses[0].academicYear,
        quarter: updateQuarterData,
        makeUpMarks: Number(number),
      };
      dispatch(makeUpMarks(payload));
      setUpdateMakupMarks(false);
      setTimeout(() => {
        refreshList(listPayload);
      }, 2000);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderUpdateMakupMarksContent = () => (
    <Grid>
      <Typography>
        {t('MAKEUP_MARKS')}
        {' '}
        (Max:
        {' '}
        {maxNumber}
        ):
      </Typography>
      <div className={classes.addMakupMarksPopup}>
        <TextField
          id="outlined-number"
          onChange={validateNumber}
          value={number}
          error={number < 0}
          helperText={number < 0 ? 'Should be greater than 0' : ''}
          InputProps={
            {
              inputProps: {
                min: 0,
                max: maxNumber,
                pattern: '[0-9]*',
                step: '1',
                inputMode: 'numeric',
              },
            }
          }
          onKeyDown={(event) => {
            if ((event.key === '+' || event.key === '-' || event.key === 'E' || event.key === 'e')) {
              event.preventDefault();
            }
          }}
          required
        />
      </div>
      <Grid item xs={12}>
        <div style={{ display: 'flex', justifyContent: 'end', padding: '40px 0 15px' }}>
          <div className={classes.popupBtn}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              btntype={Buttons.SECONDARY}
              onClick={() => {
                setUpdateMakupMarks(false);
              }}
            />
          </div>
          <div className={number < 0 ? classes.popupBtnDisable : ''}>
            <ButtonAtom
              name={t('SAVE')}
              btntype={Buttons.PRIMARY}
              onClick={() => handleUpdateMakupMarks(updateData)}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
  const renderPermissionsError = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {dialogErrorImportMessage}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => {
            setOpenDialogPermissionsError(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.gridContainer}>
      <Grid container item className={classes.titleRow}>
        <Grid item>
          <Typography className={classes.headerTitle}>
            {t('STUDENTS')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={2} className={classes.alignGrid} />
        <Grid item xs={12} lg={7} />
      </Grid>
      <Grid container className={classes.studentContainer}>
        <Grid container className={classes.studentListView}>
          <StudentsList
            setDialogOpen={() => setDialogOpen(true)}
            {...{
              refreshList,
              setLoading,
              loading,
              visibleFields,
              fileHeaders,
              setCourseData,
              courseData,
              setOpenDialog,
              validSemester,
              setValidSemester,
              setOpenDialogPermissionsError,
              setDialogErrorImportMessage,
              setAcadYear,
            }}
          />
        </Grid>
      </Grid>

      <DialogAtom
        customClass={classes.columnSettingDialog}
        isOpen={isColumnSelectOpen}
        dialogHeading={t('COLUMN_SETTINGS')}
        content={(
          <SelectColumns
            {...{
              classes,
              setDialogOpen,
              visibleFields,
              setVisibleFields,
              fileHeaders,
              setFileHeaders,
              validSemester,
              acadYear,
            }}
          />
        )}
        secHandle={() => setDialogOpen(false)}
      />
      <DialogAtom
        isOpen={isMarks}
        secHandle={() => marksClose(false)}
        dialogHeading={t('ASSIGN_SCORE')}
        customClass={classes.marksDialog}
        content={(
          <UpdateMarks
            {...{ updateMarks, courseData, createMarks }}
            studentData={updateMarksContent}
            handleClose={() => marksClose(false)}
            assignLoading={assignLoading}
            selectedQuarter={selectedQuarter}
            setSelectedQuarter={setSelectedQuarter}
            setStudentContent={setStudentContent}
            setStudentData={setUpdateMarksContent}
            getEnrollDetails={getEnrollDetails}
          />
        )}
      />

      <DialogAtom
        isOpen={isBonusMarks}
        secHandle={() => bonusMarksClose()}
        dialogHeading={t('ASSIGN_BONUS_MARKS')}
        customClass={classes.marksDialog}
        content={(
          <BonusMarks
            {...{ updateData }}
            studentData={updateMarksContent}
            handleClose={() => bonusMarksClose()}
            afterSave={() => {
              refreshList(listPayload);
              bonusMarksClose();
            }}
          />
        )}
      />
      <DialogAtom
        isOpen={updateMakupMarks}
        secHandle={() => handleUpdateMakupMarksClose(false)}
        dialogHeading={t('ADD_MAKUP_MARKS')}
        content={renderUpdateMakupMarksContent()}
      />
      <DialogAtom
        isOpen={openDialogPermissionsError}
        dialogHeading={t('PERMISSIONS_REQUIRED')}
        customClass={classes.DialogAtom}
        content={renderPermissionsError()}
        secHandle={() => {
          setOpenDialogPermissionsError(false);
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ left: '3%' }}
      >
        {updateTypeData === 'marks' && (showMakeupMarks >= (passingCriteria - 10) && showMakeupMarks < passingCriteria
          && !validSemester) && (
            <div onClick={() => handleUpdateMakupMarksOpen()}>
              <Typography sx={{
                p: 2,
                cursor: 'pointer',
                fontSize: '12px',
                borderBottom: '0.1vw solid #d0d0d0',
              }}
              >
                {t('UPDATE_MAKEUP_MARKS')}
              </Typography>
            </div>
        )}
        <div onClick={() => marksOpen(updateData, updateTypeData, updateQuarterData)}>
          <Typography sx={{ p: 2, cursor: 'pointer', fontSize: '12px' }}>
            {t('UPDATE_MARKS')}
          </Typography>
        </div>
      </Popover>
    </Box>
  );
}

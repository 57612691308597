import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,

  Typography,
  Stack,
  Menu, MenuItem,
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import GridViewIcon from '@mui/icons-material/GridView';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import MapPin from '../../../assets/images/map-pin.png';
import {
  uploadHelpVideo,
  uploadVideo,
  getHelpVideosPagination,
} from '../../../store/actions/getResources';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import { DialogAtom } from '../../../components/atoms';
import ListViewIcon from '../../../assets/svg/listViewIcon';
import useStyles from '../../../custom-hooks/useStyles';
import Dropdown from '../../../components/atoms/dropdown';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageMethod';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import CheckboxAtom from '../../../components/atoms/checkbox';
import CheckboxAtomDialog from '../../../components/atoms/checkbox-dialog-resources';
import CheckboxAtomFromDrive from '../../../components/atoms/checkbox-dialog-help-site';
import {
  getAssignedLocations, getAllLocationCourse, getLocationDetails, setSelectedYear, setSelectedLocation,
} from '../../../store/actions/getLocationCoordinator';
import { getAssignedUserRoles } from '../../../store/actions/getUser';
import styles from './style';
import userRoles from '../../../constant/userRoles';
import GridView from './grid-view';
import ListView from './list-view';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';
import { getCurrentYear } from '../../../utils/methods';

export default function HelpSite() {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const {
    nextPage,
  } = useDataTableParams();

  const [view, setView] = useState('gridView');
  const [year, setSelectYear] = useState();
  const [issetUploadVOpenDialog, setUploadVideoOpenDialog] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [errorLargeVideo, setErrorLargeVideo] = useState('');
  const [issetUploadVideoSize, setUploadVideoSize] = useState();
  const [issetUploadVideoName, setUploadVideoName] = useState('');
  const [issetUploadVideoExt, setUploadVideoExt] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [issetUploadVideo, setUploadVideo] = useState({});
  const [addVideoError, setAddVideoError] = useState('');
  const [showTokenAdvice, setShowTokenAdvice] = useState(false);

  const [selectedLocation, setSelectedLocationn] = useState('');
  const [years, setYearsData] = useState([]);
  const [locations, setLocationData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getLocationCoordinator);
  const assignedYears = reduxStore?.assignedYears;
  const assignedLocations = reduxStore?.assignedLocations;
  const helpVideos = reduxStore?.helpVideos;
  const uploadVideoResponse = reduxStore?.uploadVideo;
  const [resources, setResources] = useState([]);
  const [issetSelectVideoFromDriveOpenDialog, setSelectVideoFromDriveOpenDialog] = useState(false);
  const [issetSelectVideoFromDriveFile, setSelectVideoFromDriveFile] = useState();
  const [issetSelectVideoFromDrive, setSelectVideoFromDrive] = useState([]);
  const [disabledCheck, setDisabledCheck] = useState(false);
  const [isVideochecked, setIsVideoChecked] = useState(false);
  const [issetSelectVideoFromDriveMessage, setSelectVideoFromDriveMessage] = useState('');
  const [isDeactivateChecked, setDeactivateChecked] = useState(false);
  const [filteredResources, setFilteredResources] = useState([]);
  const [assignedUserRoles, setAssignedUserRoles] = useState([]);
  const [isSysAdminOrSuperUser, setIsSysAdminOrSuperUser] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    resourceN: 'both',
    createdBy: 'both',
    createdOn: 'both',
    updatedBy: 'both',
    updatedOn: 'both',
    sizeN: 'both',
  });
  const [pagenum, setPagenum] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalActiveResources, setTotalActiveResources] = useState(0);
  const refreshPage = () => window.location.reload();

  useEffect(() => {
    dispatch(getAssignedUserRoles())
      .then((data) => {
        setAssignedUserRoles(data?.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let isAdminOrSuperUser = false;
    assignedUserRoles.map((ur) => {
      if (ur?.name === userRoles.SYSTEM_ADMIN || ur?.name === userRoles.SUPER_USER || ur?.name === userRoles.CURRICULUM) {
        isAdminOrSuperUser = true;
      }
      return isAdminOrSuperUser;
    });
    setIsSysAdminOrSuperUser(isAdminOrSuperUser);
  }, [assignedUserRoles]);

  useEffect(() => {
    if (helpVideos) {
      if (isDeactivateChecked) {
        setFilteredResources(helpVideos);
      } else {
        const arr = [];
        helpVideos.map((hv) => {
          if (hv?.isActive === true) {
            arr.push(hv);
          }
          return arr;
        });
        setFilteredResources(arr);
        setTotalActiveResources(arr.length);
      }
    }
  }, [helpVideos, isDeactivateChecked]);

  useEffect(() => {
    setResources(filteredResources);
  }, [filteredResources]);

  useEffect(() => {
    setTotalRows(filteredResources?.length);
  }, [filteredResources]);

  useEffect(() => {
    dispatch(getAllLocationCourse({
      locationId: assignedLocations[0]?.id,
    }));
  }, [assignedLocations]);
  useEffect(() => {
    if (selectedLocation && year) {
      dispatch(getLocationDetails({ locationId: selectedLocation, year }));
      setSortOrder({
        resourceN: 'both',
        createdBy: 'both',
        createdOn: 'both',
        updatedBy: 'both',
        updatedOn: 'both',
        sizeN: 'both',
      });
    }
  }, [selectedLocation, year]);

  useEffect(() => {
    setYearsData(assignedYears);
    setLocationData(assignedLocations);
    if (reduxStore?.selectedLocations?.value) {
      setSelectedLocationn(reduxStore?.selectedLocations?.value);
    } else {
      setSelectedLocationn(assignedLocations[0]?.id);
    }

    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears.filter((opt) => opt?.id === currentYear.toString());
    if (reduxStore?.selectedYear?.name) {
      setSelectYear(reduxStore?.selectedYear?.name);
    } else {
      setSelectYear(selectedYear[0]?.id);
    }
  }, [assignedLocations, assignedYears]);

  const refreshList = () => {
    const payload = {
      userRole: 'LOCATION_COORDINATOR',
    };
    dispatch(getHelpVideosPagination(payload, 1, 100, nextPage));
    setPagenum(1);
  };

  const handleVideoCheck = (e) => {
    setIsVideoChecked(e.target.checked);
  };

  const handleYearDropDownChange = (e) => {
    setSelectYear(e.target.value);
    if (userRole === userRoles.LOCATION_COORDINATOR) {
      const payload = {
        id: e.target.value,
        name: e.target.value,
      };
      dispatch(setSelectedYear(payload));
    }
  };

  const handleLocationDromDownChange = (e) => {
    setSelectedLocationn(e.target.value);
    if (userRole === userRoles.LOCATION_COORDINATOR) {
      dispatch(setSelectedLocation(e.target));
    }
  };

  useEffect(() => {
    const loadFalse = () => setLoadingUploadFile(false);
    dispatch(getAssignedLocations(loadFalse));
  }, []);

  useEffect(() => {
    const payload = {
      userRole: 'LOCATION_COORDINATOR',
    };
    setPagenum(1);
    dispatch(getHelpVideosPagination(payload, 1, 100, nextPage));
  }, []);

  const handleView = (event, myResourceView) => {
    if (myResourceView !== null) {
      setView(myResourceView);
    }
  };

  useEffect(() => {
    if (uploadVideoResponse?.message !== undefined) {
      const payload = {
        name: uploadVideoResponse?.resourceName,
        url: uploadVideoResponse?.url,
        type: 'LOCATION_COORDINATOR',
        size: uploadVideoResponse?.fileSize,
      };
      dispatch(uploadHelpVideo(payload));
      setSelectVideoFromDriveMessage(uploadVideoResponse?.message);
      setTimeout(() => {
        refreshList();
        setSelectVideoFromDriveOpenDialog(false);
        uploadVideoResponse.message = undefined;
      }, 1000);
    }
  }, [uploadVideoResponse?.message]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddvideo = () => {
    const videonames = `${issetUploadVideoName}.${issetUploadVideoExt}`;
    const filenamesScript = videonames.replace(/ /g, '_');
    const formData = new FormData();
    setLoadingUploadFile(true);
    if (issetUploadVideo.size > 30000000) {
      setErrorLargeVideo(`${t('SIZE_VIDEO_DRIVE')}`);
      setLoadingUploadFile(false);
      setTimeout(() => {
        setErrorLargeVideo('');
        setUploadVideoOpenDialog(false);
      }, 2500);
    } else {
      formData.append('video', issetUploadVideo, filenamesScript);
      dispatch(uploadVideo(formData, setSelectVideoFromDriveOpenDialog, setLoadingUploadFile, setErrorLargeVideo, setSelectVideoFromDriveMessage, setUploadVideoOpenDialog, refreshPage));
      setIsVideoChecked(false);
    }
  };

  const renderUploadVideoContent = () => (
    <Grid className={loadingUploadFile ? classes.addDialog : ''}>
      {loadingUploadFile && (
        <Box sx={{
          display: 'flex', position: 'absolute', paddingLeft: '40%', paddingTop: '20%',
        }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorLargeVideo && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {`${errorLargeVideo}`}
          </div>
        </Grid>
      )}
      {issetSelectVideoFromDriveMessage && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadVideoResponse?.message}
          </div>
        </Grid>
      )}
      {!loadingUploadFile && (
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              className={classes.popupTextField}
              required
              id="outlined-required"
              label="File Name"
              value={issetUploadVideoName}
              onChange={(e) => {
                setAddVideoError('');
                if (e.target.value === '') {
                  setAddVideoError(t('FILE_NAME_CANNOT_BE_EMPTY'));
                }
                setUploadVideoName(e.target.value);
              }}
              defaultValue="BalaBadi-I"
            />
          </div>
          <span className={classes.errorText}>{addVideoError}</span>
          <div className={classes.dialogCheckbox}>
            <CheckboxAtomDialog
              label={t('USER_DOWNLOAD')}
              id="acceptCheckbox"
              checked={isVideochecked}
              handleChange={(e) => handleVideoCheck(e)}
            />
            <p className={classes.dialogfooter}>
              {t('FILE_SIZE')}
              &nbsp;
              :
              &nbsp;
              {issetUploadVideoSize}
              &nbsp;
              MB
            </p>
          </div>
          <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
            <ButtonAtom
              className={classes.popupBtn}
              name={t('CANCEL')}
              onClick={() => {
                setErrorLargeVideo('');
                setUploadVideoOpenDialog(false);
                uploadVideoResponse.message = undefined;
                setSelectVideoFromDriveMessage('');
                setIsVideoChecked(false);
                dispatch({
                  type: 'UPLOAD_VIDEO',
                  payload: [],
                });
              }}
              btntype={Buttons.SECONDARY}
            />
            <div className={issetUploadVideoSize > 50 ? classes.popupBtnDisable : classes.popupBtn}>
              <ButtonAtom
                name={t('UPDATE')}
                onClick={handleAddvideo}
                btntype={Buttons.PRIMARY}
                disabled={issetUploadVideoSize > 50}
              />
            </div>
          </Grid>
        </Box>
      )}
    </Grid>
  );

  const getData = (resource) => {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      const gettoken = getLocalStorage('accessToken');
      const baseUrl = JSON.parse(gettoken);
      request.addEventListener('readystatechange', () => {
        if (request.readyState === 4 && request.status === 200) {
          const data = JSON.parse(request.responseText);
          resolve(data);
        } else if (request.readyState === 4) {
          reject(new Error('error getting resources'));
        }
      });
      request.open('GET', resource);
      request.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
      request.send();
    });
  };

  const handleSelectVideoFromDriveDialog = () => {
    uploadVideoResponse.message = undefined;
    getData('https://www.googleapis.com/drive/v3/files?pageSize=1000&supportsAllDrives=true&q="root" in parents')
      .then((data) => {
        const fileData = data.files;
        const mineType = fileData.filter(
          (item) => item.mimeType.split('/')[0] === 'video',
        );
        setSelectVideoFromDrive(mineType);
        setSelectVideoFromDriveOpenDialog(true);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        setErrorLargeVideo(`${t('TOKEN_EXPIRED')}`);
        setShowTokenAdvice(true);
      });
  };

  const handleSelectVideoFromDrive = () => {
    setLoadingUploadFile(true);
    const formData = new FormData();
    if (issetSelectVideoFromDriveFile.size > 30000000) {
      setErrorLargeVideo(`${t('SIZE_VIDEO_DRIVE')}`);
      setLoadingUploadFile(false);
      setTimeout(() => {
        setErrorLargeVideo(false);
      }, 2500);
      setIsVideoChecked(false);
    } else {
      formData.append('video', issetSelectVideoFromDriveFile);
      dispatch(uploadVideo(formData, setSelectVideoFromDriveOpenDialog, setLoadingUploadFile, setErrorLargeVideo, setSelectVideoFromDriveMessage, refreshPage));
      setIsVideoChecked(false);
    }
  };

  const onChangeValue = (e, obj, callback) => {
    setDisabledCheck(true);
    const fileId = e.target.value;
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);

    xhr.responseType = 'blob';
    xhr.onload = () => {
      try {
        const filedata = new Blob([xhr.response], { type: obj?.mimeType });
        const file = new File([filedata], obj?.name.replace(/ /g, '_'));

        callback(file);
      } catch (err) {
        callback(null);
      }
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };

  const onCancelVideoFromDriveContent = () => {
    setSelectVideoFromDriveOpenDialog(false);
    setSelectVideoFromDriveFile(false);
    setIsVideoChecked(false);
    dispatch({
      type: 'UPLOAD_VIDEO',
      payload: [],
    });
  };
  const missingToken = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('TOKEN_EXPIRED')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setShowTokenAdvice(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderSelectVideoFromDriveContent = () => (
    <Grid className={loadingUploadFile ? classes.addDialog : ''}>
      {loadingUploadFile && (
        <Box sx={{
          display: 'flex', position: 'absolute', paddingLeft: '40%', paddingTop: '20%',
        }}
        >
          <CircularProgress />
        </Box>
      )}
      {errorLargeVideo && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {`${errorLargeVideo}`}
          </div>
        </Grid>
      )}
      {disabledCheck && (
        <Grid>
          <div className={classes.alertFetching} role="alert">
            {t('FETCHING_RESOURCES')}
          </div>
        </Grid>
      )}
      {issetSelectVideoFromDriveMessage && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadVideoResponse?.message}
          </div>
        </Grid>
      )}
      {!loadingUploadFile && (
        <>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className={classes.scrollBar}>

              {
                (issetSelectVideoFromDrive.length > 0)
                  ? (issetSelectVideoFromDrive?.map((obj) => (
                    <div className={classes.popupTextField}>
                      <input
                        disabled={disabledCheck}
                        type="radio"
                        value={obj?.id}
                        name="name"
                        onChange={(e) => onChangeValue(e, obj, (file) => {
                          if (file) {
                            setSelectVideoFromDriveFile(file);
                            setSelectVideoFromDriveMessage('');
                            setDisabledCheck(false);
                          }
                        })}
                      />
                      {obj?.name.replace(/ /g, '_')}
                    </div>
                  )))
                  : (
                    <p style={{ color: 'red' }}>No Data Available</p>
                  )
              }

            </div>
            <div className={classes.dialogCheckbox}>
              <CheckboxAtomFromDrive
                label={t('USER_DOWNLOAD')}
                id="acceptCheckbox"
                checked={isVideochecked}
                handleChange={(e) => handleVideoCheck(e)}
              />
            </div>
          </Box>
          <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
            <div>
              <ButtonAtom
                className={classes.popupBtn}
                name={t('CANCEL')}
                onClick={() => {
                  setErrorLargeVideo('');
                  onCancelVideoFromDriveContent();
                  setIsVideoChecked(false);
                }}
                btntype={Buttons.SECONDARY}
              />
            </div>
            <div className={issetSelectVideoFromDriveFile ? classes.popupBtn : classes.popupBtnDisable}>
              <ButtonAtom
                name={t('ADD')}
                onClick={handleSelectVideoFromDrive}
                btntype={Buttons.PRIMARY}
                disabled={!issetSelectVideoFromDriveFile}
              />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <div className={classes.gridPadding}>
      <div className={classes.divstule}>
        <Stack sx={{ width: '100%' }} spacing={4}>

          <Grid container flexDirection="row" display="flex" className={classes.divWrapper}>
            <Grid container>
              <Grid container item className={classes.titleRow}>
                <Grid item>
                  <Typography className={classes.headerTitle}>
                    {t('HELP_SITE')}
                  </Typography>
                </Grid>
                {
                  isSysAdminOrSuperUser
                  && (
                    <Grid>
                      <ButtonAtom
                        className={classes.addbutton}
                        name={t('ADD')}
                        btntype={Buttons.PRIMARY}
                        type={Buttons.PRIMARY}
                        onClick={handleClick}
                        icon={<AddIcon />}
                      />
                    </Grid>
                  )
                }
                <Menu
                  className={classes.Menubox}
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiMenuItem-root': {
                        borderBottom: '0.1vw solid #d0d0d0',
                        '@media (min-width: 1200px)': {
                          padding: '1vw',
                          fontSize: '0.9vw',
                        },
                        borderRadius: '0vw',
                        display: 'list-item',
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >

                  <MenuItem
                    variant="contained"
                    component="label"
                  >
                    {t('UPLOADVIDEO')}
                    <input
                      type="file"
                      accept=".mp4,.mov"
                      hidden
                      onChange={(e) => {
                        const videosize = (e.target.files[0].size / (1024 * 1024)).toFixed(2);
                        setUploadVideoSize(parseFloat(videosize));
                        setUploadVideo(e.target.files[0]);
                        const video = (e.target.files[0].name);
                        const videoName = video.split('.');
                        setUploadVideoExt(videoName[1]);
                        setUploadVideoName(videoName[0]);
                        setUploadVideoOpenDialog(true);
                        handleClose();
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleSelectVideoFromDriveDialog();
                      handleClose();
                    }}
                    onClose={handleClose}
                  >
                    {t('SELECT_VIDEO_FROM_DRIVE')}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <DialogAtom
              isOpen={issetUploadVOpenDialog}
              dialogHeading={t('UPLOADVIDEO')}
              customClass={classes.DialogAtom}
              content={renderUploadVideoContent()}
              secHandle={() => {
                setUploadVideoOpenDialog(false);
                dispatch({
                  type: 'UPLOAD_VIDEO',
                  payload: [],
                });
              }}
            />
            <DialogAtom
              isOpen={showTokenAdvice}
              dialogHeading={t('TOKEN_EXPIRED_TITLE')}
              content={missingToken()}
              secHandle={() => setShowTokenAdvice(false)}
            />

            <DialogAtom
              isOpen={issetSelectVideoFromDriveOpenDialog}
              dialogHeading={t('SELECT_VIDEO_FROM_DRIVE')}
              customClass={classes.DialogAtomDrive}
              content={renderSelectVideoFromDriveContent()}
              secHandle={() => {
                onCancelVideoFromDriveContent();
              }}
            />
            <Grid item className={classes.HeaderWrapper1}>
              {
                userRole === userRoles.LOCATION_COORDINATOR
                && (
                  <div className={classes.dropdownWrap}>
                    <div className={classes.studentcourse}>
                      <Dropdown
                        id="studentCourse"
                        name={t('studentCourse')}
                        variant="standard"
                        options={years}
                        value={year}
                        customClass={classes.dropdown}
                        changeCss
                        customFormControlCss={{ width: '100%' }}
                        labelId="studentCourse"
                        handleChange={handleYearDropDownChange}
                      />
                    </div>
                    <div className={classes.location}>
                      <Dropdown
                        id="studentCourse"
                        name={t('studentCourse')}
                        variant="standard"
                        options={locations}
                        value={selectedLocation}
                        customClass={classes.dropdown}
                        changeCss
                        customFormControlCss={{ width: '100%' }}
                        labelId="studentCourse"
                        handleChange={handleLocationDromDownChange}
                        icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}

                      />
                    </div>
                  </div>
                )
              }
              <Grid item className={classes.HeaderWrapper}>
                {isSysAdminOrSuperUser
                  ? (
                    <div className={classes.deactiveCheckbox}>
                      <CheckboxAtom
                        label={t('VIEW_INACTIVE')}
                        id="acceptCheckbox"
                        checked={isDeactivateChecked}
                        handleChange={() => setDeactivateChecked(!isDeactivateChecked)}
                      />
                    </div>
                  ) : null}
                <Stack direction="row" spacing={1} justifyContent="space-between">
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    aria-label="text alignment"
                    onChange={handleView}
                    className={classes.toggleButtonGrp}
                  >
                    <ToggleButton
                      value="listView"
                      aria-label="listView"
                      className={classes.toggleButtonList}
                      onClick={() => setView('listView')}
                    >
                      <ListViewIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="gridView"
                      aria-label="gridView"
                      className={classes.toggleButtonGrid}
                      onClick={() => setView('gridView')}
                    >
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.resourceContainer}>
            {view === 'listView'
              ? (
                <Grid container className={classes.resourcesListView}>
                  {setLocalStorage('myResourceView', 'listView')}
                  <ListView
                    resources={resources}
                    refreshData={refreshList}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    totalRows={totalRows}
                    isSysAdminOrSuperUser={isSysAdminOrSuperUser}
                    isDeactivateChecked={isDeactivateChecked}
                    totalActiveResources={totalActiveResources}
                  />
                </Grid>
              )
              : (
                <Grid container className={classes.resourcesGridView}>
                  {setLocalStorage('myResourceView', 'gridView')}
                  <GridView
                    tableData={resources}
                    refreshData={refreshList}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    totalRows={totalRows}
                    isSysAdminOrSuperUser={isSysAdminOrSuperUser}
                    isDeactivateChecked={isDeactivateChecked}
                    totalActiveResources={totalActiveResources}
                  />
                </Grid>
              )}

          </Grid>
        </Stack>
      </div>
    </div>
  );
}

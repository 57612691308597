/* eslint-disable no-unsafe-optional-chaining */
import { calculateAnnualScore, getTotalMarks, isNullOrUndefined } from '../../../utils/methods';

/* eslint-disable import/prefer-default-export */
function StudentList(studentData, courseName) {
  const rows = studentData?.map((student, index) => ({
    ...student,
    key: index,
    id: index,
    profilePhoto: `${student?.studentInfo?.profilePhoto}  ` || '',
    studentName: `${student?.studentInfo?.firstName} ${student?.studentInfo?.lastName}  ` || '',
    studentEmailAddress: `${student?.studentInfo?.manabadiEmail} ` || '',
    studentId: student?.id,
    parentName: `${student?.parent1Info?.firstName} ${student?.parent1Info?.lastName}  ` || '',
    courseName,
    gender: student?.studentInfo?.gender,
    phoneNumber: student?.parent1Info?.contactNumber || '',
    homeworkQ1: student?.homeworkMarks?.Q1?.obtainedMarks || 0,
    homeworkQ2: student?.homeworkMarks?.Q2?.obtainedMarks || 0,
    homeworkQ3: student?.homeworkMarks?.Q3?.obtainedMarks || 0,
    homeworkS1: student?.homeworkMarks?.S1?.obtainedMarks || 0,
    homeworkS2: student?.homeworkMarks?.S2?.obtainedMarks || 0,
    Q1HomeWorkWeightage: student?.homeworkMarks?.Q1?.weightage,
    Q2HomeWorkWeightage: student?.homeworkMarks?.Q2?.weightage,
    Q3HomeWorkWeightage: student?.homeworkMarks?.Q3?.weightage,
    S1HomeWorkWeightage: student?.homeworkMarks?.S1?.weightage,
    S2HomeWorkWeightage: student?.homeworkMarks?.S2?.weightage,
    S1HomeworkMarks: (student?.homeworkMarks?.S1?.weightage / 100) * student?.homeworkMarks?.S1?.obtainedMarks,
    S2HomeworkMarks: (student?.homeworkMarks?.S2?.weightage / 100) * student?.homeworkMarks?.S2?.obtainedMarks,

    marksQ1: student?.marks?.Q1?.obtainedMarks || 0,
    marksQ2: student?.marks?.Q2?.obtainedMarks || 0,
    marksQ3: student?.marks?.Q3?.obtainedMarks || 0,
    marksS1: student?.marks?.S1?.obtainedMarks || 0,
    marksS2: student?.marks?.S2?.obtainedMarks || 0,
    Q1PassingCriteria: student?.marks?.Q1?.passingCriteria,
    Q2PassingCriteria: student?.marks?.Q2?.passingCriteria,
    Q3PassingCriteria: student?.marks?.Q3?.passingCriteria,
    S1PassingCriteria: student?.marks?.S1?.passingCriteria,
    S2PassingCriteria: student?.marks?.S2?.passingCriteria,
    S1Marks: (student?.marks?.S1?.weightage / 100) * student?.marks?.S1?.obtainedMarks,
    S2Marks: (student?.marks?.S2?.weightage / 100) * student?.marks?.S2?.obtainedMarks,

    Q1Attended: student?.marks?.Q1?.isAttended,
    Q2Attended: student?.marks?.Q2?.isAttended,
    Q3Attended: student?.marks?.Q3?.isAttended,
    S1Attended: student?.marks?.S1?.isAttended,
    S2Attended: student?.marks?.S2?.isAttended,
    S3Attended: student?.marks?.S3?.isAttended,

    quizS1Q1: student?.quizMarks?.['S1Quiz 1']?.obtainedMarks || 0,
    quizS1Q2: student?.quizMarks?.['S1Quiz 2']?.obtainedMarks || 0,
    S1Q1QuizWeightage: student?.quizMarks?.['S1Quiz 1']?.weightage,
    S1Q2QuizWeightage: student?.quizMarks?.['S1Quiz 2']?.weightage,
    S1QuizMarks: (((student?.quizMarks?.['S1Quiz 1']?.weightage / 100) * student?.quizMarks?.['S1Quiz 1']?.obtainedMarks || 0)
      + ((student?.quizMarks?.['S1Quiz 2']?.weightage / 100) * student?.quizMarks?.['S1Quiz 2']?.obtainedMarks || 0)),

    quizS2Q1: student?.quizMarks?.['S2Quiz 1']?.obtainedMarks || 0,
    quizS2Q2: student?.quizMarks?.['S2Quiz 1']?.obtainedMarks || 0,
    S2Q1QuizWeightage: student?.quizMarks?.['S2Quiz 1']?.weightage,
    S2Q2QuizWeightage: student?.quizMarks?.['S2Quiz 1']?.weightage,
    S2QuizMarks: (((student?.quizMarks?.['S2Quiz 1']?.weightage / 100) * student?.quizMarks?.['S2Quiz 1']?.obtainedMarks || 0)
      + ((student?.quizMarks?.['S2Quiz 2']?.weightage / 100) * student?.quizMarks?.['S2Quiz 2']?.obtainedMarks || 0)),

    assignmentS1: student?.assignmentMarks?.S1?.obtainedMarks || 0,
    assignmentS2: student?.assignmentMarks?.S2?.obtainedMarks || 0,
    S1AssignmentWeightage: student?.assignmentMarks?.S1?.weightage,
    S2AssignmentWeightage: student?.assignmentMarks?.S2?.weightage,
    S1AssignmentMarks: (student?.assignmentMarks?.S1?.weightage / 100) * student?.assignmentMarks?.S1?.obtainedMarks,
    S2AssignmentMarks: (student?.assignmentMarks?.S2?.weightage / 100) * student?.assignmentMarks?.S2?.obtainedMarks,

    curricularS1: student?.attendenceActivitiesMarks?.S1?.obtainedMarks || 0,
    curricularS2: student?.attendenceActivitiesMarks?.S2?.obtainedMarks || 0,
    S1CurricularWeightage: student?.attendenceActivitiesMarks?.S1?.weightage,
    S2CurricularWeightage: student?.attendenceActivitiesMarks?.S2?.weightage,
    S1CurricularMarks: (student?.attendenceActivitiesMarks?.S1?.weightage / 100) * student?.attendenceActivitiesMarks?.S1?.obtainedMarks,
    S2CurricularMarks: (student?.attendenceActivitiesMarks?.S2?.weightage / 100) * student?.attendenceActivitiesMarks?.S2?.obtainedMarks,

    S1Total: getTotalMarks(student, 'S1', student?.enrolled_courses?.map((i) => `${i?.course?.name}`).join(',')),
    S2Total: getTotalMarks(student, 'S2', student?.enrolled_courses?.map((i) => `${i?.course?.name}`).join(',')),

    bonus: student?.bonus || '',
    grade: (student?.marks?.Q1?.isAttended || student?.marks?.Q2?.isAttended || student?.marks?.Q3?.isAttended
      || isNullOrUndefined(student?.marks?.S1?.isAttended) || isNullOrUndefined(student?.marks?.S2?.isAttended) || isNullOrUndefined(student?.marks?.S3?.isAttended)) ? (student?.grade) : 'N/A',
    gpa: (student?.marks?.Q1?.isAttended || student?.marks?.Q2?.isAttended || student?.marks?.Q3?.isAttended
      || isNullOrUndefined(student?.marks?.S1?.isAttended) || isNullOrUndefined(student?.marks?.S2?.isAttended) || isNullOrUndefined(student?.marks?.S3?.isAttended)) ? (student?.gpa) : 'N/A',
    // eslint-disable-next-line no-nested-ternary
    annualScore: (student?.marks?.Q1?.isAttended || student?.marks?.Q2?.isAttended || student?.marks?.Q3?.isAttended
      || isNullOrUndefined(student?.marks?.S1?.isAttended) || isNullOrUndefined(student?.marks?.S2?.isAttended) || isNullOrUndefined(student?.marks?.S3?.isAttended))
      ? calculateAnnualScore(courseName, student?.marks?.Q3?.maximumMarks, student?.annualScore, student?.marks?.Q3?.obtainedMarks) : 'N/A',
    newReturning: student?.newReturning,
  }));
  return rows;
}

export { StudentList };

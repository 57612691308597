/* eslint-disable arrow-spacing */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import Constant from '../constant/index';
import {
  getTeacherResourceService,
  getStudentResourceWRTCourseService,
  getHelpVideosService,
  uploadFileService,
  deactivateResourceService,
  updateResourceService,
  updateHelpVideoService,
  uploadVideoService,
  uploadHelpVideoService,
  deactivateHelpVideosService,
  createStudentResourceService,
  getStudentResourceInFolderService,
  getStudentResourceCourseService,
  getTeacherResourcePaginationService,
  getTeacherResourceInFolderService,
  getHelpVideosPaginationService,
} from '../services/auth';

export const getStudentResourceWRTCourse = (body) => (dispatch) => {
  getStudentResourceWRTCourseService(body)
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

async function processFolder(folder) {
  const resultResources = await getStudentResourceWRTCourseService({
    courseId: folder.courseId,
    parentKey: folder.selfKey,
  });

  const children = [];
  if (resultResources.data.length === 0) {
    children.push({
      id: '1',
      name: 'No data',
      url: '',
      fileType: 'No Data',
      deactivateDate: null,
    });
  }
  for (let i = 0; i < resultResources.data.length; i++) {
    const resource = resultResources.data[i];
    if (resource.deactivateDate === null && resultResources.data.length > 1) {
      if (resource.fileType === 'Folder') {
        const childFolder = {
          id: resource.id,
          name: resource.resourceName,
          url: resource.fileUrl,
          fileType: resource.fileType,
          deactivateDate: resource.deactivateDate,
          children: await processFolder(resource),
        };
        children.push(childFolder);
      } else {
        children.push({
          id: resource.id,
          name: resource.resourceName,
          url: resource.fileUrl,
          fileType: resource.fileType,
          deactivateDate: resource.deactivateDate,
        });
      }
    } else {
      children.push({
        id: '1',
        name: 'No data',
        url: '',
        fileType: 'No Data',
        deactivateDate: null,
      });
    }
  }

  return children;
}

export const getStudentResourceWRTCourseAP = (pageNumber, pageLimit, nextPage, body, setIsFetching, setLoadingResources) => (dispatch) => {
  getStudentResourceCourseService(pageNumber, pageLimit, body)
    .then(async (response) => {
      nextPage
        ? nextPage(
          response,
          pageNumber === 1 ? 20 : response.data.length,
          pageNumber,
        )
        : null;
      const getFilesFolder = response?.data.filter((item) => item.fileType === 'Folder');
      const aIDFolder = getFilesFolder.map((item) => item.id);
      if (getFilesFolder.length !== 0) {
        const result = [];
        for (let i = 0; i < getFilesFolder.length; i++) {
          const folder = getFilesFolder[i];
          if (folder.deactivateDate === null) {
            const children = await processFolder(folder);
            result.push({
              id: folder.id,
              name: folder.resourceName,
              url: folder.fileUrl,
              fileType: folder.fileType,
              deactivateDate: folder.deactivateDate,
              children,
            });
          }
        }
        const getFiles = response?.data.filter((item) => item.fileType !== 'Folder');
        result.push(...getFiles.map((item) => {
          return {
            id: item.id,
            name: item.resourceName,
            url: item.fileUrl,
            fileType: item.fileType,
            deactivateDate: item.deactivateDate,
          };
        }));
        setIsFetching(false);
        setLoadingResources !== undefined ? setLoadingResources(false) : null;
        if (pageNumber === 1) {
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
            payload: result,
          });
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
            payload: aIDFolder,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: response?.count,
          });
        } else if (pageNumber > 1) {
          dispatch({
            type: Constant.GET_PAGINATED_USERS,
            payload: result,
          });
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
            payload: aIDFolder,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: response?.count,
          });
        } else {
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
            payload: [],
          });
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
            payload: [],
          });
          dispatch({
            type: Constant.GET_PAGINATED_USERS,
            payload: [],
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: 0,
          });
        }
      } else {
        const aResources = response?.data.map((item) => {
          return {
            id: item.id,
            name: item.resourceName,
            url: item.fileUrl,
            fileType: item.fileType,
            deactivateDate: item.deactivateDate,
          };
        });
        setIsFetching(false);
        setLoadingResources !== undefined ? setLoadingResources(false) : '';
        if (pageNumber === 1) {
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
            payload: aResources,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: response?.count,
          });
        } else if (pageNumber > 1) {
          dispatch({
            type: Constant.GET_PAGINATED_USERS,
            payload: aResources,
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: response?.count,
          });
        } else {
          dispatch({
            type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
            payload: [],
          });
          dispatch({
            type: Constant.GET_PAGINATED_USERS,
            payload: [],
          });
          dispatch({
            type: Constant.TOTAL_COUNT,
            payload: 0,
          });
        }
      }
    })
    .catch((error) => {
      setIsFetching(false);
      console.log('error :', error);
    });
};

export const getStudentResourceCourse = (pageNumber, pageLimit, nextPage, body, setIsFetching, setLoading) => (dispatch) => {
  getStudentResourceCourseService(pageNumber, pageLimit, body)
    .then((response) => {
      setIsFetching(false);
      setLoading && setLoading(false);
      nextPage
        ? nextPage(
          response,
          pageNumber === 1 ? 100 : response.data.length,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_USERS,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_USERS,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: 0,
        });
      }
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getHelpVideos = (body) => (dispatch) => {
  getHelpVideosService(body.userRole)
    .then((response) => {
      dispatch({
        type: Constant.GET_ALL_HELP_VIDEOS,
        payload: response?.data,
      });
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const getHelpVideosPagination = (body, pageNumber, pageLimit, nextPage) => (dispatch) => {
  getHelpVideosPaginationService(body.userRole, pageNumber, pageLimit)
    .then((response) => {
      nextPage
        ? nextPage(
          response?.data,
          pageNumber === 1 ? 100 : response?.data?.rows.length,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ALL_HELP_VIDEOS,
          payload: response?.data?.rows,
        });
        dispatch({
          type: Constant.TOTAL_HVIDEOS_COUNT,
          payload: response?.data?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_HELP_VIDEOS,
          payload: response?.data?.rows,
        });
        dispatch({
          type: Constant.TOTAL_HVIDEOS_COUNT,
          payload: response?.data?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ALL_HELP_VIDEOS,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_HELP_VIDEOS,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_HVIDEOS_COUNT,
          payload: 0,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTeacherResource = (pageNumber, pageLimit, nextPage, body, setIsFetching, setLoading) => (dispatch) => {
  getTeacherResourcePaginationService(pageNumber, pageLimit, body)
    .then((response) => {
      setLoading && setLoading(false);
      setIsFetching(false);
      nextPage
        ? nextPage(
          response,
          pageNumber === 1 ? 100 : response.data.length,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ALL_TEACHER_RECOURCES,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_TEACHER_RECOURCES,
          payload: response?.data,
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: response?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ALL_TEACHER_RECOURCES,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_TEACHER_RECOURCES,
          payload: [],
        });
        dispatch({
          type: Constant.TOTAL_COUNT,
          payload: 0,
        });
      }
    })
    .catch((error) => {
      console.log('error :', error);
    });
};

export const deactivateResource = (body) => () => {
  deactivateResourceService(body)
    .then(() => {
    })
    .catch(() => {
    });
};

export const updateResource = (body, typeResources) => (dispatch) => {
  updateResourceService(body)
    .then(() => {
      if (typeResources === 'teacher') {
        getTeacherResourceService({ courseId: body.courseId })
          .then((response) => {
            dispatch({
              type: Constant.GET_ALL_TEACHER_RECOURCES,
              payload: response?.data,
            });
          })
          .catch(() => {
          });
      } else if (typeResources === 'student' || typeResources === 'Student') {
        getStudentResourceWRTCourseService({ courseId: body.courseId })
          .then((response) => {
            dispatch({
              type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
              payload: response?.data,
            });
          })
          .catch(() => {
          });
      }
    })
    .catch(() => {
    });
};

export const updateHelpVideo = (body, callback) => async () => {
  try {
    const res = await updateHelpVideoService(body);
    await callback();
    return Promise.resolve(res);
  } catch (err) {
    callback();
    return Promise.reject(err);
  }
};

export const uploadVideo = (body, setSelectVideoFromDriveOpenDialog, setLoadingUploadFile, setIsVideoChecked, setErrorLargeVideo, setSelectVideoFromDriveMessage, setUploadVideoOpenDialog, refreshPage = null) => (dispatch) => {
  uploadVideoService(body)
    .then((response) => {
      setTimeout(() => {
        setIsVideoChecked(false);
        setUploadVideoOpenDialog !== undefined ? setUploadVideoOpenDialog(false) : null;
        setSelectVideoFromDriveMessage !== undefined ? setSelectVideoFromDriveMessage('') : null;
        if (refreshPage) {
          refreshPage();
        }
      }, 5500);
      setLoadingUploadFile(false);
      dispatch({
        type: Constant.UPLOAD_VIDEO,
        payload: response,
      });
    })
    .catch((err) => {
      console.log(err);
      setIsVideoChecked(false);
      setErrorLargeVideo(true);
      setLoadingUploadFile(false);
      setTimeout(() => {
        setSelectVideoFromDriveOpenDialog !== undefined ? setSelectVideoFromDriveOpenDialog(false) : null;
        setUploadVideoOpenDialog !== undefined ? setUploadVideoOpenDialog(false) : null;
        setErrorLargeVideo !== undefined ? setErrorLargeVideo(false) : null;
      }, 2000);
    });
};

export const uploadVideoAssignment = (body, setSelectVideoFromDriveOpenDialog, setLoadingUploadFile, setErrorLargeVideo, setSelectVideoFromDriveMessage, setUploadVideoOpenDialog, pageNumber, pageLimit, nextPage, bodyResources, setIsFetching, setLoadingResources) => (dispatch) => {
  uploadVideoService(body)
    .then((response) => {
      setTimeout(() => {
        setSelectVideoFromDriveOpenDialog !== undefined ? setSelectVideoFromDriveOpenDialog(false) : null;
        setUploadVideoOpenDialog !== undefined ? setUploadVideoOpenDialog(false) : null;
        setSelectVideoFromDriveMessage !== undefined ? setSelectVideoFromDriveMessage('') : null;
        getStudentResourceCourseService(pageNumber, pageLimit, bodyResources)
          .then(async (responseResources) => {
            nextPage
              ? nextPage(
                responseResources,
                pageNumber === 1 ? 100 : responseResources.data.length,
                pageNumber,
              )
              : null;
            const getFilesFolder = responseResources?.data.filter((item) => item.fileType === 'Folder');
            const aIDFolder = getFilesFolder.map((item) => item.id);
            if (getFilesFolder.length !== 0) {
              const result = [];
              for (let index = 0; index < getFilesFolder.length; index++) {
                const resultResources = await getStudentResourceWRTCourseService({
                  courseId: getFilesFolder[index].courseId,
                  parentKey: getFilesFolder[index].selfKey,
                });
                result.push({
                  id: getFilesFolder[index].id,
                  name: getFilesFolder[index].resourceName,
                  url: getFilesFolder[index].fileUrl,
                  fileType: getFilesFolder[index].fileType,
                  deactivateDate: getFilesFolder[index].deactivateDate,
                  children: resultResources.data.map((item) => {
                    return {
                      id: item.id,
                      name: item.resourceName,
                      url: item.fileUrl,
                      fileType: item.fileType,
                      deactivateDate: item.deactivateDate,
                    };
                  }),
                });
              }
              const getFiles = responseResources?.data.filter((item) => item.fileType !== 'Folder');
              result.push(...getFiles.map((item) => {
                return {
                  id: item.id,
                  name: item.resourceName,
                  url: item.fileUrl,
                  fileType: item.fileType,
                  deactivateDate: item.deactivateDate,
                };
              }));
              setIsFetching(false);
              setLoadingResources !== undefined ? setLoadingResources(false) : null;
              if (pageNumber === 1) {
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: result,
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: aIDFolder,
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: responseResources?.count,
                });
              } else if (pageNumber > 1) {
                dispatch({
                  type: Constant.GET_PAGINATED_USERS,
                  payload: result,
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: aIDFolder,
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: responseResources?.count,
                });
              } else {
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: [],
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: [],
                });
                dispatch({
                  type: Constant.GET_PAGINATED_USERS,
                  payload: [],
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: 0,
                });
              }
            } else {
              const aResources = responseResources?.data.map((item) => {
                return {
                  id: item.id,
                  name: item.resourceName,
                  url: item.fileUrl,
                  fileType: item.fileType,
                  deactivateDate: item.deactivateDate,
                };
              });
              setIsFetching(false);
              setLoadingResources !== undefined ? setLoadingResources(false) : null;
              dispatch({
                type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                payload: aResources,
              });
              dispatch({
                type: Constant.TOTAL_COUNT,
                payload: responseResources?.count,
              });
            }
          })
          .catch((error) => {
            setIsFetching(false);
            console.log('error :', error);
          });
      }, 3000);
      setLoadingUploadFile(false);
      dispatch({
        type: Constant.UPLOAD_VIDEO,
        payload: response,
      });
      dispatch({
        type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
        payload: null,
      });
    })
    .catch((err) => {
      console.log(err);
      setErrorLargeVideo(true);
      setLoadingUploadFile(false);
      setTimeout(() => {
        setSelectVideoFromDriveOpenDialog !== undefined ? setSelectVideoFromDriveOpenDialog(false) : null;
        setUploadVideoOpenDialog !== undefined ? setUploadVideoOpenDialog(false) : null;
        setErrorLargeVideo !== undefined ? setErrorLargeVideo(false) : null;
      }, 2000);
    });
};

export const uploadHelpVideo = (body) => (dispatch) => {
  uploadHelpVideoService(body)
    .then((response) => {
      dispatch({
        type: Constant.UPLOAD_HELP_VIDEO,
        payload: response,
      });
    })
    .catch(() => {
    });
};

export const deactivateHelpVideos = (body, callback) => async () => {
  try {
    const res = await deactivateHelpVideosService(body);
    await callback();
    return Promise.resolve(res);
  } catch (err) {
    callback();
    return Promise.reject(err);
  }
};

export const createStudentResource = (body) => (dispatch) => {
  createStudentResourceService(body)
    .then((response) => {
      dispatch({
        type: Constant.CREATE_STUDENT_RESOURCE,
        payload: response,
      });
    })
    .catch((e) => {
      dispatch({
        type: Constant.CREATE_STUDENT_RESOURCE_ERROR,
        payload: e?.errors?.length ? e?.errors[0] : {},
      });
    });
};

export const createStudentResourceAssignment = (body, pageNumber, pageLimit, nextPage, bodyResources, setIsFetching, setLoadingResources) => (dispatch) => {
  createStudentResourceService(body)
    .then((response) => {
      dispatch({
        type: Constant.CREATE_STUDENT_RESOURCE,
        payload: response,
      });
      dispatch({
        type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
        payload: null,
      });
      setTimeout(() => {
        getStudentResourceCourseService(pageNumber, pageLimit, bodyResources)
          .then(async (responseResources) => {
            nextPage
              ? nextPage(
                responseResources,
                pageNumber === 1 ? 100 : responseResources.data.length,
                pageNumber,
              )
              : null;
            const getFilesFolder = responseResources?.data.filter((item) => item.fileType === 'Folder');
            const aIDFolder = getFilesFolder.map((item) => item.id);
            if (getFilesFolder.length !== 0) {
              const result = [];
              for (let index = 0; index < getFilesFolder.length; index++) {
                const resultResources = await getStudentResourceWRTCourseService({
                  courseId: getFilesFolder[index].courseId,
                  parentKey: getFilesFolder[index].selfKey,
                });
                result.push({
                  id: getFilesFolder[index].id,
                  name: getFilesFolder[index].resourceName,
                  url: getFilesFolder[index].fileUrl,
                  fileType: getFilesFolder[index].fileType,
                  deactivateDate: getFilesFolder[index].deactivateDate,
                  children: resultResources.data.map((item) => {
                    return {
                      id: item.id,
                      name: item.resourceName,
                      url: item.fileUrl,
                      fileType: item.fileType,
                      deactivateDate: item.deactivateDate,
                    };
                  }),
                });
              }
              const getFiles = responseResources?.data.filter((item) => item.fileType !== 'Folder');
              result.push(...getFiles.map((item) => {
                return {
                  id: item.id,
                  name: item.resourceName,
                  url: item.fileUrl,
                  fileType: item.fileType,
                  deactivateDate: item.deactivateDate,
                };
              }));
              setIsFetching(false);
              setLoadingResources !== undefined ? setLoadingResources(false) : null;
              if (pageNumber === 1) {
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: result,
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: aIDFolder,
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: responseResources?.count,
                });
              } else if (pageNumber > 1) {
                dispatch({
                  type: Constant.GET_PAGINATED_USERS,
                  payload: result,
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: aIDFolder,
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: responseResources?.count,
                });
              } else {
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: [],
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: [],
                });
                dispatch({
                  type: Constant.GET_PAGINATED_USERS,
                  payload: [],
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: 0,
                });
              }
            } else {
              const aResources = responseResources?.data.map((item) => {
                return {
                  id: item.id,
                  name: item.resourceName,
                  url: item.fileUrl,
                  fileType: item.fileType,
                  deactivateDate: item.deactivateDate,
                };
              });
              setIsFetching(false);
              setLoadingResources !== undefined ? setLoadingResources(false) : null;
              dispatch({
                type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                payload: aResources,
              });
              dispatch({
                type: Constant.TOTAL_COUNT,
                payload: responseResources?.count,
              });
            }
          })
          .catch((error) => {
            setIsFetching(false);
            console.log('error :', error);
          });
      }, 3000);
    })
    .catch((e) => {
      dispatch({
        type: Constant.CREATE_STUDENT_RESOURCE_ERROR,
        payload: e?.errors?.length ? e?.errors[0] : {},
      });
    });
};

export const getStudentResourceInFolder = (body, setLoading, setIsFetching) => (dispatch) => {
  getStudentResourceInFolderService(body)
    .then((response) => {
      setLoading(false);
      setIsFetching(false);
      dispatch({
        type: Constant.GET_STUDENT_RESOURCE_IN_FOLDER,
        payload: response.data,
      });
      dispatch({
        type: Constant.TOTAL_COUNT,
        payload: response?.count,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTeacherResourceInFolder = (body, setLoading, setIsFetching) => (dispatch) => {
  getTeacherResourceInFolderService(body)
    .then((response) => {
      setLoading(false);
      setIsFetching(false);
      dispatch({
        type: Constant.GET_TEACHER_RESOURCE_IN_FOLDER,
        payload: response.data,
      });
      dispatch({
        type: Constant.TOTAL_COUNT,
        payload: response?.count,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadFile = (body) => (dispatch) => {
  uploadFileService(body)
    .then((response) => {
      dispatch({
        type: Constant.UPLOAD_FILE,
        payload: response,
      });
    })
    .catch(() => {
    });
};

export const uploadFileAssignment = (body, pageNumber, pageLimit, nextPage, bodyResources, setIsFetching, setLoadingResources) => (dispatch) => {
  uploadFileService(body)
    .then((response) => {
      dispatch({
        type: Constant.UPLOAD_FILE,
        payload: response,
      });
      dispatch({
        type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
        payload: null,
      });
      setTimeout(() => {
        getStudentResourceCourseService(pageNumber, pageLimit, bodyResources)
          .then(async (responseResources) => {
            nextPage
              ? nextPage(
                responseResources,
                pageNumber === 1 ? 100 : responseResources.data.length,
                pageNumber,
              )
              : null;
            const getFilesFolder = responseResources?.data.filter((item) => item.fileType === 'Folder');
            const aIDFolder = getFilesFolder.map((item) => item.id);
            if (getFilesFolder.length !== 0) {
              const result = [];
              for (let index = 0; index < getFilesFolder.length; index++) {
                const resultResources = await getStudentResourceWRTCourseService({
                  courseId: getFilesFolder[index].courseId,
                  parentKey: getFilesFolder[index].selfKey,
                });
                result.push({
                  id: getFilesFolder[index].id,
                  name: getFilesFolder[index].resourceName,
                  url: getFilesFolder[index].fileUrl,
                  fileType: getFilesFolder[index].fileType,
                  deactivateDate: getFilesFolder[index].deactivateDate,
                  children: resultResources.data.map((item) => {
                    return {
                      id: item.id,
                      name: item.resourceName,
                      url: item.fileUrl,
                      fileType: item.fileType,
                      deactivateDate: item.deactivateDate,
                    };
                  }),
                });
              }
              const getFiles = responseResources?.data.filter((item) => item.fileType !== 'Folder');
              result.push(...getFiles.map((item) => {
                return {
                  id: item.id,
                  name: item.resourceName,
                  url: item.fileUrl,
                  fileType: item.fileType,
                  deactivateDate: item.deactivateDate,
                };
              }));
              setIsFetching(false);
              setLoadingResources !== undefined ? setLoadingResources(false) : null;
              if (pageNumber === 1) {
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: result,
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: aIDFolder,
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: responseResources?.count,
                });
              } else if (pageNumber > 1) {
                dispatch({
                  type: Constant.GET_PAGINATED_USERS,
                  payload: result,
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: aIDFolder,
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: responseResources?.count,
                });
              } else {
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                  payload: [],
                });
                dispatch({
                  type: Constant.GET_ALL_STUDENT_RECOURCES_WRT_FOLDER,
                  payload: [],
                });
                dispatch({
                  type: Constant.GET_PAGINATED_USERS,
                  payload: [],
                });
                dispatch({
                  type: Constant.TOTAL_COUNT,
                  payload: 0,
                });
              }
            } else {
              const aResources = responseResources?.data.map((item) => {
                return {
                  id: item.id,
                  name: item.resourceName,
                  url: item.fileUrl,
                  fileType: item.fileType,
                  deactivateDate: item.deactivateDate,
                };
              });
              setIsFetching(false);
              setLoadingResources !== undefined ? setLoadingResources(false) : null;
              dispatch({
                type: Constant.GET_ALL_STUDENT_RECOURCES_WRT,
                payload: aResources,
              });
              dispatch({
                type: Constant.TOTAL_COUNT,
                payload: responseResources?.count,
              });
            }
          })
          .catch((error) => {
            setIsFetching(false);
            console.log('error :', error);
          });
      }, 3000);
    })
    .catch(() => {
    });
};

export const getFolderDetails = (obj) => (dispatch) => {
  dispatch({
    type: Constant.GET_FOLDER_DETAILS,
    payload: obj,
  });
};

export default {
  getStudentResourceWRTCourse,
  getStudentResourceWRTCourseAP,
  getStudentResourceCourse,
  getTeacherResource,
  getHelpVideos,
  createStudentResource,
  createStudentResourceAssignment,
  deactivateResource,
  updateResource,
  uploadFile,
  uploadFileAssignment,
  uploadVideo,
  uploadVideoAssignment,
  updateHelpVideo,
  deactivateHelpVideos,
  getStudentResourceInFolder,
  getFolderDetails,
  getTeacherResourceInFolder,
};

/* eslint-disable space-before-blocks */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  Box, IconButton,
  ListItemText, Grid, Typography, Link,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DialogAtom from '../../components/atoms/dialog';
import style from './style';
import useStyles from '../../custom-hooks/useStyles';
import logo from '../../assets/images/logo.png';
import StudentDashboard from './dashboard';
import UserIcon from '../../assets/svg/userIcon';
import MeterIcon from '../../assets/svg/meterDashboardIcon';
import LogoutIcon from '../../assets/svg/logoutIcon';
import ButtonAtom from '../../components/atoms/button';
import { Buttons, NavigateRoutes } from '../../constant';
import AccountDetail from './account';
import Enroll from './enroll';
import MenuBarIconSVG from '../../assets/svg/menuBarSVG';
import {
  getAcademicGrades,
  getAllCourses,
  getExtraCurricularActivities,
  getLocations,
  getTShirts,
  getStudentAccountDetails,
  getNextEnrollCourseAdmin,
  setTermsAndConditionSettingDetailsStu,
} from '../../store/actions/getStudent';
import {
  impersonateUser,
} from '../../store/actions/getAdmin';
import Donation from './donation';
import Loader from '../../components/atoms/loader';
import { getLocalStorage, logout } from '../../utils/localStorageMethod';
import {
  getHearAboutUs,
  getVolunteers,
} from '../../store/actions/getParent';
import Constant from '../../store/constant';
import userRoles from '../../constant/userRoles';
import decryptedUserRoles from '../../constant/decryptedUserRoles';
import { isNullOrUndefined } from '../../utils/methods';

const drawerWidth = '8vw';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 10,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px - 10px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function ReturningFrame() {
  const classes = useStyles(style)();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTabTitle, setSelectedTabTitle] = useState(t('MY_DASHBOARD'));

  const [showAddSibling, setShowAddSibling] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [isDonateOpen, setIsDonationDialogOpen] = useState(false);
  const [isEligibleToEnroll, setEligibleToEnroll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const isAdminUser = getLocalStorage('isAdminUser');
  const [isEligible, setIsEligible] = useState(false);
  const [openDialogErrorParentEmail, setOpenDialogErrorParentEmail] = useState(false);
  const [parentEmailError, setParentEmailError] = useState(false);

  const checkEligibilityToEnroll = (studentStateData) => {
    if (studentStateData?.enrollCourseList && studentStateData?.studentAccountDetails?.studentData) {
      const primaryStudentData = studentStateData.studentAccountDetails.studentData.find((student) => student.primaryStudent);

      if (primaryStudentData && primaryStudentData.enrolled_courses && isNullOrUndefined(primaryStudentData.enrolled_courses.result)) {
        studentStateData.enrollCourseList.forEach((courseItem) => {
          courseItem?.course?.forEach((obj) => {
            if (obj.toCourse && obj.toCourse.academicYear !== primaryStudentData.enrolled_courses.academicYear) {
              setIsEligible(true);
            }
          });
        });
      }
    }

    if (isAdminUser) {
      setIsEligible(true);
    }
  };
  const studentStateData = useSelector((state1) => state1.getStudent);
  const [studentState, setStudentState] = useState([]);

  const changeView = (tab) => {
    setSelectedTab(tab);
    let title = '';
    if (tab === 0) {
      title = t('MY_DASHBOARD');
    }
    if (tab === 1) {
      title = t('ACCOUNT');
    } if (tab === 2) {
      title = t('CALENDER');
    }
    if (tab === 3) {
      title = t('ENROLL');
    }
    setSelectedTabTitle(title);
    setClickCount(0);
  };

  const validateForm = (callback) => {
    if (showAddSibling && callback && typeof (callback) === 'function') {
      callback();
    }
  };
  React.useEffect(() => {
    setLoading(true);
    dispatch(getVolunteers());
    dispatch(getAllCourses());
    dispatch(getHearAboutUs());
    dispatch(getLocations());
    dispatch(getTShirts());
    dispatch(getAcademicGrades());
    dispatch(getExtraCurricularActivities());
    dispatch(setTermsAndConditionSettingDetailsStu());
  }, []);

  React.useEffect(() => {
    if ((selectedTab !== 1) && showAddSibling) {
      setShowAddSibling(false);
    }
  }, [selectedTab]);

  React.useEffect(() => {
    setStudentState(studentStateData);
    checkEligibilityToEnroll(studentStateData);
    setEligibleToEnroll(isEligible);
  }, [studentStateData]);

  const getPrimaryStudent = () => studentState?.studentAccountDetails?.studentData?.find((stu) => stu.primaryStudent);
  const [isImpersonateUser, setIsImpersonateUser] = useState(false);

  React.useEffect(() => {
    if (reduxStore?.impersonateUserEmail?.success) {
      setIsImpersonateUser(true);
    }
  }, [reduxStore?.impersonateUserEmail]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    const email = studentStateData?.studentAccountDetails?.parent1Data?.personalEmail;

    if (email && !isValidEmail(email)) {
      setParentEmailError(true);
    }
  }, [studentStateData?.studentAccountDetails]);

  useEffect(() => {
    const loadFalse = () => setLoading(false);
    const encryptedUserRole = getLocalStorage('userRole');
    const userRole = decryptedUserRoles[encryptedUserRole];
    const impersonateToken = getLocalStorage('impersonateToken');
    if (isImpersonateUser || impersonateToken) {
      if (isAdminUser && impersonateToken) {
        const payload = { id: getPrimaryStudent()?.id, isAdminUser };
        dispatch(getStudentAccountDetails(loadFalse));
        dispatch(getNextEnrollCourseAdmin(payload));
        setInitialLoad(true);
      } else if (!isAdminUser) {
        const payload = { id: getPrimaryStudent()?.id, isAdminUser };
        dispatch(getStudentAccountDetails(loadFalse));
        dispatch(getNextEnrollCourseAdmin(payload));
        setInitialLoad(true);
      } else if (!impersonateToken) {
        dispatch(impersonateUser({
          email: state?.email,
        }));
        setIsImpersonateUser(false);
      }
    } else if (userRole === userRoles.STUDENT) {
      const payload = { id: getPrimaryStudent()?.id, isAdminUser };
      dispatch(getStudentAccountDetails(loadFalse));
      dispatch(getNextEnrollCourseAdmin(payload));
      setInitialLoad(true);
    }
  }, [isImpersonateUser]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const confirmIfDonation = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('DONATE_ONLY')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('YES')}
          onClick={() => {
            setIsDonationDialogOpen(true);
            setOpenDialog(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const errorParentEmail = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classes.alertError} role="alert">
            {t('ERROR_PARENT_EMAIL')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => {
            setOpenDialogErrorParentEmail(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const clearImpersonate = () => {
    localStorage.removeItem('tempUserId');
    localStorage.removeItem('impersonateUser');
    localStorage.removeItem('isAdminUser');
    navigate(NavigateRoutes.STUDENTS_VIEW, { state: { previousFilters: state?.filterOptions } });
    dispatch({ type: Constant.IMPERSONATE_USER, payload: [] });
    dispatch({ type: Constant.GET_STUDENT_ACCOUNT_DETAILS, payload: [] });
  };
  /* eslint-disable no-else-return */
  const isElegibleToEnrollFunction = () => {
    if (!isEligibleToEnroll) {
      return true;
    } else if (selectedTab === 3) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    return () => {
      const impersonateToken = localStorage.getItem('impersonateToken');
      const isEnrolling = localStorage.getItem('enrolling');
      if (impersonateToken) {
        localStorage.removeItem('impersonateToken');
        clearImpersonate();
      } else if (!isEnrolling) {
        // eslint-disable-next-line
        const shouldReload = window.confirm('You will lose your current information, do you wish to continue?');
        if (shouldReload) {
          navigate(NavigateRoutes.STUDENT_DASHBOARD, {});
          window.location.reload();
        }
      }
    };
  }, []);

  return (
    <Box sx={{ display: 'flex' }} className={classes.studentDashboard}>
      <AppBar position="fixed" className={classes.appBar}>
        {
          JSON.parse(localStorage.getItem('impersonateUser'))
          && (
            <Grid className={classes.headerViewingDiv}>
              <div className={classes.headerViewing}>
                <p>Viewing as</p>
                <p><span>{state?.email ? state?.email : JSON.parse(localStorage.getItem('impersonateUser')).state.email}</span></p>
                <ButtonAtom
                  className={classes.exitBtn}
                  onClick={() => clearImpersonate()}
                  name={t('EXIT')}
                  icon={<ExitToApp />}
                />
              </div>
            </Grid>
          )
        }
        <Grid className={classes.HeaderWrapper} anchor="top">
          <Box>
            <img
              src={logo}
              width={380}
              alt="ManaBadi SiliconAndhra Logo"
              className={classes.headerLogo}
            />
          </Box>
          <Stack direction="row" spacing={1} justifyContent="space-between !important" alignItems="center">
            <ListItemText
              className={classes.commonProfile}
              primary={(
                <Typography
                  component="div"
                  className={classes.inOneRow}
                >
                  {getPrimaryStudent()?.studentInfo?.firstName}
                  {' '}
                  {getPrimaryStudent()?.studentInfo?.lastName}
                </Typography>
              )}
              secondary={(
                <>
                  <Typography
                    component="div"
                    className={classes.roleName}
                  >
                    Student
                  </Typography>
                  <div
                    className={classes.logoutIcon}
                    onClick={logout}
                  >
                    <Typography>
                      <LogoutIcon />
                      Logout
                    </Typography>
                  </div>
                </>
              )}
            />
            <ListItemIcon>
              <CardMedia
                className={classes.accountIcon}
                component="img"
                image={getPrimaryStudent()?.studentInfo?.profilePhoto}
                alt="profile-img"
              />
            </ListItemIcon>
          </Stack>
        </Grid>
      </AppBar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
        className={`${classes.openDrawerArrow} ${classes.drawerArrow}`}
      >
        <ChevronRightIcon />
      </IconButton>
      <Drawer
        className={classes.menuBar}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            marginTop: '6.3vw',
            height: 'calc(100% - 6.3vw)',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box>
          <IconButton onClick={handleDrawerClose} className={`${classes.closeDrawerArrow} ${classes.drawerArrow}`}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <List>
            <ListItem className={classes.listMenuBarItem}>
              <ListItemIcon className={classes.menuIconBar}>
                <MenuBarIconSVG />
              </ListItemIcon>
            </ListItem>
            <ListItem
              onClick={() => changeView(0)}
              button
              className={`${classes.listMeterItem} ${(selectedTab === 0) ? classes.buttonMeterActive : ''}`}
            >
              <ListItemIcon className={classes.sideMenuIcon}>
                <MeterIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={t('DASHBOARD')} />
            </ListItem>
            <ListItem
              className={`${classes.listItem} ${selectedTab === 1 ? classes.buttonActive : ''}`}
              onClick={() => changeView(1)}
              button
            >
              <ListItemIcon className={classes.sideMenuIcon}>
                <UserIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={t('ACCOUNT')} />
            </ListItem>
            <ListItem
              button
              disabled={isElegibleToEnrollFunction()}
              className={`${classes.listItem} ${selectedTab === 3 ? classes.buttonActive : ''}`}
              onClick={() => changeView(3)}
            >
              <ListItemIcon className={classes.sideMenuIcon}>
                <UserIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={t('ENROLL')} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Main open={open} className={classes.addSpaceAround} component="main" sx={{ flexGrow: 1 }}>
        <Grid className={`${classes.HeaderWrapper} ${classes.divWrapper}`}>
          <Typography gutterBottom className={classes.headingName}>
            {selectedTabTitle}
          </Typography>
          <Stack direction="row" justifyContent="space-between !important">
            <Box>
              <Grid container>
                <Grid item xs={4} className={classes.btn}>

                  <Link to="/enroll">

                    <ButtonAtom
                      btntype={Buttons.PRIMARY}
                      className={classes.actionBtn}
                      name={t('ENROLL')}
                      disabled={isElegibleToEnrollFunction()}
                      onClick={() => {
                        if (selectedTab !== 3) {
                          setSelectedTab(3);
                        }
                      }}
                    />

                  </Link>

                </Grid>
                <Grid item xs={4} className={classes.btn}>
                  <ButtonAtom
                    btntype={Buttons.PRIMARY}
                    className={classes.actionBtn}
                    name={t('ADD_SIBLING')}
                    onClick={() => {
                      setShowAddSibling(true);
                      setClickCount((clickedCount) => clickedCount + 1);
                      if (selectedTab === 3) {
                        return;
                      }
                      if (selectedTab !== 1) {
                        setSelectedTab(1);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <ButtonAtom
                    btntype={Buttons.PRIMARY}
                    className={classes.donateActionBtn}
                    disabled={selectedTab === 3}
                    onClick={() => {
                      if (parentEmailError) {
                        setOpenDialogErrorParentEmail(true);
                      } else {
                        setOpenDialog(true);
                      }
                    }}
                    name={t('DONATE')}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
        <Grid>
          {selectedTab === 0
            ? (
              <StudentDashboard
                studentAccountDetails={studentState?.studentAccountDetails}
                initialLoad={initialLoad}
                setInitialLoad={setInitialLoad}
                selectedTab={selectedTab}
              />
            )
            : selectedTab === 1 ? (
              <AccountDetail
                showAddSibling={showAddSibling}
                setShowAddSibling={setShowAddSibling}
                studentAccountDetails={studentState?.studentAccountDetails}
                setStudentState={setStudentState}
                validateForm={validateForm}
                clickCount={clickCount}
              />
            ) : selectedTab === 3 ? (
              <Enroll
                showAddSibling={showAddSibling}
                setShowAddSibling={setShowAddSibling}
                studentAccountDetails={studentState?.studentAccountDetails}
                setStudentState={setStudentState}
              />
            ) : null}
        </Grid>
        <DialogAtom
          isOpen={isDonateOpen}
          dialogHeading={t('DONATION')}
          secHandle={() => setIsDonationDialogOpen(false)}
          customClass={classes.donateDialogAtom}
          content={(
            <Donation studentAccountDetails={studentState?.studentAccountDetails} />
          )}
        />
        <DialogAtom
          isOpen={openDialog}
          dialogHeading={t('DONATE')}
          content={confirmIfDonation()}
          secHandle={() => setOpenDialog(false)}
        />
        <DialogAtom
          isOpen={openDialogErrorParentEmail}
          dialogHeading={t('ERROR_ANNOUNCEMENTS')}
          content={errorParentEmail()}
          secHandle={() => setOpenDialogErrorParentEmail(false)}
        />
        {loading && (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )}
      </Main>
    </Box>
  );
}
